<template>
  <b-form-group
    label="เบิกจ่าย:"
    label-align="right"
    label-cols="3"
    :state="state"
    invalid-feedback="กรุณาเลือกวิธีการเบิกจ่าย"
  >
    <b-form-radio-group
      v-model="internalValue"
      button-variant="outline-primary"
      size="sm"
      buttons
      @input="handleInput($event)"
    >
      <b-form-radio
        v-for="option in filterOptions"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
      >
        <span
          v-b-tooltip.v-warning
          :title="
            option.disabled
              ? 'ไม่สามารถเบิกจ่ายบัตรปชช.ใบเดียวฯ เนื่องจากเลขบัตรไม่ถูกต้อง'
              : ''
          "
          :disabled="!option.disabled"
        >
          <img
            v-if="option.src"
            class="mr-1"
            style="width: auto; height: 20px"
            :src="option.src"
          />
          {{ option.text }}</span
        >
      </b-form-radio>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
import NHSOLogo from "../../assets/img/NHSO_logo.png";
// import PaotangLogo from "../../assets/img/Paotang-small.png";

export default {
  props: ["state", "patient", "value"],
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      internalValue: this.value,
      options: [
        { text: "คนไข้ชำระเอง", value: "DentCloud" },
        // {
        //   text: "ชำระด้วยเป๋าตัง",
        //   value: "Paotang",
        //   src: PaotangLogo,
        // },
        {
          text: "บัตรปชช. ใบเดียวฯ",
          value: "D10",
          src: NHSOLogo,
        },
        {
          text: "D11",
          value: "D11",
          src: NHSOLogo,
        },
        {
          text: "D20",
          value: "D20",
          src: NHSOLogo,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      nhsoSupportedItems: "moduleUser/getNhsoSupportedItems",
    }),
    filterOptions() {
      const dentCloudOption = this.options.find(
        opt => opt.value === "DentCloud"
      );
      const nhsoOptions = this.options.filter(
        option =>
          option.value !== "DentCloud" &&
          this.nhsoSupportedItems.includes(option.value)
      );

      let list = [dentCloudOption, ...nhsoOptions];

      if (!this.validatePersonalId) {
        list = list.map(i => {
          if (i.value !== "DentCloud") {
            return { ...i, disabled: true };
          }
          return i;
        });
      }

      return list;
    },
    validatePersonalId() {
      return /^\d{13}$/.test(this.patient.personalId);
    },
  },
  methods: {
    handleInput(newValue) {
      this.internalValue = newValue;
      this.$emit("input", newValue);
    },
  },
};
</script>