<template>
  <div>
    <div ref="body">
      <!-- ข้อมูลผู้ป่วย -->
      <p style="text-indent: 50px; line-height: 2">
        ข้าพเจ้า
        <template v-if="documentData.patient && documentData.patient.title">
          ท{{ documentData.patient.title }}
        </template>
        {{ documentData.ptFullname }}
        เลขประจำตัวประชาชน
        {{ documentData.patient && documentData.patient.idNumber ? documentData.patient.idNumber : '______________________________' }}
        <template v-if="documentData.patient && documentData.patient.address">
          ที่อยู่ {{ documentData.patient.address }}
        </template>
        ได้รับการอธิบายถึงแผนการรักษาทางทันตกรรมจัดฟันแบบใสจากทันตแพทย์ผู้ทำการรักษา
        และมีความเข้าใจในรายละเอียดดังต่อไปนี้
      </p>

      <!-- รายละเอียดการรักษา -->
      <div class="treatment-details mb-4">
        <h5 class="font-weight-bold mb-3">รายละเอียดการรักษา: การจัดฟันแบบใส</h5>

        <div class="purpose mb-4">
          <h6 class="font-weight-bold">1. วัตถุประสงค์ของการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            การจัดฟันแบบใส (Clear Aligners) เป็นการรักษาเพื่อแก้ไขการเรียงตัวของฟัน
            และปัญหาการสบฟัน โดยใช้เครื่องมือจัดฟันแบบใสที่ถอดได้
            ซึ่งจะค่อยๆ เคลื่อนฟันไปยังตำแหน่งที่วางแผนไว้
            โดยการเปลี่ยนชุดจัดฟันใสเป็นระยะตามแผนการรักษา
          </p>
        </div>

        <div class="treatment-process mb-4">
          <h6 class="font-weight-bold">2. ขั้นตอนการรักษา</h6>
          <ol class="treatment-steps">
            <li>การตรวจวินิจฉัยและสแกนฟันเพื่อวางแผนการรักษาด้วยระบบดิจิทัล</li>
            <li>การเตรียมช่องปากก่อนการจัดฟัน (ถ้าจำเป็น)</li>
            <li>การติด attachment (ปุ่มยึด) บนฟันบางซี่ตามความจำเป็น</li>
            <li>การใส่ชุดจัดฟันใสตามลำดับที่กำหนด</li>
            <li>การติดตามผลการรักษาและปรับแผนตามความจำเป็น</li>
            <li>การใส่เครื่องมือคงสภาพฟันหลังการรักษา</li>
          </ol>
        </div>

        <div class="usage-requirements mb-4">
          <h6 class="font-weight-bold">3. ข้อกำหนดในการใช้งาน</h6>
          <ul class="requirements-list">
            <li>ต้องใส่เครื่องมืออย่างน้อย 20-22 ชั่วโมงต่อวัน</li>
            <li>ถอดเครื่องมือเฉพาะเวลารับประทานอาหารและแปรงฟันเท่านั้น</li>
            <li>เปลี่ยนชุดจัดฟันใสตามกำหนด (โดยทั่วไปทุก 1-2 สัปดาห์)</li>
            <li>ทำความสะอาดเครื่องมือและฟันอย่างสม่ำเสมอ</li>
          </ul>
        </div>

        <div class="risks mb-4">
          <h6 class="font-weight-bold">4. ความเสี่ยงและภาวะแทรกซ้อนที่อาจเกิดขึ้น</h6>
          <div class="ml-4">
            <p class="font-weight-bold">ก. ภาวะแทรกซ้อนทั่วไป:</p>
            <ul class="complication-list mb-5">
              <li>ความไม่สบายในช่วงแรกของการใส่เครื่องมือและเมื่อเปลี่ยนชุดใหม่</li>
              <li>การพูดไม่ชัดในระยะแรก</li>
              <li>การระคายเคืองจาก attachment หรือขอบเครื่องมือ</li>
            </ul>

            <p class="font-weight-bold mt-3">ข. ความเสี่ยงที่อาจเกิดขึ้น:</p>
            <ul class="complication-list">
              <li>ผลการรักษาอาจไม่เป็นไปตามที่คาดหวังหากไม่ใส่เครื่องมือตามกำหนด</li>
              <li>อาจต้องใช้เวลารักษานานกว่าที่วางแผนไว้</li>
              <li>อาจต้องมีการแก้ไขแผนการรักษาระหว่างทาง</li>
            </ul>
          </div>
        </div>

        <div class="limitations mb-4">
          <h6 class="font-weight-bold">5. ข้อจำกัดของการรักษา</h6>
          <ul class="limitation-list">
            <li>ความสำเร็จของการรักษาขึ้นอยู่กับความร่วมมือในการใส่เครื่องมือ</li>
            <li>บางกรณีอาจต้องใช้การจัดฟันแบบดั้งเดิมร่วมด้วย</li>
            <li>อาจมีความจำเป็นต้องทำการรักษาเพิ่มเติมหลังจบการรักษาหลัก (refinement)</li>
          </ul>
        </div>

        <div class="alternatives mb-4">
          <h6 class="font-weight-bold">6. ทางเลือกอื่นในการรักษา</h6>
          <ul class="alternative-list">
            <li>การจัดฟันแบบดั้งเดิม (Traditional Braces)</li>
            <li>การจัดฟันแบบติดด้านใน (Lingual Braces)</li>
            <li>การรักษาแบบไม่จัดฟัน (เช่น การทำวีเนียร์ในบางกรณี)</li>
            <li>การไม่รักษา (แต่ปัญหาการสบฟันอาจส่งผลต่อสุขภาพช่องปากในระยะยาว)</li>
          </ul>
        </div>

        <div v-if="documentData.commentDetail">
          <p class="font-weight-bold">หมายเหตุ:</p>
          <p class="my-justify" style="text-indent: 50px; line-height: 2">
            {{ documentData.commentDetail }}
          </p>
        </div>

        <!-- การยินยอม -->
        <div class="consent-section mt-4">
          <h6 class="font-weight-bold">การยินยอมการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            ข้าพเจ้าได้รับทราบถึงวิธีการรักษา ข้อกำหนดในการใช้งาน ประโยชน์ที่จะได้รับ
            และความเสี่ยงที่อาจเกิดขึ้นจากการรักษา รวมถึงทางเลือกอื่นในการรักษาแล้ว
            ข้าพเจ้ายินยอมให้ทำการรักษาด้วยการจัดฟันแบบใสตามที่ได้วางแผนไว้
          </p>
        </div>

        <!-- ลายเซ็น -->
        <div class="signature-section mt-8">
          <b-row class="mb-5">
            <b-col cols="5" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ผู้ป่วย/ผู้ให้ความยินยอม</div>
              </div>
              <div class="mt-2">({{ documentData.ptFullname }})</div>
              <div class="mt-2">วันที่: {{ documentData.consentDate || ".................." }}</div>
            </b-col>
            <b-col cols="5" offset="2" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ทันตแพทย์ผู้ให้การรักษา</div>
              </div>
              <div class="mt-2">({{ documentData.title }}{{ documentData.doctorObj ? documentData.doctorObj.fullName : '' }})</div>
              <div class="mt-2">วันที่: {{ documentData.consentDate || ".................." }}</div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ClearAlignerConsentForm',
  props: {
    documentData: {
      type: Object,
      required: true,
      default: () => ({
        clinicName: '',
        clinicAddress: '',
        clinicPhone: '',
        ptFullname: '',
        patient: {
          idNumber: '',
          dob: null,
          address: '',
          phone: '',
          dn: '',
          title: ''
        },
        title: '',
        doctorObj: {
          fullName: ''
        },
        consentDate: null,
        commentDetail: ''
      })
    }
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return
      const date = moment(dateString, 'YYYY-MM-DD')
      return moment().diff(date, 'years')
    }
  }
}
</script>

<style scoped>
.treatment-steps,
.requirements-list,
.complication-list,
.limitation-list,
.alternative-list {
  padding-left: 2rem;
  line-height: 2;
}

.treatment-steps li,
.requirements-list li,
.complication-list li,
.limitation-list li,
.alternative-list li {
  margin-bottom: 0.5rem;
}

.signature-line {
  margin-bottom: 0.5rem;
}

.font-weight-bold {
  font-weight: bold;
}

.signature-section {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

@media print {
  .signature-section {
    page-break-inside: avoid;
  }
}
</style>