<template>
  <b-row class="small-text component-treatmentHx">
    <b-col cols="12" lg="6">
      <b-card body-class="p-2">
        <b-row>
          <b-col cols="3">
            <b-avatar
              class="mb-1"
              :variant="patient.contentId ? 'white' : 'info'"
              :text="abbreviation()"
              alt="User Image"
              size="60px"
              :src="viewFile(patient.contentId)"
              square
              rounded
            ></b-avatar>
          </b-col>
          <b-col cols="9">
            <div class="float-right" style="display: flex; align-items: center">
              <HealthLinkButton :patient="patient" />
              <b-button class="ml-2" size="sm" @click="editCustomer(patient)">
                <i class="fas fa-info"></i>
              </b-button>
            </div>

            <p class="mb-0 narrow-spacing">
              DN: {{ patient.dn }} <CopyToClipboard :text="patient.dn" />
            </p>
            <p
              v-if="patient.existDn"
              class="mb-1 text-bold text-muted description narrow-spacing"
            >
              DN(เดิม): {{ patient.existDn }}
              <CopyToClipboard :text="patient.existDn" />
            </p>
            <p class="mb-1 narrow-spacing">
              <strong>{{ fullName() }}</strong>
              <i v-if="patient.dob">( {{ calAge(patient.dob) }} ปี )</i>
            </p>
            <p v-if="patient.congenitalDisease" class="text-danger mb-1">
              โรคประจำตัว:{{ patient.congenitalDisease }}
            </p>
            <p v-if="patient.allergic" class="text-danger mb-1">
              แพ้ยา: {{ patient.allergic }}
            </p>
            <p v-if="isPatientDoctor" class="mb-0">
              แพทย์ประจำ:
              {{ getDoctorListBy(patient.doctorId, "uid").fullName }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <div v-if="isItemTableBusy" class="text-center mb-3">
        <b-spinner variant="primary" type="grow"></b-spinner>
      </div>
      <div v-else>
        <b-row>
          <b-col cols="12" sm="6">
            <b-button
              class="mb-3"
              size="sm"
              variant="primary"
              :disabled="!hasOrthoPackage"
              @click="openPatientOrthoPkgModal"
              block
              >{{
                hasOrthoPackage ? "แพ็กเกจจัดฟัน" : "ไม่มีแพ็กเกจจัดฟัน"
              }}</b-button
            ></b-col
          >
          <b-col cols="12" sm="6">
            <b-button class="mb-3" v-b-toggle.tx-hx-filter size="sm" block
              >ค้นหาประวัติโดยละเอียด</b-button
            >
          </b-col>
        </b-row>
        <b-collapse id="tx-hx-filter">
          <b-row>
            <b-col cols="12" md="6" order="1" order-md="1">
              <b-form-group
                class="mb-1"
                label="ซี่ฟัน:"
                label-cols-sm="3"
                label-align-sm="right"
                label-class="d-none d-sm-block"
              >
                <div id="tooth-filter">
                  <multiselect
                    class="col p-0 vmHideDropdown"
                    v-model="filterTooth"
                    :options="getToothList"
                    :multiple="true"
                    placeholder="เลือกซี่ฟัน"
                    track-by="id"
                    :custom-label="customToothLabel"
                  ></multiselect>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" order="3" order-md="2">
              <date-picker
                class="mb-1"
                v-model="dateRange"
                type="date"
                range
                format="DD MMM YY"
                :shortcuts="dateRangeShortcuts"
                :disabled-date="notAfterToday"
                placeholder="เลือกวันที่ต้องการค้นหา"
                input-class="form-control form-control-sm"
              ></date-picker>
            </b-col>
            <b-col cols="12" md="6" order="2" order-md="3">
              <b-form-group
                class="mb-1"
                label="ค้นหา:"
                label-cols-sm="3"
                label-align-sm="right"
                label-class="d-none d-sm-block"
              >
                <b-input-group>
                  <b-form-input
                    v-model="treatmentFilter"
                    type="search"
                    placeholder="แพทย์/รายการ"
                    size="sm"
                    trim
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" order="4">
              <b-form-group class="mb-1">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="typeSelected"
                  :options="typeOptions"
                  value-field="val"
                  text-field="text"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>

        <b-card no-body class="overflow-auto">
          <b-list-group class="treatment-list-group" flush>
            <b-list-group-item
              v-for="treatment in filteredTreatmentItems"
              :key="treatment.treatId"
              class="flex-column align-items-start p-2 light-gray-bg"
              @click="handleListClick(treatment)"
            >
              <div class="d-flex w-100">
                <p class="font-weight-bold mb-0">
                  {{ formatDate(treatment.creationDt) }}
                </p>
                <p v-if="treatment.nhsoVisitNo > 0" class="ml-1 mr-auto">
                  <img style="width: auto; height: 18px" :src="NHSOLogo" />
                </p>
                <p class="m-0 ml-auto"></p>
                <p
                  v-if="!isFileStorageExpired"
                  v-b-tooltip.hover
                  title="เพิ่มรูป"
                  class="text-muted m-0 ml-2"
                  @click="showUploadModal(treatment.treatId)"
                >
                  <i class="fas fa-plus"></i>
                </p>
                <p
                  v-if="isToday(treatment.creationDt)"
                  class="text-muted m-0 ml-2"
                  v-b-tooltip.hover
                  title="แก้ไขการรักษา"
                  @click="openEditingTreatmentModal(treatment.appointmentId)"
                >
                  <i class="fas fa-edit"></i>
                </p>
                <p
                  v-if="isModified(treatment)"
                  class="text-muted m-0 ml-2"
                  v-b-tooltip.hover
                  title="ดูการแก้ไขย้อนหลัง"
                  @click="openEditingHistory(treatment.treatId)"
                >
                  <i class="fa fa-history"></i>
                </p>
                <p
                  v-if="isDrawNote(treatment.appointmentId)"
                  v-b-tooltip.hover
                  title="มีการบันทึกด้วยการเขียน"
                  class="text-muted m-0 ml-2"
                >
                  <i class="fas fa-pencil-alt"></i>
                </p>
              </div>
              <b-list-group flush>
                <b-list-group-item
                  v-for="item in treatment.items"
                  :key="item.id"
                  class="p-2 border-secondary"
                  :active="treatment.treatId === selectedItem.treatId"
                >
                  <div class="py-1">
                    <div class="d-flex w-100 justify-content-between">
                      <p class="font-weight-bold mb-1">
                        {{ item.treatmentCode ? `[${item.treatmentCode}]-` : ""
                        }}{{ item.treatment }}
                      </p>
                      <p class="mb-0">{{ item.toothNumberSurfaces }}</p>
                    </div>

                    <p v-if="item.dxName" class="mb-1">Dx: {{ item.dxName }}</p>
                    <p
                      v-if="item.note"
                      class="mb-1"
                      style="line-height: 1rem; white-space: pre-line"
                    >
                      {{ item.note }} <CopyToClipboard :text="item.note" />
                    </p>
                    <hr class="mt-0 mb-1 mx-2" />
                    <div class="d-flex w-100 justify-content-between">
                      <small class="mb-0">
                        {{ item.quantity ? `จำนวน:${item.quantity} /` : ""
                        }}{{ item.price ? ` ราคา ${item.price} บาท` : "" }}
                      </small>
                      <small class="mb-0">
                        {{ item.doctor ? `แพทย์: ${item.doctor}` : "" }}
                      </small>
                      <!-- <small>{{ item }}</small> -->
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <!-- {{ treatment }} -->
              <div class="text-right">
                <span
                  v-if="
                    getUserListIncludeDeletedBy(treatment.creationUid, 'uid')
                  "
                  class="font-weight-light small"
                >
                  บันทึกโดย:
                  {{
                    getUserListIncludeDeletedBy(treatment.creationUid, "uid")
                      .fullName
                  }}
                  {{ formatDateTime(treatment.creationDt) }}
                </span>
                <span
                  v-if="isModified(treatment)"
                  class="font-weight-light text-danger small"
                >
                  แก้ไขโดย:
                  {{
                    getUserListIncludeDeletedBy(treatment.modifyUid, "uid")
                      .fullName
                  }}
                  {{ formatDateTime(treatment.modifyDt) }}
                </span>
              </div>
            </b-list-group-item>

            <!-- ไม่มีประวัติ -->
            <b-list-group-item
              v-if="filteredTreatmentItems.length === 0"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0 text-muted">ไม่พบประวัติการรักษา</p>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>
    </b-col>
    <b-col cols="12" lg="6">
      <Gallery label="OPD Card" category="OpdCard" />
      <Gallery
        label="รูปวันที่รักษา"
        :displayList="fileListFilterByTreatmentId"
      />
      <b-card body-class="p-2" border-variant="primary">
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center">
            <div v-if="nextVisitLoading" class="align-self-center">
              <b-spinner small></b-spinner>
            </div>
            <div v-else class="w-100">
              <div v-if="aptDraft.id">
                <b-table-simple class="m-0" small responsive>
                  <b-thead>
                    <b-tr>
                      <b-th class="border-top-0" colspan="12" variant="info"
                        >Next visit</b-th
                      >
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th>นัดกับแพทย์:</b-th>
                      <b-td>{{ aptDraft.doctor || "" }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>เพื่อ:</b-th>
                      <b-td>{{ aptDraft.treatmentItems || "" }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>ระยะเวลา:</b-th>
                      <b-td>{{ aptDraft.duration || "" }} นาที</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>นัดในอีก:</b-th>
                      <b-td
                        >{{ aptDraft.inDays ? aptDraft.inDays : "" }} วัน</b-td
                      >
                    </b-tr>
                    <b-tr>
                      <b-th>โน๊ตเพิ่มเติม:</b-th>
                      <b-td>{{ aptDraft.note || "" }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div v-else class="d-flex justify-content-center">
                <p class="text-muted m-2">ไม่มีข้อมูล Next visit</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col>
          <DisplayDrawNote
            ref="displayDrawNote"
            :drawNote="drawNote"
            :selectedItem="selectedItem"
          />
          <!-- <DisplayDrawNote
            ref="displayDrawNote"
            :drawNote="drawNote"
            :selectedItem="selectedItem"
            @openDrawingTxHxModal="openDrawingTxHxModal"
          /> -->
        </b-col>
      </b-row>
    </b-col>
    <b-popover
      id="toothNumberPop"
      target="tooth-filter"
      triggers="focus"
      placement="rightbottom"
    >
      <div style="width: 200px">
        <TeethDiagram v-model="filterTooth" @close="hideTeethDiagramPopover" />
      </div>
      <div>
        <b-row class="mt-1">
          <b-col class="pr-1">
            <b-button
              block
              variant="outline-info"
              class="p-0"
              size="sm"
              @click="filterTooth = []"
              >เคลียร์</b-button
            >
          </b-col>
          <b-col class="pl-1">
            <b-button
              block
              variant="outline-primary"
              class="p-0"
              size="sm"
              @click="hideTeethDiagramPopover"
              >ตกลง</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-popover>
    <!-- <DrawingTxHxModal ref="drawingTxHxModal" :patient="patient" /> -->
    <PatientOrthoPkgModal ref="patientOrthoPkgModal" :patientId="patient.id" />
    <EditingHistoryModal ref="editingHistoryModal" />
    <UploadFilesModal
      :patientId="patient.id"
      ref="uploadFiles"
      @uploadedFiles="pollForUpdates"
    />
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import DateRangePicker from "vue2-daterange-picker";

import DisplayDrawNote from "@/components/modal/ProfileCustomer/DisplayDrawNote";
import TeethDiagram from "@/components/popover/TeethDiagram";
import PatientOrthoPkgModal from "@/components/modal/ProfileCustomer/PatientOrthoPkg";
import EditingHistoryModal from "@/components/modal/EditingHistory";
import CopyToClipboard from "@/components/CopyToClipboard";
import Gallery from "@/components/card/Gallery";
import UploadFilesModal from "@/components/modal/UploadFiles";
import HealthLinkButton from "@/components/button/HealthLink";

import NHSOLogo from "../../../assets/img/NHSO_logo.png";

import { eventBus } from "@/main";

import moment from "moment";
import numeral from "numeral";

import "@/theme/modal/ProfileCustomer/TreatmentHx.scss";

export default {
  name: "TreatmentHx",
  components: {
    DisplayDrawNote,
    TeethDiagram,
    PatientOrthoPkgModal,
    EditingHistoryModal,
    CopyToClipboard,
    Gallery,
    UploadFilesModal,
    HealthLinkButton,
  },
  props: ["patient", "isItemTableBusy"],
  data() {
    return {
      //date table
      isSelcetedAll: true,
      dateTableFields: [
        {
          key: "creationDt",
          label: "วันที่รักษา",
          sortable: true,
        },
        { key: "time", label: "เวลา" },
      ],

      //items table
      itemsTableFields: [
        { key: "creationDt", label: "วันที่", sortable: true },
        { key: "doctor", label: "แพทย์" },
        {
          key: "toothNumberSurfaces",
          label: "ซี่",
        },
        { key: "treatment", label: "รายการ" },
        { key: "price", label: "฿" },
        { key: "txTypeName", label: "ประเภท", sortable: true },
      ],
      selectedItem: {},
      apptIdSelected: null,
      treatmentFilter: "",
      treatmentId: null,

      //next visit
      aptDraft: {
        id: null,
        doctor: "",
        treatmentName: "",
        durration: null,
        inDays: null,
        note: "",
      },
      nextVisitLoading: false,

      filterTooth: null,

      // toothId: null,
      toothHover: null,

      dateRange: [],

      typeSelected: [],
      typeOptions: [
        { text: "การรักษา", value: 1 },
        { text: "แพ็กเกจจัดฟัน", value: 2 },
        { text: "ยา และอุปกรณ์", value: 3 },
      ],

      drawNote: null,
      NHSOLogo,
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      viewFile: "moduleContent/viewFile",
      getTreatmentHistoryList: "moduleTreatment/getTreatmentHistoryList",
      getUserListIncludeDeletedBy: "moduleUser/getUserListIncludeDeletedBy",
      getUserList: "moduleUser/getUserList",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",
      getAppointmentFormat: "moduleAppointment/getAppointmentFormat",
      getTreatmentBy: "moduleAppointment/getTreatmentBy",
      getTreatBy: "moduleAppointment/getTreatBy",

      getToothList: "moduleMaster/getToothList",
      getToothBy: "moduleMaster/getToothBy",

      getDoctorListBy: "moduleUser/getDoctorListBy",
      getDoctorList: "moduleUser/getDoctorList",

      getPatientOrthoPackage: "moduleTreatment/getPatientOrthoPackage",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
      hasSubscribed: "moduleUser/hasSubscribed",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
      getFileList: "moduleMediaFile/getFileList",
    }),
    treatmentItems() {
      let allItems = this.getTreatmentHistoryList.map(i =>
        i.items.map(j => {
          return {
            ...j,
            appointmentId: i.appointmentId,
            creationUid: i.creationUid,
            creationDt: i.creationDt,
            modifyUid: i.modifyUid,
            modifyDt: i.modifyDt,
            treatId: i.id,
            nhsoVisitNo: i.appointment?.nhsoVisitNo,
          };
        })
      );
      let itemsList = [].concat(...allItems).map(i => this.formatItem(i));
      let drawingItem = this.getTreatmentHistoryList
        .filter(i => i.items.length < 1)
        .map(i => {
          if (i.drawNote?.lines?.length > 0) {
            return this.createDrawingItem(i);
          } else {
            return this.createEmptyItem(i);
          }
        });
      return itemsList.concat(drawingItem);
    },
    isItemSelected() {
      return !(
        this.selectedItem && // 👈 null and undefined check
        Object.keys(this.selectedItem).length === 0 &&
        this.selectedItem.constructor === Object
      );
    },
    filteredTreatmentItems() {
      let list = this.treatmentItems;
      //filter วันที่
      if (this.dateRange[0] && this.dateRange[1]) {
        list = list.filter(item =>
          moment(item.creationDt)
            .locale("th")
            .isBetween(
              moment(this.dateRange[0]),
              moment(this.dateRange[1]),
              "day",
              "[]"
            )
        );
      }

      // filter ซี่ฟัน
      if (this.filterTooth?.length > 0) {
        let filterToothName = this.filterTooth.map(i => i.name);
        list = list.filter(
          i =>
            filterToothName.filter(j => {
              return i.toothNo?.includes(j);
            }).length > 0
        );
      }

      // filter ชื่อรายการ แพทย์
      if (this.treatmentFilter) {
        let filter = this.treatmentFilter.toLowerCase();
        list = list.filter(i => {
          return (
            i.doctor?.toLowerCase().includes(filter) ||
            i.treatment?.toLowerCase().includes(filter)
          );
        });
      }
      //filter ประเภท
      if (this.typeSelected.length > 0) {
        list = list.filter(i => this.typeSelected.includes(i.txTypeId));
      }
      //group ตามวันที่
      list = this.groupListById(list);
      //เรียงวันที่
      list.sort((a, b) => {
        return new Date(b.creationDt) - new Date(a.creationDt);
      });
      return list;
    },
    isPatientDoctor() {
      return (
        this.patient?.doctorId &&
        this.getDoctorListBy(this.patient.doctorId, "uid")
      );
    },
    hasOrthoPackage() {
      return this.getPatientOrthoPackage?.packageId;
    },
    fileListFilterByTreatmentId() {
      if (!this.treatmentId) return [];
      return this.getFileList.filter(i => i.treatmentId === this.treatmentId);
    },
  },
  created() {},
  methods: {
    ...mapActions({
      fetchQueueAppointmentDraftByApptId:
        "moduleAppointment/fetchQueueAppointmentDraftByApptId",
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
      grantToken: "moduleFhir/grantToken",
    }),
    async handleListClick(item) {
      try {
        this.selectedItem = item;
        this.treatmentId = item.treatId;
        if (this.apptIdSelected === this.selectedItem.appointmentId) return;
        this.apptIdSelected = this.selectedItem.appointmentId;
        this.fetchNextVisitData(this.apptIdSelected);
        this.applyDrawNote();
      } catch (err) {
        console.error(err);
      }
    },

    applyDrawNote() {
      this.drawNote = this.getTreatmentHistoryList.find(
        i => i.appointmentId === this.apptIdSelected
      )?.drawNote;
      this.$refs.displayDrawNote.assignDrawNote(this.drawNote);
      // this.$refs.displayDrawNote.resetDrawScale();
    },
    fetchNextVisitData(appointmentId) {
      this.nextVisitLoading = true;

      this.fetchQueueAppointmentDraftByApptId({
        id: appointmentId,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          this.aptDraft = this.formatAppointmentDraft(res?.data);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.nextVisitLoading = false;
        });
    },
    openEditingHistory(treatmentId) {
      this.$refs.editingHistoryModal.show(treatmentId);
    },
    showUploadModal(treatmentId) {
      this.$refs["uploadFiles"].show({ treatmentId });
    },
    formatDayDate(date) {
      if (date) {
        return moment(date).locale("th").format("dd D MMM YY");
      } else {
        return "";
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("llll");
      } else {
        return "";
      }
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    formatDateTime(date) {
      return moment(date).locale("th").format("L LT");
    },
    editCustomer(patient) {
      this.$emit(
        "openCustomerModal",
        Object.assign({}, patient),
        this.appointment
      );
    },
    fullName() {
      if (this.patient) {
        var tilte = this.patient.titleNameTh
          ? this.patient.titleNameTh
          : this.patient.titleNameEn
          ? this.patient.titleNameEn
          : "";
        var firstName = this.patient.firstNameTh
          ? this.patient.firstNameTh
          : this.patient.firstNameEn
          ? this.patient.firstNameEn
          : "";
        var lastName = this.patient.lastNameTh
          ? this.patient.lastNameTh
          : this.patient.lastNameEn
          ? this.patient.lastNameEn
          : "";
        var fullName = [tilte, firstName, lastName].join(" ");
        return fullName;
      } else {
        return "";
      }
    },
    abbreviation() {
      if (
        this.patient &&
        (this.patient.firstNameEn || this.patient.lastNameEn)
      ) {
        var firstName = "";
        var lastName = "";
        if (this.patient.firstNameEn) {
          firstName = this.patient.firstNameEn.charAt(0);
        }
        if (this.patient.lastNameEn) {
          lastName = this.patient.lastNameEn.charAt(0);
        }
        return `${firstName}${lastName}`;
      } else {
        return "";
      }
    },

    formatItem(item) {
      let surfaces = [
        { id: 5, label: "O", order: 1 },
        { id: 3, label: "M", order: 2 },
        { id: 4, label: "D", order: 3 },
        { id: 1, label: "B", order: 4 },
        { id: 2, label: "Li", order: 5 },
      ];
      let surfacesTx =
        item.surfaceItems
          ?.map(i => surfaces.find(j => j.id === i))
          .sort((a, b) => a.order - b.order)
          .map(i => i.label)
          .join("") || "";

      let treatmentTypeName = {
        1: "การรักษา",
        2: "แพ็กเกจจัดฟัน",
        3: "ยา และอุปกรณ์",
      };
      let event = {
        id: item.id,
        actionUid: item.actionUid,
        appointmentId: item.appointmentId,
        treatId: item.treatId,
        creationUid: item.creationUid,
        creationDt: item.creationDt,
        modifyUid: item.modifyUid,
        modifyDt: item.modifyDt,
        doctor:
          this.getUserListIncludeDeletedBy(item.actionUid, "uid")?.fullName ||
          "",
        toothNo: item.toothItems,
        // toothNumberSurfaces: `${item.toothItems} ${surfacesTx}`,
        toothNumberSurfaces: (item.toothItems || "") + (surfacesTx || ""),
        treatment: item.name,
        treatmentCode:
          this.getTreatBy(Number(item.treatItems), "id")?.code || "",
        price: numeral(item.price).format("0,0"),
        note: item.note,
        txTypeId: item.typeId,
        txTypeName: treatmentTypeName[item.typeId],
        dxName: this.getTreatDiagBy(item.diagItems, "id").nameEn,
        quantity: item.quantity,
        nhsoVisitNo: item.nhsoVisitNo,
      };
      return event;
    },

    createDrawingItem(treatment) {
      let event = {
        treatId: treatment.id,
        appointmentId: treatment.appointmentId,
        actionUid: treatment.creationUid,
        creationUid: treatment.creationUid,
        modifyUid: treatment.modifyUid,
        creationDt: treatment.creationDt,
        modifyDt: treatment.modifyDt,
        txTypeId: 1,
        treatment: "-- มีเขียนบันทึก --",
      };
      return event;
    },
    createEmptyItem(treatment) {
      let event = {
        treatId: treatment.id,
        appointmentId: treatment.appointmentId,
        actionUid: treatment.creationUid,
        creationUid: treatment.creationUid,
        modifyUid: treatment.modifyUid,
        creationDt: treatment.creationDt,
        modifyDt: treatment.modifyDt,
        txTypeId: 1,
        treatment: "-- ไม่มีบันทึกรายการรักษา --",
      };
      return event;
    },
    groupListById(list) {
      let groupedList = Object.values(
        list.reduce((result, item) => {
          if (!result[item.treatId]) {
            result[item.treatId] = {
              treatId: item.treatId,
              appointmentId: item.appointmentId,
              nhsoVisitNo: item.nhsoVisitNo,
              creationUid: item.creationUid,
              modifyUid: item.modifyUid,
              // actionUid: item.actionUid || item.creationUid,
              creationDt: item.creationDt,
              modifyDt: item.modifyDt,
              items: [],
            };
          }
          result[item.treatId].items.push(item);
          return result;
        }, {})
      );
      return groupedList;
    },

    formatAppointmentDraft(aptDraftRaw) {
      if (!aptDraftRaw?.id) {
        return {
          id: null,
          doctor: "",
          treatmentName: "",
          durration: null,
          inDays: null,
          note: "",
        };
      }
      let appointmentFormat = this.getAppointmentFormat(aptDraftRaw);
      const startDate = moment(aptDraftRaw.creationDt).startOf("day");
      const endDate = moment(appointmentFormat.start).startOf("day");
      const totalDiffDate = endDate.diff(startDate, "days", true);
      return {
        id: aptDraftRaw.id,
        doctor: appointmentFormat.extendedProps.doctorName,
        treatmentItems: aptDraftRaw.aptTreatItems
          ?.map(i => this.getTreatmentBy(i, "id").nameTh)
          .join(", "),
        duration: appointmentFormat.extendedProps.timeDuration,
        inDays: totalDiffDate,
        note: appointmentFormat?.extendedProps?.note,
      };
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    openPatientOrthoPkgModal() {
      this.$refs.patientOrthoPkgModal.show();
    },
    openEditingTreatmentModal(appointmentId) {
      eventBus.$emit("openPaymentHistoryModalInEditingMode", appointmentId);
    },
    async select1stIndex() {
      await this.$nextTick();
      if (this.filteredTreatmentItems.length < 1) return;
      this.handleListClick(this.filteredTreatmentItems[0]);
    },
    customToothLabel(libs) {
      if (libs.label) {
        return `${libs.label}`;
      } else {
        return `${libs.name}`;
      }
    },
    hideTeethDiagramPopover() {
      this.$root.$emit("bv::hide::popover", "toothNumberPop");
    },
    isDrawNote(id) {
      let arrId = this.getTreatmentHistoryList
        .filter(i => i.drawNote?.lines?.length > 0)
        .map(i => i.appointmentId);
      return arrId.includes(id);
    },
    isModified(treatment) {
      if (
        this.getUserListIncludeDeletedBy(treatment.modifyUid, "uid") &&
        this.getUserListIncludeDeletedBy(treatment.creationUid, "uid")
      ) {
        return (
          treatment.modifyUid !== treatment.creationUid ||
          treatment.creationDt !== treatment.modifyDt
        );
      }
    },
    setDefaultValueTreatmenHx() {
      this.selectedItem = {};
      this.treatmentId = null;
      this.apptIdSelected = null;
      this.treatmentFilter = "";

      //next visit
      this.aptDraft = {
        id: null,
        doctor: "",
        treatmentName: "",
        durration: null,
        inDays: null,
        note: "",
      };

      this.filterTooth = null;
      // this.toothId = null;
      this.toothHover = null;
      this.dateRange = [];
      this.typeSelected = [];
      this.drawNote = null;
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
    isToday(dateTime) {
      return moment(dateTime).isSame(moment(), "day");
    },
    pollForUpdates(e) {
      eventBus.$emit("pollForUpdates", e);
    },
  },
  mounted() {
    eventBus.$on("updateDrawNote", e => {
      this.drawNote = e;
    });
    eventBus.$on(
      "setDefaultValueProfileCustomer",
      this.setDefaultValueTreatmenHx
    );
  },
  beforeDestroy() {
    eventBus.$off("updateDrawNote", e => {
      this.drawNote = e;
    });
    eventBus.$off(
      "setDefaultValueProfileCustomer",
      this.setDefaultValueTreatmenHx
    );
  },
};
</script>

<style>
</style>
