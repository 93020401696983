<template>
  <b-row>
    <div
      class="col-12 col-lg-2 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
    >
      <PatientProfile
        :patient="patient"
        button-name="ข้อมูลติดต่อ"
        :button-click="editCustomer"
      />
    </div>

    <div class="col-12 col-lg-10">
      <b-container fluid class="photo-gallery">
        <b-overlay :show="isFetching" rounded="sm" spinner-variant="primary">
          <b-row>
            <b-col sm="3">
              <template v-if="mode === 'document'">
                <b-row>
                  <b-col class="px-1">
                    <b-form-select
                      v-model="currentDocumentType"
                      :options="documentTypes"
                      @change="showDocument($event)"
                      class="mb-3"
                      variant="success"
                    >
                    </b-form-select>
                  </b-col>
                </b-row>
                <!-- Add a key to force re-render when document type changes -->
                <DocumentGenerator
                  :key="currentDocumentType"
                  ref="documentGenerator"
                  :document-type="currentDocumentType"
                  :modal-id="`${currentDocumentType}-modal`"
                  :modal-ref="`${currentDocumentType}Modal`"
                  modal-class="component-modal-treatment-planning"
                />
              </template>
            </b-col>
            <b-col>
              <div
                class="mb-3 p-1 bg-light rounded d-flex justify-content-between align-items-center"
              >
                <span v-if="selectedFiles.length > 0"
                  >{{ selectedFiles.length }} files selected</span
                >
                <span v-else> </span>
                <div>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-2"
                    @click="handleBulkDownload"
                    :disabled="!selectedFiles.length"
                  >
                    <i class="fas fa-download mr-1"></i> Download Selected
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="handleBulkDelete"
                    :disabled="!selectedFiles.length"
                  >
                    <i class="fas fa-trash-alt mr-1"></i> Delete Selected
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- Selected Files Header - Only show for photo gallery mode -->

          <!-- Category Cards -->
          <CategoryCard
            v-for="category in currentCategories"
            :key="category.value"
            :title="category.text"
            :category="category.value"
            :items="filteredList(category.value)"
            :selected-files="selectedFiles"
            @upload="showUploadModal"
            @select="toggleFileSelection"
            @preview="handlePreview"
            @download="handleDownload"
            @delete="handleDelete"
            @sign="openSignTab"
            @radiiView="openRadiiView"
          />
        </b-overlay>
      </b-container>
    </div>

    <!-- Modals and Overlays -->
    <Lightbox
      :index="lightBoxIndex"
      :items="lightBoxList"
      :toolbar="[
        'counter',
        'zoom',
        'slide',
        'rotate',
        'gallery',
        'fullscreen',
        'close',
      ]"
      @close="resetLightbox"
    />
    <UploadFilesModal
      :patientId="patient.id"
      ref="uploadFiles"
      @uploadedFiles="pollForUpdates"
      @uploadedTemplateFiles="pollForUpdatesTemplate"
    />
    <Loading v-if="isLoading" />
    <Dialog ref="Dialog" />
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";
import VueLightbox from "vue-lightbox-lite";
import { useFileOperations } from "@/composables/useFileOperations";
import CategoryCard from "./PhotoGallery/CategoryCard.vue";
import DocumentGenerator from "@/components/modal/DocumentGenerator/DocumentGenerator.vue";
import { documentTypes } from "@/components/modal/DocumentGenerator/config/documentConfig";

import UploadFilesModal from "@/components/modal/UploadFiles.vue";
import Dialog from "@/components/modal/Dialog.vue";
import Loading from "@/components/Loading.vue";
import { MAX_POLL_ATTEMPS, POLLING_INTERVAL } from "@/constants";
import "vue-lightbox-lite/dist/index.css";
import truncateText from "@/utils/truncateText";
import formatDate from "@/utils/formatDate";

import { debounce } from "lodash";

export default {
  name: "PhotoGallery",
  components: {
    Lightbox: VueLightbox,
    CategoryCard,
    DocumentGenerator,
    UploadFilesModal,
    Dialog,
    Loading,
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "photo",
      validator: function (value) {
        return ["photo", "document"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      currentDocumentType: "empty",
      photoCategories: [
        { text: "Intra-Oral", value: "IntraOralPhoto" },
        { text: "Extra-Oral", value: "ExtraOralPhoto" },
        { text: "X-Ray", value: "XRay" },
        { text: "OPD Card", value: "OpdCard" },
      ],
      documentCategories: [
        { text: "Document", value: "Document" },
        { text: "Template Document", value: "Template" },
      ],
      lightBoxIndex: null,
      lightBoxList: [],
      isLoading: false,
      isFetching: false,
      limit: 100,
      highestId: 0,
      pollInterval: null,
      continuePolling: true,
      fetchAttempts: 0,
      uploadedFiles: null,
      spinnerToastShow: true,
      selectedFiles: [],
      truncateText,
      formatDate,
      storageListener: null,
    };
  },
  computed: {
    ...mapGetters({
      getFileList: "moduleMediaFile/getFileList",
      getFileTemplate: "moduleMediaFile/getFileTemplate",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
    }),
    currentCategories() {
      return this.mode === "photo"
        ? this.photoCategories
        : this.documentCategories;
    },
    documentTypes() {
      return documentTypes.map(item => {
        if (item.options) {
          // Handle grouped options
          return {
            ...item,
            options: item.options.map(option => ({
              ...option,
              disabled:
                option.checkForSubscription && this.isFileStorageExpired,
            })),
          };
        }
        // Handle regular items
        return {
          ...item,
          disabled:
            item.disabled ||
            (item.checkForSubscription && this.isFileStorageExpired),
        };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchMediaFiles: "moduleMediaFile/fecthMediaFiles",
      deleteMediaFile: "moduleMediaFile/deleteMediaFile",
      fetchRadiiViewUrl: "moduleMediaFile/fetchRadiiViewUrl",
      fecthMediaFilesTemplate: "moduleMediaFile/fecthMediaFilesTemplate",
    }),
    editCustomer() {
      this.$emit("openCustomerModal", this.patient, this.appointment);
    },
    handlePreview(item) {
      const list = this.filteredList(item.category);
      this.lightBoxIndex = list.findIndex(i => i.id === item.id);
      this.lightBoxList = list;
    },
    resetLightbox() {
      this.lightBoxIndex = null;
      this.lightBoxList = [];
    },
    showUploadModal(category) {
      this.$refs.uploadFiles.show({ category });
    },
    async openRadiiView({ src }) {
      try {
        const { data } = await this.fetchRadiiViewUrl({ imageUrl: src });
        window.open(data.viewer, "_blank");
      } catch (err) {
        console.error(err);
      }
    },
    async handleDelete(item) {
      try {
        const { isConfirmed } = await this.$refs.Dialog.showAlertConfirm(
          "ลบไฟล์นี้ ?",
          "คุณต้องการลบไฟล์นี้"
        );
        if (isConfirmed) {
          this.$set(item, "isDeleting", true);
          const res = await this.deleteMediaFile({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: item.id,
          });
          if (res.status === 200) {
            this.selectedFiles = this.selectedFiles.filter(
              f => f.id !== item.id
            );
          }
        }
      } catch (err) {
        console.error(err);
        this.$refs.Dialog.showAlert("Error", "Failed to delete file");
      } finally {
        this.$set(item, "isDeleting", false);
      }
    },
    async handleBulkDelete() {
      try {
        const { isConfirmed } = await this.$refs.Dialog.showAlertConfirm(
          "Delete Selected Files?",
          `Are you sure you want to delete ${this.selectedFiles.length} files?`
        );

        if (isConfirmed) {
          const deletePromises = this.selectedFiles.map(async file => {
            this.$set(file, "isDeleting", true);
            try {
              const res = await this.deleteMediaFile({
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
                id: file.id,
              });
              return res.status === 200 ? file.id : null;
            } catch (err) {
              console.error(`Error deleting file ${file.id}:`, err);
              return null;
            } finally {
              this.$set(file, "isDeleting", false);
            }
          });

          const deletedIds = await Promise.all(deletePromises);
          const successfulDeletes = deletedIds.filter(id => id !== null);

          this.selectedFiles = this.selectedFiles.filter(
            file => !successfulDeletes.includes(file.id)
          );

          if (successfulDeletes.length > 0) {
            this.$bvToast.toast(
              `Successfully deleted ${successfulDeletes.length} files`,
              {
                title: "Success",
                variant: "success",
                solid: true,
              }
            );
          }
        }
      } catch (err) {
        console.error("Error in bulk delete:", err);
        this.$refs.Dialog.showAlert("Error", "Failed to delete files");
      }
    },
    async handleDownload(item) {
      try {
        const { downloadFile } = useFileOperations();
        await downloadFile(item, this.patient.id);
      } catch (error) {
        this.$refs.Dialog.showAlert("Download Error", error.message);
      }
    },
    async handleBulkDownload() {
      try {
        const { bulkDownload } = useFileOperations();
        await bulkDownload(this.selectedFiles, this.patient.id);
      } catch (error) {
        this.$refs.Dialog.showAlert(
          "Download Error",
          "Failed to download files"
        );
      }
    },
    toggleFileSelection(file) {
      const index = this.selectedFiles.findIndex(f => f.id === file.id);
      if (index === -1) {
        this.selectedFiles.push(file);
      } else {
        this.selectedFiles.splice(index, 1);
      }
    },
    filteredList(category) {
      if (category === "Template") return this.getFileTemplate;
      return this.getFileList.filter(i => i.category === category);
    },
    async fetchFiles() {
      try {
        const response = await this.fetchMediaFiles({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          params: { patientId: this.patient.id, limit: this.limit },
        });

        const newFiles = response.filter(file => file.id > this.highestId);
        if (
          newFiles.length > 0 &&
          (this.uploadedFiles === null ||
            newFiles.length === this.uploadedFiles?.length)
        ) {
          this.continuePolling = false;
        } else {
          this.fetchAttempts++;
          if (this.fetchAttempts >= MAX_POLL_ATTEMPS) {
            this.continuePolling = false;
          }
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        if (!this.continuePolling) {
          eventBus.$emit("hideSpinnerToast");
          clearInterval(this.pollInterval);
          if (this.getFileList.length > 0) {
            this.highestId = Math.max(...this.getFileList.map(file => file.id));
          }
          this.resetPollingData();
        }
      }
    },
    pollForUpdates(e) {
      if (this.mode === "document") return; // check to run in photo mode only
      if (this.spinnerToastShow) eventBus.$emit("showSpinnerToast");
      this.uploadedFiles = e;
      this.highestId = Math.max(...this.getFileList.map(file => file.id));
      this.pollInterval = setInterval(() => {
        this.fetchFiles();
      }, POLLING_INTERVAL);
    },
    pollForUpdatesTemplate(e) {
      if (this.spinnerToastShow) eventBus.$emit("showSpinnerToast");
      this.uploadedFiles = e;
      this.highestId = Math.max(...this.getFileTemplate.map(file => file.id));
      this.pollInterval = setInterval(() => {
        this.fetchFilesTemplate();
      }, POLLING_INTERVAL);
    },
    async fetchFilesTemplate() {
      try {
        const response = await this.fecthMediaFilesTemplate({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        });

        const newFiles = response.filter(file => file.id > this.highestId);
        if (
          newFiles.length > 0 &&
          (this.uploadedFiles === null ||
            newFiles.length === this.uploadedFiles?.length)
        ) {
          this.continuePolling = false;
        } else {
          this.fetchAttempts++;
          if (this.fetchAttempts >= MAX_POLL_ATTEMPS) {
            this.continuePolling = false;
          }
        }
      } catch (error) {
        console.error("Error fetching template files:", error);
      } finally {
        if (!this.continuePolling) {
          eventBus.$emit("hideSpinnerToast");
          clearInterval(this.pollInterval);
          if (this.getFileTemplate.length > 0) {
            this.highestId = Math.max(
              ...this.getFileTemplate.map(file => file.id)
            );
          }
          this.resetPollingData();
        }
      }
    },
    resetPollingData() {
      this.highestId = 0;
      this.pollInterval = null;
      this.continuePolling = true;
      this.fetchAttempts = 0;
      this.spinnerToastShow = true;
    },
    openSignTab(item) {
      const { clinicUrl, branchUrl } = this.$route.params;
      const url = `${process.env.VUE_APP_FRONT_BASE_URL}/document`;

      function objectToParams(obj) {
        return Object.keys(obj)
          .filter(key => obj[key] !== null)
          .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
          )
          .join("&");
      }
      const params = {
        ...item,
        clinicUrl,
        branchUrl,
        patientId: this.patient?.id,
      };

      window.open(`${url}?${objectToParams(params)}`, "_blank");
    },
    async showDocument() {
      try {
        await this.$nextTick();

        if (!this.$refs.documentGenerator) {
          console.warn("Document generator reference not found");
          return;
        }

        this.$refs.documentGenerator.show(this.patient);
      } catch (error) {
        console.error("Error showing document:", error);
      }
    },
    handleStorageEvent(e) {
      console.log("Storage event received:", e);
      if (e.key === "crossTabEvent" && e.newValue) {
        try {
          const eventData = JSON.parse(e.newValue);
          console.log("Parsed event data:", eventData);

          if (
            eventData.type === "POLL_FOR_UPDATE" &&
            eventData.payload?.source === "signDoc"
          ) {
            console.log("Triggering poll for updates");
            this.pollForUpdates(eventData.payload.files);

            // Clear the event after processing
            localStorage.removeItem("crossTabEvent");
          }
        } catch (error) {
          console.error("Error processing storage event:", error);
        }
      }
    },
  },
  mounted() {
    eventBus.$on("pollForUpdates", this.pollForUpdates);

    this.storageListener = this.handleStorageEvent.bind(this);
    window.addEventListener("storage", this.storageListener);

    if (this.mode === "document") {
      this.fecthMediaFilesTemplate({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
    }
  },
  beforeDestroy() {
    eventBus.$off("pollForUpdates", this.pollForUpdates);
    if (this.storageListener) {
      window.removeEventListener("storage", this.storageListener);
    }
  },
};
</script>

<style scoped>
.photo-gallery .row {
  margin-right: -4px;
  margin-left: -4px;
}

.photo-gallery [class*="col-"] {
  padding-right: 4px;
  padding-left: 4px;
}

.mb-2.p-1.bg-light.rounded {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.img-wrap {
  position: relative;
}

.img-wrap .img-delete-button {
  position: absolute;
  font-size: 1.2em;
  top: -5px;
  right: -3px;
  z-index: 100;
  cursor: pointer;
  display: none;
}

.img-wrap:hover .img-delete-button {
  display: block;
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%);
}
</style>
