<template>
  <b-modal
    modal-class="component-modal-checkin"
    id="CheckinModal"
    ref="CheckinModal"
    title="ส่งตรวจ"
    size="md fluid"
    hide-footer
    @hidden="defaultValue"
  >
    <div class="row">
      <div
        class="col-12 col-lg-4 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
      >
        <PatientProfile
          :patient="patient"
          button-name="ข้อมูลติดต่อ"
          :button-click="
            () => {
              editCustomer(patient);
            }
          "
        />
      </div>
      <div class="col">
        <CheckinRadio
          v-if="branchLinkedWithKtbPaotang || branchLinkedWithNhso"
          :patient="patient"
          :state="!$v.claim.$error"
          v-model="$v.claim.$model"
        />
        <!-- Add date input for D11 or D20 claims -->
        <b-row v-if="['D11', 'D20'].includes(claim)">
          <b-col>
            <b-form-group label="วันที่:" label-align="right" label-cols="3">
              <b-form-datepicker
                v-model="claimDate"
                :state="!$v.claimDate.$error"
                class="mb-2"
                :max="maxDate"
                hide-header
                locale="th"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="แพทย์:" label-align="right" label-cols="3">
              <b-form-select
                class=""
                :class="{ 'is-invalid': $v.doctor.$error }"
                id="doctor"
                v-model="$v.doctor.$model"
                :options="getDoctorList"
                value-field="uid"
                text-field="fullName"
                @change="valueChanged"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >เลือกแพทย์</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="ห้อง:" label-align="right" label-cols="3">
              <b-form-select
                class=""
                :class="{ 'is-invalid': $v.roomId.$error }"
                id="resource"
                v-model="$v.roomId.$model"
                :options="getRoomList"
                value-field="id"
                text-field="title"
                @change="valueChanged"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >เลือกห้อง</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="เพื่อ:" label-align="right" label-cols="3">
              <h6
                v-if="appointment && appointment.treatment"
                class="pt-2"
                v-html="appointment.treatment"
              ></h6>
              <h6 v-else class="pt-2">-</h6>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-textarea
          id="textarea"
          v-model="note"
          placeholder="ส่งข้อความให้ห้องตรวจ เช่น สียาง"
          rows="3"
          max-rows="3"
        ></b-form-textarea>
        <b-row class="mt-3">
          <b-col class="d-flex justify-content-end">
            <b-button class="" variant="primary" @click="saveCheckin()"
              >ส่งตรวจ</b-button
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <template v-slot:modal-footer> </template>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import CheckinRadio from "@/components/button/CheckinRadio";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import { eventBus } from "../../main";

export default {
  name: "CheckinModal",
  components: {
    Loading,
    CheckinRadio,
  },
  data() {
    return {
      isLoading: false,
      patient: {},
      appointment: {},
      doctor: null,
      roomId: null,
      note: "",
      indent: "130px",
      edited: false,
      appointmentDetail: {},
      linkedWithKtbPaotang: false,
      linkedWithNhso: false,
      claim: null,
      claimDate: new Date().toISOString(), // Initialize with today's date
    };
  },
  validations: {
    doctor: { required },
    roomId: { required },
    claim: {
      required: requiredIf(function () {
        return this.branchLinkedWithNhso;
      }),
    },
    claimDate: {
      required: requiredIf(function () {
        return ["D11", "D20"].includes(this.claim);
      }),
    },
  },
  computed: {
    ...mapGetters({
      appointmentList: "moduleAppointment/getQueueAppointmentList",
      getBranchInfo: "moduleUser/getBranchInfo",
      branchLinkedWithKtbPaotang: "moduleUser/getLinkedWithKtbPaotang",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso",
      getRoomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      getDoctorList: "moduleUser/getDoctorList",
      viewAvatar: "moduleContent/viewAvatar",
      patientInfo: "modulePatient/getPatientInfo",
    }),
    validatePersonalId() {
      return /^\d{13}$/.test(this.patient.personalId);
    },
    isProd() {
      return (
        process.env.VUE_APP_MODE === "production" ||
        process.env.VUE_APP_MODE === "gamma"
      );
    },
    maxDate() {
      return new Date();
    },
  },
  created() {
    this.init();
  },
  watch: {
    appointmentDetail() {
      this.appointment = {
        id: this.appointmentDetail.id,
        doctorName: this.appointmentDetail.extendedProps.doctorName,
        patientName: this.getPatientName(this.patient),
        roomId: this.appointmentDetail.extendedProps.roomId,
        treatment: this.appointmentDetail.extendedProps.treatmentTitle,
        callback: data => {
          if (data) {
            const fcTime = $("#appointment-temp .fc-time")[0];
            if (fcTime) {
              fcTime.style.backgroundColor = data.statusColor;
            }
          }
        },
      };
    },
  },
  methods: {
    ...mapActions({
      confirmQueueCheckIn: "moduleAppointment/confirmQueueCheckIn",
      cancelQueueCheckIn: "moduleAppointment/cancelQueueCheckIn",
    }),
    init() {},
    show(index, appointment, patient) {
      this.claimDate = new Date().toISOString().split("T")[0]; // Set default date to today

      if (index === null && !appointment?.id) {
        this.patient = patient;
        this.roomId = appointment?.roomId ?? null;
        this.doctor = appointment?.doctor ?? null;
      } else {
        let eventDetail;
        typeof index === "number"
          ? (eventDetail = this.appointmentList[index])
          : (eventDetail = appointment);
        this.appointmentDetail = eventDetail;
        let eventProps = eventDetail.extendedProps;
        this.patient = eventProps.patient;

        this.appointment = {
          id: this.appointmentDetail.id,
          doctorName: this.appointmentDetail.extendedProps.doctorName,
          patientName: this.getPatientName(this.patient),
          roomId: this.appointmentDetail.extendedProps.roomId,
          treatment: this.appointmentDetail.extendedProps.treatmentTitle,
          callback: data => {
            if (data) {
              const fcTime = $("#appointment-temp .fc-time")[0];
              if (fcTime) {
                fcTime.style.backgroundColor = data.statusColor;
              }
            }
          },
        };

        this.roomId = this.appointmentDetail.extendedProps.roomId;
        this.doctor = this.appointmentDetail.extendedProps.doctor;
      }
      this.$bvModal.show("CheckinModal");
      this.$v.$reset();
    },
    getPatientName(patient) {
      return patient
        ? `${patient.titleNameTh} ${patient.firstNameTh} ${patient.lastNameTh}`
        : "-";
    },
    async saveCheckin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //สร้างนัดหมายใหม่ก่อนส่งตรวจ
      if (!this.appointment?.id) {
        await this.createQuickApt();
      }

      switch (this.claim) {
        case "D10":
        case "D11":
        case "D20":
          this.linkedWithNhso = true;
          this.linkedWithKtbPaotang = false;
          break;
        // case "Paotang":
        //   this.linkedWithKtbPaotang = true;
        //   this.linkedWithNhso = false;
        //   break;
        default:
          this.linkedWithKtbPaotang = false;
          this.linkedWithNhso = false;
          this.claimDate = null;
          break;
      }

      this.isLoading = true;
      const date = new Date(this.claimDate);
      await this.confirmQueueCheckIn({
        event: {
          note: this.note,
          roomId: parseInt(this.roomId),
          aptTreatItems: this.appointmentDetail.extendedProps.treatmentList,
          doctorUid: this.doctor,
          linkedWithKtbPaotang: this.linkedWithKtbPaotang,
          linkedWithNhso: this.linkedWithNhso,
          linkedPatientPersonalId: this.linkedWithKtbPaotang
            ? this.patient.personalId
            : null,
          nhsoSubitemNo: this.claim === "DentCloud" ? null : this.claim,
          nhsoServiceDate: ["DentCloud", "D10"].includes(this.claim)
            ? null
            : date.toISOString(), // Include claimDate in the request
        },
        id: this.appointment.id,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          if (res.status == "200") {
            if (this.appointment.callback) {
              this.appointment.callback(res.data);
            }
          }
          this.isLoading = false;
          this.$bvModal.hide("CheckinModal");
          this.$bvModal.hide("ProfileCustomerModal");
          this.$parent.showDialogToast("success", "ส่งตรวจสำเร็จ");
        })
        .catch(() => {
          this.isLoading = false;
          this.$parent.showDialogToast("warning", "ส่งตรวจไม่สำเร็จ");
        });
    },
    cancelCheckin(index) {
      var eventDetail = this.appointmentList[index];
      var eventProps = eventDetail.extendedProps;
      var patient = eventProps.patient;

      this.appointment = {
        id: eventDetail.id,
        doctorName: eventProps.doctorName,
        patientName: this.getPatientName(patient),
        callback: data => {
          if (data) {
            const fcTime = $("#appointment-temp .fc-time")[0];
            if (fcTime) {
              fcTime.style.backgroundColor = data.statusColor;
            }
          }
        },
      };

      return this.cancelQueueCheckIn({
        id: this.appointment.id,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
    },
    async createQuickApt() {
      let eventData = {
        startDt: this.roundedTime(),
        endDt: moment(this.roundedTime()).add(30, "minutes").format(),
        roomId: parseInt(this.roomId),
        aptTreatItems: [],
        doctorUid: this.doctor,
        patientId: this.patient.id,
        note: "-นัดหมายนี้ถูกสร้างอัตโนมัติ เพื่อส่งตรวจ-",
      };
      this.isLoading = true;
      await this.$store
        .dispatch("moduleAppointment/createQueueAppointment", {
          event: eventData,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(res => {
          if (res.status == "200") {
            this.appointmentDetail = res.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    valueChanged() {
      this.edited = true;
    },
    editCustomer(patient) {
      this.$emit(
        "openCustomerModal",
        Object.assign({}, patient),
        this.appointment
      );
    },
    defaultValue() {
      this.patient = null;
      this.appointment = null;
      this.doctor = null;
      this.roomId = null;
      this.note = "";
      this.edited = false;
      this.linkedWithKtbPaotang = false;
      this.linkedWithNhso = false;
      this.claim = null;
      this.claimDate = null;
    },
    roundedTime(time) {
      const mins = moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
      const rounded = Math.ceil(moment(time).minute() / mins) * mins;
      return moment(time).clone().minute(rounded).second(0).format();
    },
  },
  mounted() {
    eventBus.$on("updatePatientInfo", () => {
      this.patient = this.patientInfo;
    });
  },
};
</script>

<style>
</style>