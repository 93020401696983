// DocumentGenerator.vue
<template>
  <b-modal
    :id="modalId"
    :ref="modalRef"
    size="xl fluid"
    no-close-on-backdrop
    :modal-class="[modalClass, 'enhanced-modal']"
    :ok-title="documentConfig.buttons.print"
    :cancel-title="documentConfig.buttons.clear"
    cancel-variant="danger"
    content-class="modal-content-custom"
  >
    <!-- Enhanced modal title -->
    <template #modal-title>
      <div class="modal-title-custom">
        <h5 class="mb-0 d-flex align-items-center">
          <span class="title-icon mr-2">
            <i class="fas fa-file-medical"></i>
          </span>
          {{ documentConfig.title }}
          <span v-if="documentConfig.subtitle" class="subtitle-badge ml-2">
            {{ documentConfig.subtitle }}
          </span>
        </h5>
      </div>
    </template>

    <!-- Document Form Section with enhanced styling -->
    <div class="form-sections">
      <!-- Patient Info with card styling -->
      <div class="custom-section" v-if="documentConfig.showPatientName">
        <div class="section-header">
          <i class="fas fa-user-circle mr-2"></i>
          Patient Information
        </div>
        <div class="section-content">
          <b-row class="mb-1">
            <b-col cols="2" class="label-col">
              {{ documentConfig.labels.patientName }}:
            </b-col>
            <b-col cols class="value-col">
              {{ ptFullname }}
            </b-col>
          </b-row>

          <b-row v-if="ptAddress && documentConfig.showAddress" class="mb-3">
            <b-col cols="2" class="label-col">
              {{ documentConfig.labels.address }}:
            </b-col>
            <b-col cols class="value-col text-muted">
              {{ ptAddress }}
            </b-col>
          </b-row>
        </div>
      </div>

      <div
        class="custom-section"
        v-if="
          documentConfig.showDoctorTitle ||
          documentConfig.showDoctorSelect ||
          documentConfig.showComments
        "
      >
        <div class="section-header">
          <i class="fas fa-user-md mr-2"></i>
          Doctor Information
        </div>
        <div class="section-content">
          <!-- Your existing doctor selection form elements with enhanced classes -->
          <b-row class="mb-1" v-if="documentConfig.showDoctorTitle">
            <b-col cols="2" class="label-col">
              {{ documentConfig.labels.doctorTitle }}:
            </b-col>
            <b-col>
              <b-form-select
                class="custom-select"
                :class="{ 'is-invalid': $v.title.$error }"
                v-model="$v.title.$model"
                :options="titleOptions"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>{{
                    documentConfig.placeholders.selectTitle
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <!-- Doctor Selection -->
          <b-row class="mb-1" v-if="documentConfig.showDoctorSelect">
            <b-col cols="2" class="label-col"
              >{{ documentConfig.labels.doctor }}:</b-col
            >
            <b-col>
              <b-form-select
                class="custom-select"
                :class="{ 'is-invalid': $v.selectId.$error }"
                v-model="$v.selectId.$model"
                :options="doctorList"
                text-field="fullName"
                value-field="uid"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>{{
                    documentConfig.placeholders.selectDoctor
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>

          <!-- Comments Section -->
          <b-row v-if="documentConfig.showComments">
            <b-col cols="2" class="label-col"
              >{{ documentConfig.labels.comments }}:</b-col
            >
            <b-col sm="10">
              <b-form-textarea
                :placeholder="documentConfig.placeholders.comments"
                rows="6"
                v-model="commentDetail"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- Treatment Table -->
    <div v-if="documentConfig.showTreatmentTable" class="custom-section">
      <div class="section-header">
        <i class="fas fa-table mr-2"></i>
        {{ documentConfig.labels.tableCaption }}
      </div>
      <div class="table-responsive">
        <b-table
          class="custom-table"
          small
          sticky-header="700px"
          sort-icon-left
          sort-by="creationDt"
          sort-desc
          hover
          :items="treatmentItems"
          :fields="tableHeaders"
          select-mode="multi"
          selectable
          @row-selected="onRowSelected"
        >
          <template #table-caption>{{
            documentConfig.labels.tableCaption
          }}</template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </div>
    </div>

    <!-- Print Preview Section -->
    <div class="d-none">
      <div ref="header">
        <b-row>
          <b-col cols="2" class="text-center">
            <b-img fluid :src="logo" :alt="clinic.name" style="height: 95px" />
          </b-col>
          <b-col>
            <h4 class="mb-1">{{ clinic.name }}</h4>
            <p v-if="branchInfo && branchInfo.address" class="mb-1 my-justify">
              {{ branchInfo.address }}
            </p>
            <p class="mb-1">
              <span v-if="branchInfo && branchInfo.tel">
                {{ documentConfig.labels.tel }}: {{ branchInfo.tel }}
              </span>
              <span v-if="branchInfo && branchInfo.line" class="ml-3">
                Line ID: {{ branchInfo.line }}
              </span>
            </p>
            <p v-if="branchInfo && branchInfo.website" class="mb-1">
              {{ branchInfo.website }}
            </p>
          </b-col>
        </b-row>
        <hr />
      </div>

      <!-- Document Body -->
      <div ref="body">
        <!-- Medical Certificate Template -->
        <div v-if="documentType === 'medicalCert'">
          <Medical-certTemplate
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              selectedItem,
              groupSelectedItem,
              commentDetail,
              today,
            }"
          />
        </div>

        <div v-if="documentType === 'dentalImplantConsent'">
          <DentalImplantConsentForm
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              commentDetail,
              today,
            }"
          />
        </div>

        <div v-if="documentType === 'referralLetter'">
          <ReferralLetterTemplate
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              selectedItem,
              groupSelectedItem,
              commentDetail,
              today,
            }"
          />
        </div>

        <div v-if="documentType === 'marketingConsent'">
          <MarketingConsentForm
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              commentDetail,
              consentDate: today,
              marketingConsent: 'no_consent',
            }"
          />
        </div>

        <div v-if="documentType === 'veneerConsent'">
          <VeneerConsentForm
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              commentDetail,
              consentDate: today,
            }"
          />
        </div>

        <div v-if="documentType === 'traditionalBracesConsent'">
          <TraditionalBracesConsentForm
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              commentDetail,
              consentDate: today,
            }"
          />
        </div>

        <div v-if="documentType === 'clearAlignerConsent'">
          <ClearAlignerConsentForm
            :document-data="{
              title,
              doctorObj,
              ptFullname,
              patient,
              commentDetail,
              consentDate: today,
            }"
          />
        </div>
      </div>
    </div>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-if="documentConfig.showUpload"
          variant="info"
          class="custom-btn mr-2"
          :disabled="isFileStorageExpired"
          @click="uploadPdf"
        >
          <i class="fas fa-signature mr-2"></i>
          {{ documentConfig.buttons.upload }}
        </b-button>
        <b-button variant="primary" class="custom-btn" @click="generatePdf">
          <i class="fas fa-print mr-1"></i>
          {{ documentConfig.buttons.print }}
        </b-button>
      </div>
    </template>

    <PdfExport ref="pdfExport" @openSignTabCompleted="hideModal" />
    <Dialog ref="Dialog" />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/modal/Dialog";
import PdfExport from "@/components/PdfExport";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { documentConfig } from "./config/documentConfig";

import MedicalCertTemplate from "./templates/MedicalCertTemplate.vue";
import DentalImplantConsentForm from "./templates/DentalImplantConsentForm.vue";
import ReferralLetterTemplate from "./templates/ReferralLetterTemplate.vue";
import MarketingConsentForm from "./templates/MarketingConsentForm.vue";
import VeneerConsentForm from "./templates/VeneerConsentForm.vue";
import TraditionalBracesConsentForm from "./templates/TraditionalBracesConsentForm.vue";
import ClearAlignerConsentForm from "./templates/ClearAlignerConsentForm.vue";

export default {
  name: "DocumentGenerator",
  components: {
    PdfExport,
    Dialog,
    MedicalCertTemplate,
    DentalImplantConsentForm,
    ReferralLetterTemplate,
    MarketingConsentForm,
    VeneerConsentForm,
    TraditionalBracesConsentForm,
    ClearAlignerConsentForm,
  },

  props: {
    documentType: {
      type: String,
      required: true,
      // validator: function (value) {
      //   return (
      //     [
      //       "empty",
      //       "medicalCert",
      //       "dentalImplantConsent",
      //       "referralLetter",
      //     ].indexOf(value) !== -1
      //   );
      // },
    },
    modalId: {
      type: String,
      default: "document-modal",
    },
    modalRef: {
      type: String,
      default: "documentModal",
    },
    modalClass: {
      type: String,
      default: "component-modal-document",
    },
  },

  data() {
    return {
      patient: {},
      clinic: {},
      logo: null,
      titleOptions: ["ทพ.", "ทพญ."],
      title: null,
      selectId: null,
      commentDetail: "",
      selectedItem: [],
      isPdfCreating: false,
      tableHeaders: [
        {
          key: "selected",
          label: "",
          class: "text-center",
          thStyle: "min-width: 32px",
        },
        {
          label: "วันที่ตรวจ/รักษา",
          sortable: true,
          key: "creationDt",
          formatter: this.formatDate,
        },
        {
          label: "ซี่ฟัน",
          key: "toothNumberSurfaces",
          formatter: function (value) {
            return value || "";
          },
        },
        {
          label: "วินิจฉัย",
          key: "dxName",
        },
        {
          label: "รายการ",
          key: "name",
        },
        {
          label: "แพทย์",
          key: "doctorName",
        },
      ],
    };
  },

  validations() {
    const validations = {};

    if (this.documentConfig.showDoctorTitle) {
      validations.title = { required };
    }

    if (this.documentConfig.showDoctorSelect) {
      validations.selectId = { required };
    }

    return validations;
  },

  computed: {
    ...mapGetters({
      branchInfo: "moduleUser/getBranchInfo",
      getTreatmentHistoryList: "moduleTreatment/getTreatmentHistoryList",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",
      getDoctorList: "moduleUser/getDoctorList",
      getUserList: "moduleUser/getUserList",
      getUserListIncludeDeletedBy: "moduleUser/getUserListIncludeDeletedBy",
      userInfo: "moduleUser/getUserInfo",
      isDoctor: "moduleUser/isDoctor",
      viewFile: "moduleContent/viewFile",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
    }),

    documentConfig() {
      return documentConfig[this.documentType] || {};
    },

    today() {
      return moment().locale("th").add(543, "years").format("D MMMM YYYY");
    },

    ptFullname() {
      return [
        this.patient.titleNameTh,
        this.patient.firstNameTh,
        this.patient.lastNameTh,
      ]
        .filter(Boolean)
        .join(" ");
    },

    ptAddress() {
      const { address, subDistrict, district, province, country, postcode } =
        this.patient;
      return [address, subDistrict, district, province, country, postcode]
        .filter(Boolean)
        .join(" ");
    },

    doctorList() {
      return this.getDoctorList.map(function (doctor) {
        var user = this.getUserList.find(function (user) {
          return user.uid === doctor.uid;
        });
        return Object.assign({}, doctor, {
          drLicenseNo: user ? user.drLicenseNo : null,
        });
      }, this);
    },

    doctorObj() {
      return this.doctorList.find(doctor => this.selectId === doctor.uid) || {};
    },

    treatmentItems() {
      return this.getTreatmentHistoryList
        .map(function (item) {
          return item.items;
        })
        .flat()
        .filter(function (item) {
          return [1, 2].includes(item.typeId);
        })
        .map(item => ({
          ...item,
          doctorName: this.getUserListIncludeDeletedBy(item.actionUid, "uid")
            .fullName,
          dxName: this.getTreatDiagBy(item.diagItems, "id").nameEn,
          toothNumberSurfaces: `${item.toothItems || ""} ${this.formatSurfaces(
            item
          )}`,
        }));
    },

    groupSelectedItem() {
      const sorted = this.selectedItem
        .slice(0)
        .sort((a, b) => Date.parse(a.creationDt) - Date.parse(b.creationDt))
        .map(item => ({
          ...item,
          formattedDate: this.formatDate(item.creationDt),
        }));

      return sorted.reduce((acc, item) => {
        const date = item.formattedDate;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {});
    },
  },

  methods: {
    show(patient) {
      this.$refs[this.modalRef].show();
      this.clinic = this.branchInfo.clinic;
      this.patient = patient;
      this.logo = this.viewFile(this.branchInfo.clinic.logo) || null;
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
    },

    onRowSelected(items) {
      this.selectedItem = items;
    },

    async uploadPdf(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isPdfCreating = true;

      try {
        await this.$nextTick();
        await this.$refs.pdfExport.generatePdf({
          header: this.$refs.header,
          body: this.$refs.body,
          footer: this.$refs.footer,
          option: {
            isUpload: true,
            patientId: this.patient.id,
            remarkTitle: this.documentConfig.filePrefix + "_",
          },
        });
      } finally {
        this.isPdfCreating = false;
        this.$v.$reset();
      }
    },

    async generatePdf(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.$touch();
      // Only validate if there are validation rules
      if (Object.keys(this.$v.$params).length > 0) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      }
      this.isPdfCreating = true;

      try {
        await this.$nextTick();
        await this.$refs.pdfExport.generatePdf({
          header: this.$refs.header,
          body: this.$refs.body,
          footer: this.$refs.footer,
        });
      } finally {
        this.isPdfCreating = false;
        this.$v.$reset();
      }
    },

    formatDate(date) {
      return moment(date).add(543, "years").locale("th").format("D MMMM YYYY");
    },

    formatSurfaces(item) {
      var surfaces = [
        { id: 5, label: "O", order: 1 },
        { id: 3, label: "M", order: 2 },
        { id: 4, label: "D", order: 3 },
        { id: 1, label: "B", order: 4 },
        { id: 2, label: "Li", order: 5 },
      ];

      if (!item.surfaceItems) return "";

      return item.surfaceItems
        .map(function (surfaceId) {
          return surfaces.find(function (s) {
            return s.id === surfaceId;
          });
        })
        .sort(function (a, b) {
          return a.order - b.order;
        })
        .map(function (surface) {
          return surface.label;
        })
        .join("");
    },

    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },

  mounted() {
    if (this.isDoctor) {
      this.selectId = this.userInfo.uid;
    }
  },
};
</script>

<style lang="scss" scoped>
.enhanced-modal {
  // Modal specific styles
  --primary-color: #2c3e50;
  --border-radius: 8px;
  --section-spacing: 1.25rem;

  .modal-content-custom {
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .modal-title-custom {
    color: var(--primary-color);

    .title-icon {
      color: #3498db;
    }

    .subtitle-badge {
      background: #e74c3c;
      color: white;
      padding: 0.25rem 0.75rem;
      border-radius: 50px;
      font-size: 0.875rem;
    }
  }

  // Section styling
  .custom-section {
    background: #ffffff;
    border: 1px solid #e1e8ef;
    border-radius: var(--border-radius);
    margin-bottom: var(--section-spacing);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);

    .section-header {
      background: #f8fafc;
      color: var(--primary-color);
      padding: 0.75rem 1rem;
      font-weight: 600;
      border-bottom: 1px solid #e1e8ef;
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    .section-content {
      padding: 1rem;
    }
  }

  // Form styling
  .label-col {
    color: #606f7b;
    font-weight: 500;
  }

  .value-col {
    color: #2d3748;
  }

  .custom-select {
    border-color: #dae1e7;
    border-radius: 4px;
    transition: border-color 0.2s;

    &:focus {
      border-color: #3498db;
      box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
    }

    &.is-invalid {
      border-color: #e74c3c;
    }
  }

  // Table styling
  .custom-table {
    border: none;
    margin-bottom: 0;

    thead th {
      background: #f8fafc;
      border-bottom: 2px solid #dae1e7;
      color: var(--primary-color);
      font-weight: 600;
      padding: 0.75rem;
    }

    tbody {
      tr {
        &:hover {
          background-color: #f8fafc !important;
        }

        td {
          padding: 0.75rem;
          border-bottom: 1px solid #edf2f7;
          vertical-align: middle;
        }
      }

      tr:last-child td {
        border-bottom: none;
      }
    }
  }

  // Button styling
  .custom-btn {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.2s;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  // Responsive table container
  .table-responsive {
    border-radius: var(--border-radius);
    border: 1px solid #e1e8ef;
  }
}
</style>