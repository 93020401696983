# templates/VeneerConsentForm.vue
<template>
  <div>
    <div ref="body">
      <!-- ข้อมูลผู้ป่วย -->
      <p style="text-indent: 50px; line-height: 2">
        ข้าพเจ้า
        <template v-if="documentData.patient && documentData.patient.title">
          ท{{ documentData.patient.title }}
        </template>
        {{ documentData.ptFullname }}
        เลขประจำตัวประชาชน
        {{
          (documentData.patient && documentData.patient.idNumber) ||
          "______________________________"
        }}
        <template v-if="documentData.patient && documentData.patient.address">
          ที่อยู่ {{ documentData.patient.address }}
        </template>
        ได้รับการอธิบายถึงแผนการรักษาทางทันตกรรมจากทันตแพทย์ผู้ทำการรักษา
        และมีความเข้าใจในรายละเอียดดังต่อไปนี้
      </p>

      <!-- รายละเอียดการรักษา -->
      <div class="treatment-details mb-4">
        <h5 class="font-weight-bold mb-3">รายละเอียดการรักษา: วีเนียร์</h5>

        <div class="purpose mb-4">
          <h6 class="font-weight-bold">1. วัตถุประสงค์ของการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            การทำวีเนียร์ (Veneer)
            เป็นการรักษาเพื่อปรับปรุงความสวยงามของฟันด้านหน้า
            โดยการติดแผ่นวัสดุบางๆ ที่ทำจากเซรามิกหรือวัสดุคอมโพสิต
            เพื่อแก้ไขปัญหาเรื่องสี รูปร่าง และการเรียงตัวของฟัน
          </p>
        </div>

        <div class="steps mb-4">
          <h6 class="font-weight-bold">2. ขั้นตอนการรักษา</h6>
          <ol class="treatment-steps">
            <li>การตรวจและวางแผนการรักษา รวมถึงการถ่ายภาพและทำแบบจำลองฟัน</li>
            <li>การเตรียมผิวฟันโดยการกรอแต่งผิวฟันเล็กน้อย</li>
            <li>การพิมพ์ปากเพื่อทำวีเนียร์ชั่วคราว</li>
            <li>การลองวีเนียร์และปรับแต่งให้เข้ากับสีและรูปร่างที่ต้องการ</li>
            <li>การยึดวีเนียร์ถาวร</li>
          </ol>
        </div>

        <!-- ความเสี่ยงและภาวะแทรกซ้อน -->
        <div class="risks mb-4">
          <h6 class="font-weight-bold">
            3. ความเสี่ยงและภาวะแทรกซ้อนที่อาจเกิดขึ้น
          </h6>
          <div class="ml-4">
            <p class="font-weight-bold">ก. ภาวะแทรกซ้อนระยะสั้น:</p>
            <ul class="complication-list">
              <li>อาการเสียวฟันหลังการรักษา (มักหายได้เอง)</li>
              <li>ความไม่สบายในการสบฟันในช่วงแรก</li>
              <li>การระคายเคืองเหงือกชั่วคราว</li>
            </ul>

            <p class="font-weight-bold mt-3">ข. ภาวะแทรกซ้อนระยะยาว:</p>
            <ul class="complication-list">
              <li>วีเนียร์อาจแตกหรือหลุดได้หากใช้งานไม่ระมัดระวัง</li>
              <li>อาจต้องเปลี่ยนวีเนียร์ใหม่หลังการใช้งานระยะหนึ่ง</li>
              <li>สีของวีเนียร์อาจเปลี่ยนไปตามเวลา</li>
            </ul>
          </div>
        </div>

        <!-- ข้อจำกัดและการดูแลรักษา -->
        <div class="limitations mb-4">
          <h6 class="font-weight-bold">4. ข้อจำกัดและการดูแลรักษา</h6>
          <ul class="limitation-list">
            <li>หลีกเลี่ยงการกัดของแข็ง หรือใช้ฟันในการงัดหรือฉีกสิ่งของ</li>
            <li>ต้องรักษาสุขภาพช่องปากให้ดี และมาตรวจตามนัดอย่างสม่ำเสมอ</li>
            <li>วีเนียร์ไม่สามารถเปลี่ยนสีได้ด้วยการฟอกสีฟัน</li>
            <li>อาจต้องมีการบูรณะซ่อมแซมหรือเปลี่ยนใหม่ในอนาคต</li>
          </ul>
        </div>

        <!-- ทางเลือกอื่นในการรักษา -->
        <div class="alternatives mb-4">
          <h6 class="font-weight-bold">5. ทางเลือกอื่นในการรักษา</h6>
          <ul class="alternative-list">
            <li>การจัดฟัน</li>
            <li>การครอบฟัน</li>
            <li>การทำคอมโพสิตโดยตรง</li>
            <li>การฟอกสีฟัน (กรณีปัญหาเรื่องสีเท่านั้น)</li>
          </ul>
        </div>

        <div v-if="documentData.commentDetail">
          <p class="font-weight-bold">หมายเหตุ :</p>
          <p class="my-justify" style="text-indent: 50px; line-height: 2">
            {{ documentData.commentDetail }}
          </p>
        </div>

        <!-- การยินยอม -->
        <div class="consent-section mt-4">
          <h6 class="font-weight-bold">การยินยอมการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            ข้าพเจ้าได้รับทราบถึงวิธีการรักษา ประโยชน์ที่จะได้รับ
            และความเสี่ยงที่อาจเกิดขึ้นจากการรักษา
            รวมถึงทางเลือกอื่นในการรักษาแล้ว
            ข้าพเจ้ายินยอมให้ทำการรักษาด้วยวีเนียร์ตามที่ได้วางแผนไว้
          </p>
        </div>

        <!-- ลายเซ็น -->
        <div class="signature-section mt-8">
          <div class="row mb-5">
            <div class="col-5 text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ผู้ป่วย/ผู้ให้ความยินยอม</div>
              </div>
              <div class="mt-2">({{ documentData.ptFullname }})</div>
              <div class="mt-2">
                วันที่: {{ documentData.consentDate || ".................." }}
              </div>
            </div>

            <div class="col-5 offset-2 text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ทันตแพทย์ผู้ให้การรักษา</div>
              </div>
              <div class="mt-2">
                ({{ documentData.title }}{{ documentData.doctorObj && documentData.doctorObj.fullName }})
              </div>
              <div class="mt-2">
                วันที่: {{ documentData.consentDate || ".................." }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "VeneerConsentForm",
  props: {
    documentData: {
      type: Object,
      required: true,
      default: () => ({
        clinicName: "",
        clinicAddress: "",
        clinicPhone: "",
        ptFullname: "",
        patient: {
          idNumber: "",
          dob: null,
          address: "",
          phone: "",
          dn: "",
        },
        title: "",
        doctorObj: {
          fullName: "",
        },
        consentDate: null,
        commentDetail: "",
      }),
    },
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
};
</script>

<style scoped>
.treatment-steps,
.complication-list,
.limitation-list,
.alternative-list {
  padding-left: 2rem;
  line-height: 2;
}

.treatment-steps li,
.complication-list li,
.limitation-list li,
.alternative-list li {
  margin-bottom: 0.5rem;
}

.signature-line {
  margin-bottom: 0.5rem;
}

.font-weight-bold {
  font-weight: bold;
}

.signature-section {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.offset-2 {
  margin-left: 16.666667%;
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 3rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

@media print {
  .signature-section {
    page-break-inside: avoid;
  }
}
</style>