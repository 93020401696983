var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-drawing"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading-overlay"},[_c('p',[_vm._v("Adding Page, please wait...")])]),_c('div',{staticClass:"sticky-parent"},[_c('div',{staticClass:"sticky-top mt-2 mr-2",attrs:{"id":"tx-drawing-buttons"}},[_c('b-button-group',{staticClass:"mr-3",attrs:{"size":"sm"}},[_c('b-button',{staticClass:"px-auto mx-auto width-45px",attrs:{"variant":"outline-primary","pressed":!_vm.eraserMode},on:{"click":function($event){_vm.eraserMode = false},"touchstart":function($event){_vm.eraserMode = false}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('b-button',{staticClass:"mx-auto width-45px",attrs:{"variant":"outline-info","pressed":_vm.eraserMode},on:{"click":function($event){_vm.eraserMode = true},"touchstart":function($event){_vm.eraserMode = true}}},[_c('i',{staticClass:"fas fa-eraser"})])],1),_c('b-button-group',{staticClass:"mr-3",attrs:{"size":"sm"}},[_c('VSwatches',{attrs:{"popover-x":"right"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},[_c('b-button',{staticClass:"px-auto mx-auto width-45px rounded-left",style:('background-color:' +
              _vm.color +
              ';cursor: pointer; height: 31px;border-radius: 0px'),attrs:{"slot":"trigger","size":"sm"},on:{"click":function($event){_vm.eraserMode = false}},slot:"trigger"},[_c('i',{staticClass:"fas fa-palette"})])],1),_c('b-button',{staticClass:"width-45px",attrs:{"id":"btn-thickness","variant":"light","size":"sm"},on:{"click":function($event){_vm.eraserMode = false}}},[_c('hr',{staticClass:"m-auto border-0 rounded",style:({
              height: _vm.thickness + 'px',
              backgroundColor: _vm.color,
            })})])],1),_c('b-button-group',{staticClass:"mr-3",attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.historyStep <= 0},on:{"click":_vm.undoStroke}},[_c('i',{staticClass:"fas fa-undo"})]),_c('b-button',{attrs:{"variant":"info","disabled":_vm.historyStep === _vm.historyLines.length},on:{"click":_vm.redoStroke}},[_c('i',{staticClass:"fas fa-redo"})])],1),_c('b-button-group',{staticClass:"mr-3",attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":"warning"},on:{"click":_vm.loadLinesFromLocalStorage}},[_c('i',{staticClass:"fas fa-paste"})])],1),_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.clearLines}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)]),_vm._l((_vm.pages),function(page,index){return _c('div',{key:index,staticClass:"overflow-hidden"},[_c('v-stage',{ref:'stage' + index,refInFor:true,attrs:{"id":'stage' + index,"config":_vm.getconfigKonva(index)},on:{"mousedown":function($event){return _vm.mouseDrawing($event, index)},"touchstart":function($event){return _vm.pencilDrawing($event, index)},"mouseup":function($event){return _vm.stopDrawing($event, index)},"touchend":function($event){return _vm.stopDrawing($event, index)},"mousemove":function($event){return _vm.drawing($event, index)},"touchmove":function($event){return _vm.drawing($event, index)}}},[_c('v-layer',[_c('v-rect',{attrs:{"config":page.configBackground}}),_c('v-text',{attrs:{"config":page.configText}}),_vm._l((_vm.lines[index]),function(line,lineindex){return _c('v-line',{key:("page-" + index + "-" + lineindex),attrs:{"config":line}})}),_c('v-image',{ref:"addPageIcon",refInFor:true,attrs:{"config":{
            x: page.configBackground.x + page.configBackground.width / 2 + 66,
            y: page.configBackground.y + page.configBackground.height - 40,
            width: 24,
            height: 24,
            image: _vm.pageIconImage, // Pass the preloaded image directly
            draggable: false, // Ensure it doesn't move when clicked
            listening: true, // Enable interactivity
            name: 'addPageIcon',
          }},on:{"click":function($event){return _vm.handleAddPageIconClick($event, index)},"mouseenter":function($event){return _vm.onAddPageIconHover(true, index)},"mouseleave":function($event){return _vm.onAddPageIconHover(false, index)},"touchstart":function (e) {
              _vm.handleAddPageIconClick(e, index);
              _vm.onAddPageIconHover(true, index);
            },"touchend":function($event){return _vm.onAddPageIconHover(false, index)}}}),_c('v-text',{attrs:{"config":{
            x: page.configBackground.x + page.configBackground.width / 2 + 3, // Adjust as needed
            y: page.configBackground.y + page.configBackground.height - 30, // Adjust as needed
            text: 'Page ' + (index + 1),
            fontSize: 16,
            fill: 'gray',
            align: 'center',
            shadowColor: 'white',
            shadowBlur: 5,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
          }}}),_c('v-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.pages.length > 1),expression:"pages.length > 1"}],ref:"deleteIcon",refInFor:true,attrs:{"config":{
            x: page.configBackground.x + page.configBackground.width / 2 - 30,
            y: page.configBackground.y + page.configBackground.height - 40,
            width: 22,
            height: 24,
            image: _vm.iconImage, // Pass the preloaded image directly
            draggable: false, // Ensure it doesn't move when clicked
            opacity: _vm.pages.length > 1 ? 1 : 0.2,
            listening: _vm.pages.length > 1,
            name: 'deleteIcon',
          }},on:{"click":function($event){return _vm.handleDeleteIconClick($event, index)},"mouseenter":function($event){return _vm.onDeleteIconHover(true, index)},"mouseleave":function($event){return _vm.onDeleteIconHover(false, index)},"touchstart":function (e) {
              _vm.handleDeleteIconClick(e, index);
              _vm.onDeleteIconHover(true, index);
            },"touchend":function($event){return _vm.onDeleteIconHover(false, index)}}})],2)],1),_c('br'),_c('br')],1)}),_c('b-popover',{staticClass:"p-0",attrs:{"id":"popoverThickness","target":"btn-thickness","placement":"bottom","triggers":"click blur"}},[_c('b-button-group',[_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 0.6},on:{"click":function($event){(_vm.thickness = 0.6), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '1px',
            width: '1px',
          })})]),_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 1.2},on:{"click":function($event){(_vm.thickness = 1.2), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '2px',
            width: '2px',
          })})]),_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 2},on:{"click":function($event){(_vm.thickness = 2), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '3px',
            width: '3px',
          })})]),_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 4},on:{"click":function($event){(_vm.thickness = 4), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '4px',
            width: '4px',
          })})]),_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 9},on:{"click":function($event){(_vm.thickness = 9), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '9px',
            width: '9px',
          })})]),_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 14},on:{"click":function($event){(_vm.thickness = 14), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '14px',
            width: '14px',
          })})]),_c('b-button',{attrs:{"variant":"light","pressed":_vm.thickness == 20},on:{"click":function($event){(_vm.thickness = 20), _vm.hidePopover()}}},[_c('span',{staticClass:"spanDot",style:({
            height: '20px',
            width: '20px',
          })})])],1)],1),_c('Dialog',{ref:"Dialog"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }