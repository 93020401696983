<template>
  <b-modal
    id="QuickEventModal"
    ref="QuickEventModal"
    title="กรุณาเลือกแพทย์ ห้อง และเวลา"
    centered
    @hidden="defaultValue"
  >
    <b-form id="quickEventForm" @submit.prevent="saveEvent">
      <CheckinRadio
        v-if="branchLinkedWithKtbPaotang || branchLinkedWithNhso"
        :patient="patient"
        :state="!$v.claim.$error"
        v-model="$v.claim.$model"
      />
      <b-row v-if="['D11', 'D20'].includes(claim)">
        <b-col>
          <b-form-group label="วันที่:" label-align="right" label-cols="3">
            <b-form-datepicker
              v-model="claimDate"
              :state="!$v.claimDate.$error"
              class="mb-2"
              :max="maxDate"
              hide-header
              locale="th"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-row>
        <b-form-group class="col">
          <b-form-select
            :class="{ 'is-invalid': $v.doctor.$error }"
            id="doctor"
            v-model="$v.doctor.$model"
            :options="getDoctorList"
            value-field="uid"
            text-field="fullName"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >เลือกแพทย์</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group class="col">
          <b-form-select
            :class="{ 'is-invalid': $v.roomId.$error }"
            id="resource"
            v-model="$v.roomId.$model"
            :options="getRoomList"
            value-field="id"
            text-field="title"
            number
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >เลือกห้อง</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!['D11', 'D20'].includes(claim)">
        <b-form-group class="col">
          <b-row class="px-3">
            <datetime
              class="col-10 p-0 pr-1"
              type="time"
              v-model="$v.dateTime.$model"
              input-class="form-control my-0"
              :input-style="dateTimeInputStyle"
              format="cccc dd LLLL yyyy / HH:mm"
              value-zone="UTC+7"
              :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
              :hour-step="1"
              :minute-step="duration"
              :week-start="7"
              :placeholder="'กรุณาเลือกเวลา'"
              auto
            ></datetime>
            <b-button class="col-2" @click="dateTime = roundedTime()"
              >ตอนนี้</b-button
            >
          </b-row>
          <template v-slot:description>
            <span v-if="$v.dateTime.$error" class="error"
              >กรุณาเลือกภายในเวลาทำการ ({{ getBranchInfo.confOpeningTime }}-{{
                getBranchInfo.confClosingTime
              }}) แก้ไขเวลาทำการได้ในเมนู "การตั้งค่า"</span
            >
          </template>
        </b-form-group>
      </b-form-row>
    </b-form>
    <template v-slot:modal-footer>
      <b-button type="submit" variant="primary" form="quickEventForm"
        >ยืนยัน</b-button
      >
    </template>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import CheckinRadio from "@/components/button/CheckinRadio";

import { eventBus } from "@/main";
import { mapActions, mapGetters } from "vuex";

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "QuickEvent",
  components: {
    Dialog,
    Loading,
    CheckinRadio,
  },
  data() {
    return {
      isLoading: false,
      patient: {},
      doctor: null,
      roomId: null,
      dateTime: null,
      appointment: null,
      mode: null,
      claim: null,
      claimDate: null,
      linkedWithKtbPaotang: false,
      linkedWithNhso: false,
    };
  },
  validations: {
    doctor: { required },
    roomId: { required },
    dateTime: {
      required,
      isBetweenTime(dateTime) {
        const dateTimeStr = moment(dateTime).format("HH:mm");
        const convertTime = str => moment(str, "HH:mm");
        return moment(convertTime(dateTimeStr)).isBetween(
          convertTime(this.getBranchInfo.confOpeningTime),
          convertTime(this.getBranchInfo.confClosingTime),
          "minutes",
          "[)"
        );
      },
    },
    claim: {
      // Confirm user in case of NHSO
      required: requiredIf(function () {
        return this.branchLinkedWithNhso;
      }),
    },
    claimDate: {
      required: requiredIf(function () {
        return ["D11", "D20"].includes(this.claim);
      }),
    },
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getRoomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      getDoctorList: "moduleUser/getDoctorList",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso",
      branchLinkedWithKtbPaotang: "moduleUser/getLinkedWithKtbPaotang",
    }),
    dateTimeInputStyle() {
      return this.$v.dateTime.$error
        ? "border-color: #dc3545; border-radius: 0.25rem 0 0 0.25rem"
        : "";
    },
    today() {
      return moment().startOf("day").format();
    },
    duration() {
      return moment.duration(this.getBranchInfo?.confApptDuration).asMinutes();
    },
    maxDate() {
      return new Date();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    show(patient, appointment, mode) {
      this.patient = patient;
      this.roomId = appointment?.roomId ?? null;
      this.doctor = appointment?.doctor ?? null;
      this.dateTime = appointment?.start ?? this.roundedTime();
      this.mode = mode;
      this.claimDate = new Date().toISOString().split("T")[0]; // Set default date to today

      this.$bvModal.show("QuickEventModal");
      this.$v.$reset();
    },

    saveEvent() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      switch (this.claim) {
        case "D10":
        case "D11":
        case "D20":
          this.linkedWithNhso = true;
          this.linkedWithKtbPaotang = false;
          break;
        // case "Paotang":
        //   this.linkedWithKtbPaotang = true;
        //   this.linkedWithNhso = false;
        //   break;
        default:
          this.linkedWithKtbPaotang = false;
          this.linkedWithNhso = false;
          this.claimDate = null;
          break;
      }
      if (this.mode === "overdue") {
        this.saveEventForOverdue();
        return;
      }
      const date = new Date(this.claimDate);

      let eventData = {
        // branchId: this.getBranchInfo.id,
        // id: null,
        startDt: this.dateTime,
        endDt: moment(this.dateTime).add(30, "minutes").format(),
        roomId: parseInt(this.roomId),
        aptTreatItems: [],
        doctorUid: this.doctor,
        patientId: this.patient.id,
        note: "-นัดหมายนี้ถูกสร้างอัตโนมัติ เพื่อบันทึกการรักษา-",
        linkedWithKtbPaotang: this.linkedWithKtbPaotang,
        linkedWithNhso: this.linkedWithNhso,
        nhsoSubitemNo: this.claim === "DentCloud" ? null : this.claim,
        nhsoServiceDate: ["DentCloud", "D10"].includes(this.claim)
          ? null
          : date.toISOString(), // Include claimDate in the request
      };
      this.isLoading = true;
      this.$store
        .dispatch("moduleAppointment/createQueueAppointment", {
          event: eventData,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(res => {
          if (res.status == "200") {
            this.$bvModal.hide("QuickEventModal");
            this.$bvModal.hide("ProfileCustomerModal");
            eventBus.$emit("openPaymentHistoryModal", res.data); //res.data คือ appointment
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveEventForOverdue() {
      let eventData = {
        // branchId: this.getBranchInfo.id,
        // id: null,
        startDt: this.dateTime,
        endDt: moment(this.dateTime).add(30, "minutes").format(),
        roomId: parseInt(this.roomId),
        aptTreatItems: [],
        doctorUid: this.doctor,
        patientId: this.patient.id,
        note: "-นัดหมายนี้ถูกสร้างอัตโนมัติ เพื่อชำระค้างจ่าย-",
        linkedWithKtbPaotang: this.linkedWithKtbPaotang,
        linkedWithNhso: this.linkedWithNhso,
      };
      this.isLoading = true;
      this.$store
        .dispatch("moduleAppointment/createQueueAppointment", {
          event: eventData,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(res => {
          if (res.status == "200") {
            this.$bvModal.hide("QuickEventModal");
            this.$bvModal.hide("ProfileCustomerModal");
            eventBus.$emit("openPaymentModal", res.data); //res.data คือ appointment
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //Utils
    roundedTime(time) {
      const mins = moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
      const rounded = Math.ceil(moment(time).minute() / mins) * mins;
      return moment(time).clone().minute(rounded).second(0).format();
    },
    defaultValue() {
      this.patient = {};
      this.doctor = null;
      this.roomId = null;
      this.dateTime = null;
      this.appointment = null;
      this.mode = null;
      this.claim = null;
      this.linkedWithKtbPaotang = false;
      this.linkedWithNhso = false;
      this.claimDate = null; // Reset to today's date
    },
  },
};
</script>

<style>
</style>