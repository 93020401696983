<template>
  <div class="component-drawing">
    <div v-show="loading" class="loading-overlay">
      <p>Adding Page, please wait...</p>
    </div>
    <div class="sticky-parent">
      <div id="tx-drawing-buttons" class="sticky-top mt-2 mr-2">
        <b-button-group class="mr-3" size="sm">
          <b-button
            class="px-auto mx-auto width-45px"
            variant="outline-primary"
            :pressed="!eraserMode"
            @click="eraserMode = false"
            @touchstart="eraserMode = false"
          >
            <i class="fas fa-pen"></i>
          </b-button>
          <b-button
            class="mx-auto width-45px"
            variant="outline-info"
            :pressed="eraserMode"
            @click="eraserMode = true"
            @touchstart="eraserMode = true"
          >
            <i class="fas fa-eraser"></i>
          </b-button>
        </b-button-group>

        <b-button-group class="mr-3" size="sm">
          <VSwatches v-model="color" popover-x="right">
            <b-button
              class="px-auto mx-auto width-45px rounded-left"
              slot="trigger"
              size="sm"
              :style="
                'background-color:' +
                color +
                ';cursor: pointer; height: 31px;border-radius: 0px'
              "
              @click="eraserMode = false"
              ><i class="fas fa-palette"></i
            ></b-button>
          </VSwatches>
          <b-button
            id="btn-thickness"
            class="width-45px"
            variant="light"
            size="sm"
            @click="eraserMode = false"
          >
            <hr
              class="m-auto border-0 rounded"
              :style="{
                height: thickness + 'px',
                backgroundColor: color,
              }"
            />
          </b-button>
        </b-button-group>
        <b-button-group class="mr-3" size="sm">
          <b-button
            variant="primary"
            :disabled="historyStep <= 0"
            @click="undoStroke"
          >
            <i class="fas fa-undo"></i>
          </b-button>
          <b-button
            variant="info"
            :disabled="historyStep === historyLines.length"
            @click="redoStroke"
          >
            <i class="fas fa-redo"></i>
          </b-button>
        </b-button-group>
        <b-button-group class="mr-3" size="sm">
          <b-button variant="warning" @click="loadLinesFromLocalStorage">
            <i class="fas fa-paste"></i>
          </b-button>
        </b-button-group>

        <b-button class="mr-2" size="sm" @click="clearLines" variant="danger">
          <i class="fas fa-trash-alt"></i>
        </b-button>
      </div>
    </div>
    <div class="overflow-hidden" v-for="(page, index) in pages" :key="index">
      <v-stage
        :id="'stage' + index"
        :ref="'stage' + index"
        :config="getconfigKonva(index)"
        @mousedown="mouseDrawing($event, index)"
        @touchstart="pencilDrawing($event, index)"
        @mouseup="stopDrawing($event, index)"
        @touchend="stopDrawing($event, index)"
        @mousemove="drawing($event, index)"
        @touchmove="drawing($event, index)"
      >
        <v-layer>
          <v-rect :config="page.configBackground"></v-rect>
          <v-text :config="page.configText"></v-text>
          <v-line
            v-for="(line, lineindex) in lines[index]"
            :key="`page-${index}-${lineindex}`"
            :config="line"
          ></v-line>
          <v-image
            :config="{
              x: page.configBackground.x + page.configBackground.width / 2 + 66,
              y: page.configBackground.y + page.configBackground.height - 40,
              width: 24,
              height: 24,
              image: pageIconImage, // Pass the preloaded image directly
              draggable: false, // Ensure it doesn't move when clicked
              listening: true, // Enable interactivity
              name: 'addPageIcon',
            }"
            ref="addPageIcon"
            @click="handleAddPageIconClick($event, index)"
            @mouseenter="onAddPageIconHover(true, index)"
            @mouseleave="onAddPageIconHover(false, index)"
            @touchstart="
              e => {
                handleAddPageIconClick(e, index);
                onAddPageIconHover(true, index);
              }
            "
            @touchend="onAddPageIconHover(false, index)"
          ></v-image>
          <!-- Text inside background -->
          <v-text
            :config="{
              x: page.configBackground.x + page.configBackground.width / 2 + 3, // Adjust as needed
              y: page.configBackground.y + page.configBackground.height - 30, // Adjust as needed
              text: 'Page ' + (index + 1),
              fontSize: 16,
              fill: 'gray',
              align: 'center',
              shadowColor: 'white',
              shadowBlur: 5,
              shadowOffsetX: 1,
              shadowOffsetY: 1,
            }"
          ></v-text>

          <v-image
            v-show="pages.length > 1"
            :config="{
              x: page.configBackground.x + page.configBackground.width / 2 - 30,
              y: page.configBackground.y + page.configBackground.height - 40,
              width: 22,
              height: 24,
              image: iconImage, // Pass the preloaded image directly
              draggable: false, // Ensure it doesn't move when clicked
              opacity: pages.length > 1 ? 1 : 0.2,
              listening: pages.length > 1,
              name: 'deleteIcon',
            }"
            ref="deleteIcon"
            @click="handleDeleteIconClick($event, index)"
            @mouseenter="onDeleteIconHover(true, index)"
            @mouseleave="onDeleteIconHover(false, index)"
            @touchstart="
              e => {
                handleDeleteIconClick(e, index);
                onDeleteIconHover(true, index);
              }
            "
            @touchend="onDeleteIconHover(false, index)"
          ></v-image>
        </v-layer>
      </v-stage>

      <br /><br />
    </div>

    <b-popover
      id="popoverThickness"
      class="p-0"
      target="btn-thickness"
      placement="bottom"
      triggers="click blur"
    >
      <b-button-group>
        <b-button
          variant="light"
          :pressed="thickness == 0.6"
          @click="(thickness = 0.6), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '1px',
              width: '1px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 1.2"
          @click="(thickness = 1.2), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '2px',
              width: '2px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 2"
          @click="(thickness = 2), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '3px',
              width: '3px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 4"
          @click="(thickness = 4), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '4px',
              width: '4px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 9"
          @click="(thickness = 9), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '9px',
              width: '9px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 14"
          @click="(thickness = 14), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '14px',
              width: '14px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 20"
          @click="(thickness = 20), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '20px',
              width: '20px',
            }"
          ></span
        ></b-button>
      </b-button-group>
    </b-popover>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import noteTemplate from "@/assets/img/noteTemplate.jpeg";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { eventBus } from "@/main";
import Dialog from "@/components/modal/Dialog";

import "@/theme/modal/PaymentHistory/Drawing.scss";

export default {
  name: "Drawing",
  components: {
    VSwatches,
    Dialog,
  },
  props: ["patient", "drawNote", "summaryTableBusy"],
  data() {
    return {
      configKonva: {
        width: 720,
        height: 960,
      },
      configBackground: {
        x: 0,
        y: 0,
        width: 720,
        height: 960,
        fillPatternImage: null,
        preventDefault: false,
      },
      configText: {
        x: 15,
        y: 10,
        text: this.drawNote?.text || "ไม่มึข้อมูลบันทึก",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      },
      pages: [],
      lines: [],
      numPages: [],
      lastSavedDrawNote: { text: {}, lines: [] },

      lineWidth: 0,
      isTouch: false,
      points: [],
      lastline: {},

      eraserMode: false,

      thickness: 2,
      color: "#2980B9",

      //history
      historyStep: 0,
      historyLines: [],
      linesEdited: false,

      //Konva objects
      stage: null,

      isPen: false,
      loading: false,
      drawNoteInitialized: false,
      iconImage: null,
      pageIconImage: null,
    };
  },
  created() {
    // Preload the image inline when the component is created
    const trashImg = new Image();
    trashImg.src = require("@/assets/icon/trash-solid.png"); // Use the correct path
    this.iconImage = trashImg; // Assign the image directly to the data property

    // Preload the add-page icon
    const addPageImg = new Image();
    addPageImg.src = require("@/assets/icon/file-circle-plus.png");
    this.pageIconImage = addPageImg;
  },
  watch: {
    drawNote: {
      handler() {
        this.$nextTick(() => {
          if (this.drawNote) {
            console.log("Updated DrawNote in next tick:");
          } else {
            console.warn("Updated drawNote is empty or undefined");
          }
        });
      },
      deep: true, // Use deep watching for nested objects/arrays
    },
  },
  computed: {
    ...mapGetters({
      getUserInfo: "moduleUser/getUserInfo",
    }),
    newDrawNote() {
      return {
        text: this.configText.text || "",
        lines: this.lines || [],
      };
    },
  },
  methods: {
    loadIcon() {
      const img = new Image();
      img.onload = () => {
        console.log("Image loaded:", img);
        this.iconImage = img; // After image is loaded, set it as the pattern for the rectangle
      };
      img.onerror = () => {
        console.error("Failed to load icon image.");
      };
      img.src = require("@/assets/icon/trash-solid.png"); // Correct path to your image icon
      console.log("Transh icon loaded");
    },
    //drawing
    mouseDrawing(e, pageIndex) {
      const target = e.target;
      console.log("Event target:", target);

      // Skip logic if clicking on the delete icon
      if (
        (target.name && target.name() === "deleteIcon") ||
        target.name() === "addPageIcon"
      ) {
        console.log(
          "Clicked delete icon or addPageIcon, skipping drawing and erasing"
        );
        return;
      }

      if (this.eraserMode) {
        console.log("Eraser mode is active");
        this.startObjEraser(e, pageIndex);
      } else {
        console.log("Drawing mode is active");
        this.startDrawing(e, pageIndex);
      }
    },

    getconfigKonva(index) {
      return {
        width: this.configKonva.width,
        height: this.configKonva.height,
      };
    },
    pencilDrawing(e, pageIndex) {
      if (!this.isPen) return;
      e.evt.preventDefault();
      if (this.eraserMode) {
        this.startObjEraser(e, pageIndex);
      } else {
        this.startDrawing(e, pageIndex);
      }
    },
    startDrawing(e, pageIndex) {
      // Ensure we are not in eraser mode
      if (!this.eraserMode) {
        // Get the stage from the event target
        const stage = e.target.getStage();

        // Check if the stage is available
        if (stage) {
          const pos = stage.getPointerPosition(); // Get the current pointer position
          // Check if pointer position is valid
          if (pos) {
            this.isTouch = true; // Set touch flag
            console.log("inside of pos");
            this.lastLine = {
              lineCap: "round",
              lineJoin: "round",
              stroke: this.color,
              strokeWidth: this.thickness,
              globalCompositeOperation: "source-over",
              points: [pos.x, pos.y],
              tension: 0.5,
            };

            if (!this.lines[pageIndex]) {
              this.$set(this.lines, pageIndex, []);
            }
            // Use push instead of concat for better performance and readability
            this.lines[pageIndex].push(this.lastLine);

            // Optionally, you can call batchDraw here if you are modifying the stage directly
            stage.batchDraw();
          } else {
            console.warn("Pointer position is null.");
          }
        } else {
          console.warn("Stage is not available. Cannot start drawing.");
        }
      }
    },

    stopDrawing(e, pageIndex) {
      console.log("Event type:", e.type);
      if (!this.isTouch) return;

      if (this.eraserMode) {
        this.lines[pageIndex].pop();
        if (this.linesEdited) {
          this.addHistory();
          this.linesEdited = false;
        }
      } else {
        // Increased threshold from 1 to 2 for more aggressive point reduction
        let points = this.chunkArrayInGroups(this.lastLine.points, 2);
        let simplifiedPoints = this.simplifyLineRDP(points, 1); // Increased from 1 to 2

        // Round coordinates to reduce decimal places
        simplifiedPoints = simplifiedPoints.map(point => [
          Math.round(point[0]),
          Math.round(point[1]),
        ]);

        this.lastLine.points = [].concat.apply([], simplifiedPoints);
        this.addHistory();
      }
      this.isTouch = false;
      this.$emit("update:drawNote", this.newDrawNote);
      console.log("drawNote from stop drawing", this.newDrawNote);
    },

    drawing(e, pageIndex) {
      if (!this.isTouch) return;
      console.log("inside of drawing");
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();
      console.log("Pos in drawing", pos);

      if (!pos) {
        console.error("Pointer position not available for stage:", stage);
        return;
      }

      // Update points in the current line
      const newPoints = this.lastLine.points.concat([pos.x, pos.y]);
      this.lastLine.points = newPoints;
      this.$set(
        this.lines[pageIndex],
        this.lines[pageIndex].length - 1,
        this.lastLine
      );

      console.log("drawing", this.lastLine.points);
      if (this.eraserMode) {
        console.log("Eraser mode is active - skipping drawing");
        this.objErasing(pageIndex);
        return;
      }
    },

    //undo
    undoStroke() {
      if (this.historyStep <= 0) return;
      this.historyStep -= 1;
      this.lines = this.historyLines[this.historyStep - 1] || [];
    },
    redoStroke() {
      if (this.historyStep === this.historyLines.length) return;

      this.historyStep += 1;
      this.lines = this.historyLines[this.historyStep - 1] || [];
    },
    addHistory() {
      this.historyLines = this.historyLines.slice(0, this.historyStep);
      // Deep clone the nested array
      this.historyLines.push(JSON.parse(JSON.stringify(this.lines)));
      this.historyStep += 1;
    },

    //eraser

    startObjEraser(e, pageIndex) {
      console.log("Inside startObjEraser...");
      // safely check to ensure the page has lines
      if (!this.lines[pageIndex] || !this.lastLine) return;
      console.log("this.eraserMode", this.eraserMode);
      if (this.eraserMode) {
        this.isTouch = true;
        const stage = e.target.getStage();
        const pos = stage.getPointerPosition();
        console.log("Pos in start object eraser", pos);

        if (!pos) {
          console.error("Pointer position not available for stage:", stage);
        }

        this.lastLine = {
          lineCap: "round",
          lineJoin: "round",
          stroke: "gainsboro",
          strokeWidth: 5,
          bezier: true,
          globalCompositeOperation: "source-over",
          points: [pos.x, pos.y],
        };

        console.log("Before adding on current page:", this.lines);
        this.$set(
          this.lines,
          pageIndex,
          this.lines[pageIndex].concat([this.lastLine])
        );
        console.log("Lines on current page:", this.lines);

        // this.$set(this.lines, pageIndex, this.lines[pageIndex].concat([this.lastLine]));
      }
    },

    // getStageForPage(pageIndex) {
    //   console.log("PageIndex", pageIndex)
    //   const stageRefKey =  `stage${pageIndex}`
    //   const stageRef = this.$refs[stageRefKey]?.[0]
    //   console.log("stageRef from getStageForPage new", stageRef)
    //   if (!stageRef) {
    //     console.error("Stage reference not found for pageIndex:", pageIndex);
    //     return null;
    //   }
    //   return stageRef.getNode(); // Use the Konva `getStage()` method to access the Stage instance
    // },

    objErasing(pageIndex) {
      console.log("objErasing called for pageIndex:", pageIndex);
      if (!this.lines[pageIndex] || !this.lastLine) return;

      let length = 50;
      this.lastLine.points.splice(
        -length - 2,
        this.lastLine.points.length - length
      );
      let eraserPoints = this.lastLine.points.slice(-4);
      console.log("Eraser points:", eraserPoints);
      // let linesErased = false; // Track if any line was erased
      this.lines[pageIndex].forEach((line, index) => {
        if (this.lines[pageIndex].length - 1 === index) return;
        let points = [...line.points];
        while (points.length >= 4) {
          let linePoints = points.slice(0, 4);
          let int = this.intersect(...eraserPoints, ...linePoints);
          console.log(
            "Checking intersection:",
            eraserPoints,
            linePoints,
            "Result:",
            int
          );
          if (int) {
            this.lines[pageIndex].splice(index, 1);
            this.linesEdited = true;
            // linesErased = true;

            break;
          }
          // Move to the next segment of the line
          points.splice(0, 2);
        }
      });

      // Check if all lines on the page are erased
      if (this.lines[pageIndex].length === 0) {
        // Remove the empty lines entry
        this.lines.splice(pageIndex, 1);

        // Remove the corresponding page
        if (this.pages && this.pages.length > pageIndex) {
          this.pages.splice(pageIndex, 1);
        }
      }
    },

    intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
      if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
      }
      let denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
      if (Math.abs(denominator) < 1e-10) {
        return false;
      }
      let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
      let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
      if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
      }
      let x = x1 + ua * (x2 - x1);
      let y = y1 + ua * (y2 - y1);
      return { x, y };
    },
    chunkArrayInGroups(arr, size) {
      var result = [];
      for (var i = 0; i < arr.length; i += size)
        result.push(arr.slice(i, i + size));
      return result;
    },
    simplifyLineRDP(points, length) {
      var simplify = function (start, end) {
        // recursize simplifies points from start to end
        var maxDist, index, xx, yy, dx, dy, ddx, ddy, p1, p2, p, t, dist, dist1;
        p1 = points[start];
        p2 = points[end];
        xx = p1[0];
        yy = p1[1];
        ddx = p2[0] - xx;
        ddy = p2[1] - yy;
        dist1 = ddx * ddx + ddy * ddy;
        maxDist = length;
        for (var i = start + 1; i < end; i++) {
          p = points[i];
          if (ddx !== 0 || ddy !== 0) {
            t = ((p[0] - xx) * ddx + (p[1] - yy) * ddy) / dist1;
            if (t > 1) {
              dx = p[0] - p2[0];
              dy = p[1] - p2[1];
            } else if (t > 0) {
              dx = p[0] - (xx + ddx * t);
              dy = p[1] - (yy + ddy * t);
            } else {
              dx = p[0] - xx;
              dy = p[1] - yy;
            }
          } else {
            dx = p[0] - xx;
            dy = p[1] - yy;
          }
          dist = dx * dx + dy * dy;
          if (dist > maxDist) {
            index = i;
            maxDist = dist;
          }
        }

        if (maxDist > length) {
          // continue simplification while maxDist > length
          if (index - start > 1) {
            simplify(start, index);
          }
          newLine.push(points[index]);
          if (end - index > 1) {
            simplify(index, end);
          }
        }
      };
      var end = points.length - 1;
      var newLine = [points[0]];
      simplify(0, end);
      newLine.push(points[end]);
      return newLine;
    },

    //clear
    clearLines() {
      this.$refs.Dialog.showAlertConfirm(
        'ลบการเขียนทั้งหมด"',
        "คุณต้องการลบข้อมูลการเขียนทั้งหมด?"
      ).then(res => {
        if (res.isConfirmed) {
          this.lines = [];
          this.addHistory();
        }
      });
    },

    async insertText(e) {
      if (this.drawNote.text) return;
      let patientFirstName;
      let patientLastName;
      patientFirstName = this.patient?.firstNameTh || "";
      patientLastName = this.patient?.lastNameTh || "";

      let userName =
        this.getUserInfo.firstNameTh + " " + this.getUserInfo.lastNameTh;
      let dateTimeRecord = moment().locale("th").format("D MMM YY H:mm");
      this.configText.text =
        dateTimeRecord +
        "\nชื่อคนไข้: " +
        patientFirstName +
        " " +
        patientLastName +
        "\nผู้บันทึก: " +
        userName;
      // Get the stage from the event or a fallback (e.g., a ref)
      const stage = e?.target?.getStage() || this.$refs.drawingCard?.getStage();
      if (stage) {
        stage.batchDraw();
      } else {
        console.warn("Stage is not available for drawing.");
      }
      eventBus.$emit("drawNoteUpdated", this.drawNote);
      // this.initializePages()
      // const stage = e.target.getStage();
      // stage.batchDraw();
    },

    defaultValue() {
      this.lines = [];
      this.configText = {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      };
      this.historyStep = 0;
      this.historyLines = [];
      this.lastSavedDrawNote = {};
      this.eraserMode = false;
    },

    hidePopover() {
      this.$root.$emit("bv::hide::popover", "popoverThickness");
    },

    retrieveDrawNote() {
      eventBus.$emit("returnDrawNote", this.drawNote);
      this.defaultValue();
    },
    assignDrawNote(e) {
      if (e?.lines.length > 0) {
        this.configText.text = e.text;
        this.lines = [...e.lines];
      }
      // this.stage.batchDraw();
    },
    saveLinesToLocalStorage() {
      if (this.lines?.length === 0) return;
      localStorage.setItem("drawingLines", JSON.stringify(this.lines));
    },
    async addNewPage() {
      if (this.pages.length >= 5) {
        this.$bvToast.toast("Maximum of 5 pages reached.", {
          title: "Page Limit Reached",
          variant: "warning",
          solid: true,
          autoHideDelay: 3000,
          toaster: "b-toaster-bottom-left",
        });
        return; // Exit the method if the page limit is reached
      }
      this.loading = true;
      const newPage = {
        configBackground: {
          x: 0,
          y: 0,
          width: 720,
          height: 960,
          fillPatternImage: null,
          preventDefault: false,
        },
        // configText: { text: "" },
        configText: {
          x: 15,
          y: 10,
          text: this.configText.text || "", // Use drawNote to set text
          fontSize: 14,
          lineHeight: 1.5,
          fill: "#3D556E",
        },
        lines: [],
      };

      //Add the new page to the pages array
      this.pages.push(newPage);
      console.log("New page added");

      try {
        const newPageIndex = this.pages.length - 1;
        await this.$nextTick(); // Ensure the page is rendered

        await this.loadBackgroundTemplate(newPageIndex); // Wait for the background to load
        console.log(`Background loaded for page ${newPageIndex}`);
      } catch (error) {
        console.error(error.message);
      } finally {
        this.loading = false; // Stop loading regardless of success or error
      }

      // Save the updated pages array to localStorage

      // this.saveLines()
    },
    deletePage() {
      if (this.pages.length <= 1) {
        // Show a toast message informing the user that they can't delete the last page
        this.$bvToast.toast("You cannot delete the last page.", {
          title: "Delete Page",
          variant: "warning",
          solid: true,
          autoHideDelay: 3000,
        });
        return; // Exit the function if there is only one page left
      }
      // Confirm deletion with a toast message
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete last page?", {
          title: "Delete Page",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          centered: true,
        })
        .then(confirmed => {
          if (confirmed) {
            // if (this.drawNote && Array.isArray(this.drawNote.lines) && this.drawNote.lines.length > 1 )  {
            //   this.drawNote.lines.pop();
            // }
            if (this.drawNote && this.drawNote.lines) {
              // First, check if it's null, and set it to an empty array
              if (this.drawNote.lines === null) {
                this.drawNote.lines = []; // Set to empty array if null
              }

              // Now check if it's an array and has elements
              if (
                Array.isArray(this.drawNote.lines) &&
                this.drawNote.lines.length > 0
              ) {
                this.drawNote.lines.pop(); // Pop the last line if not empty
              }
            }
            // Remove the page from pages array
            this.pages.pop();

            // Optional: save updated pages to local storage
            this.deleteSavePages();
            // this.deleteSaveLines(this.pages.length)
            // localStorage.setItem("savedPages", JSON.stringify(this.pages))

            // Show success toast notification
            this.$bvToast.toast(
              `Page ${this.pages.length + 1} deleted successfully.`,
              {
                title: "Page Deleted",
                variant: "info",
                solid: true,
                autoHideDelay: 3000,
                toaster: "b-toaster-bottom-left",
              }
            );
          }
        })
        .catch(err => {
          console.error("Page deletion canceled or failed:", err);
        });
    },

    handleDeleteIconClick(event, index) {
      event.evt.stopPropagation();
      event.evt.preventDefault();
      // Prevent event from triggering the drawing logic
      this.deletePageNumber(index);
    },

    handleAddPageIconClick(event) {
      console.log(event);
      event.evt.stopPropagation();
      event.evt.preventDefault();
      this.addNewPage();
    },

    deletePageNumber(index) {
      if (this.pages.length <= 1) {
        // Show a toast message informing the user that they can't delete the last page
        this.$bvToast.toast("You cannot delete the last page.", {
          title: "Delete Page",
          variant: "warning",
          solid: true,
          autoHideDelay: 3000,
        });
        return; // Exit the function if there is only one page left
      }
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete Page ${index + 1}?`, {
          title: "Delete Page",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          centered: true,
        })
        .then(confirmed => {
          if (confirmed) {
            if (this.drawNote && this.drawNote.lines) {
              if (this.drawNote.lines === null) {
                this.drawNote.lines = [];
              }

              if (Array.isArray(this.drawNote.lines[index])) {
                this.drawNote.lines.splice(index, 1);
              }
            }

            //Remove page from the pages array
            if (Array.isArray(this.pages) && index < this.pages.length) {
              this.pages.splice(index, 1);
            }

            //show success toast notification
            this.$bvToast.toast(`Page ${index + 1} deleted successfuly`, {
              title: "Page Deleted",
              variant: "info",
              solid: true,
              autoHideDelay: 3000,
              toaster: "b-toaster-bottom-left",
            });
          }
        })
        .catch(err => {
          console.error("Page deletion canceled or failed:", err);
        });
    },

    onDeleteIconHover(isHovering, index) {
      const icon = this.$refs[`deleteIcon`][index].getNode();
      console.log("inside onDeleteIconHover", this.$refs.deleteIcon[index]);
      if (icon) {
        if (isHovering) {
          this.isPen = false;
          // Apply hover effect
          icon.to({
            scaleX: 1.2,
            scaleY: 1.2,
            shadowColor: "black",
            shadowBlur: 10,
            duration: 0.2, // Smooth transition
          });
        } else {
          this.isPen = true;
          icon.to({
            scaleX: 1,
            scaleY: 1,
            shadowColor: "",
            shadowBlur: 0,
            duration: 0.2,
          });
        }
      }
    },

    onAddPageIconHover(isHovering, index) {
      const icon = this.$refs[`addPageIcon`][index]?.getNode();
      console.log("inside onAddPageIconHover", this.$refs.addPageIcon[index]);
      if (icon) {
        if (isHovering) {
          this.isPen = false;
          // Apply hover effect
          icon.to({
            scaleX: 1.2,
            scaleY: 1.2,
            shadowColor: "black",
            shadowBlur: 10,
            duration: 0.2, // Smooth transition
          });
        } else {
          this.isPen = true;
          icon.to({
            scaleX: 1,
            scaleY: 1,
            shadowColor: "",
            shadowBlur: 0,
            duration: 0.2,
          });
        }
      }
    },

    saveLines() {
      this.pages.forEach((page, index) => {
        const pageLines = this.lines[index]; // Get the lines for the current page
        if (pageLines && pageLines.length > 0) {
          // Save lines for the current page in localStorage
          localStorage.setItem(
            `savedLines-${index}`,
            JSON.stringify(pageLines)
          );
        }
      });
    },

    deleteSaveLines(lastPageIndex) {
      // Remove saved lines for the last page from localStorage
      localStorage.removeItem(`savedLines-${lastPageIndex}`);
    },

    savePages() {
      // Retrieve the current count from localStorage, defaulting to 0 if it doesn't exist
      let pageCount = parseInt(localStorage.getItem("savedPageCount"), 10) || 0;

      // Increment the page count by 1
      pageCount += 1;

      // Save the updated count to localStorage
      localStorage.setItem("savedPageCount", pageCount);
      console.log("Updated saved page count:", pageCount);
    },

    deleteSavePages() {
      let pageCount = parseInt(localStorage.getItem("savedPageCount"), 10) || 0;

      pageCount = Math.max(0, pageCount - 1);

      localStorage.setItem("savedPageCount", pageCount);
      console.log("Update save page count after delete", pageCount);
    },

    loadBackgroundTemplate(index) {
      return new Promise((resolve, reject) => {
        const stageRefKey = `stage${index}`;
        const stageRef = this.$refs[stageRefKey]?.[0];

        if (stageRef) {
          // Set stage dimensions
          // stage.height(960);
          // stage.width(720);
          const stage = stageRef.getNode();

          // Load and assign the noteTemplate image as the background
          const image = new window.Image();
          image.src = noteTemplate;
          image.onload = () => {
            console.log("Image loaded:", image);
            this.pages[index].configBackground.fillPatternImage = image;
            stage.draw();
            console.log(
              "Config Background:",
              this.pages[index].configBackground
            );
            resolve(); // Resolve the promise when the image is loaded successfully
            console.log("background image success");
          };
          image.onerror = err => {
            console.error("Failed to load image:", err);
            reject(new Error(`Failed to load image for stage ${stageRefKey}`)); // Reject if there’s an error
          };
        } else {
          reject(
            new Error(
              `Stage reference ${stageRefKey} not found in loadBackgroundTemplate`
            )
          ); // Reject if stage is not found
        }
      });
    },

    async loadLinesFromLocalStorage() {
      let lines = (await localStorage.getItem("drawingLines")) || "";
      if (lines) {
        let res = await this.$refs.Dialog.showAlertConfirm(
          "Paste การเขียนล่าสุด",
          "ใช้ในกรณีระบบมีปัญหา เขียนไปแล้ว แต่ไม่สามารถบันทึกลงประวัติคนไข้ ระบบจะดึงการเขียนล่าสุดกลับมา"
        );
        if (res.isConfirmed) this.lines = JSON.parse(lines);
      } else {
        this.$refs.Dialog.showAlertInfo("ไม่มีข้อมูลกรเขียนที่ถูกบันทึกไว้");
      }
    },

    async initializePages() {
      this.pages = [];
      const savedPagesCount =
        this.drawNote.lines && this.drawNote.lines.length > 0
          ? this.drawNote.lines.length
          : 0;

      if (savedPagesCount === 0) {
        // If no saved pages, initialize with one default page
        // this.pages = [{ configBackground: { fillPatternImage: null }, configText: { text: "" }, lines: [] }];
        this.pages.push({
          configBackground: {
            x: 0,
            y: 0,
            width: 720,
            height: 960,
            fillPatternImage: null,
            preventDefault: false,
          },
          // configText: { text: "" },
          configText: {
            x: 15,
            y: 10,
            text: this.configText.text || "", // Use drawNote to set text
            fontSize: 14,
            lineHeight: 1.5,
            fill: "#3D556E",
          },
          lines: [],
        });
        this.lines.push([]);
        console.log("IN !save in Drawing.vue");
        console.log("this.drawNote from Drawing.vue", this.lines);

        const numPages = 1;
        this.$nextTick(() => {
          for (let i = 0; i < numPages; i++) {
            const stageRefKey = `stage${i}`;
            const stageRef = this.$refs[stageRefKey]?.[0]; // Safely access the stage reference

            if (stageRef) {
              const stage = stageRef.getNode();

              const image = new window.Image();
              image.src = noteTemplate; // Make sure this path is correct
              console.log("Image URL:", noteTemplate);

              image.onload = () => {
                // Set the background image for each page
                this.pages[i].configBackground.fillPatternImage = image;

                // Redraw the stage to show the background
                stage.draw();

                // this.saveLines()
              };

              image.onerror = () => {
                console.error("Failed to load image:", image.src);
              };
              this.loadIcon();
            } else {
              console.warn(
                `Stage reference ${stageRefKey} not found in save initializepage`
              );
            }
          }
        });
      } else {
        // Load saved pages
        console.log("IN else in Drawing.vue");
        console.log("this.drawNote from Drawing.vue", this.lines);

        this.pages = Array.from({ length: savedPagesCount }, (_, i) => ({
          configBackground: {
            x: 0,
            y: 0,
            width: 720,
            height: 960,
            fillPatternImage: null,
            preventDefault: false,
          },
          // configText: { text: "" },
          configText: {
            x: 15,
            y: 10,
            text: this.drawNote?.text || "", // Use drawNote to set text
            fontSize: 14,
            lineHeight: 1.5,
            fill: "#3D556E",
          },
          lines: [],
        }));
        // this.lines.push([]);

        const numPages = savedPagesCount;
        console.log(this.$refs);

        this.$nextTick(() => {
          for (let i = 0; i < numPages; i++) {
            const stageRefKey = `stage${i}`;
            console.log("StageRefkey", stageRefKey);
            const stageRef = this.$refs[stageRefKey]?.[0]; // Safely access the stage reference

            if (stageRef) {
              const stage = stageRef.getNode();

              const image = new window.Image();
              image.src = noteTemplate; // Make sure this path is correct
              // console.log("Image URL:", noteTemplate);

              image.onload = () => {
                // Set the background image for each page
                this.pages[i].configBackground.fillPatternImage = image;

                // Redraw the stage to show the background
                stage.draw();
                this.loadIcon();
              };

              image.onerror = () => {
                console.error("Failed to load image:", image.src);
              };
            } else {
              console.warn(
                `Stage reference ${stageRefKey} not found in else initializePages`
              );
            }
          }
        });
      }
    },
  },
  async mounted() {
    window.addEventListener("pointerdown", e => {
      this.isPen = e.pointerType === "pen";
    });
    eventBus.$on("setDefaultValuePaymentHistory", () => {
      this.configText.text = "";
      this.lines = [];
    });

    eventBus.$on("updateDrawNote", assignedDrawNote => {
      console.log(
        "Received updated drawNote:",
        JSON.stringify(assignedDrawNote)
      );
      this.assignDrawNote(assignedDrawNote);
    });

    eventBus.$on("drawNoteUpdated", updatedDrawNote => {
      // console.log("Received updated drawNote:", JSON.stringify(updatedDrawNote));

      this.drawNoteInitialized = true;
      // Now you can safely access updated drawNote
      if (updatedDrawNote && updatedDrawNote.lines.length > 0) {
        console.log("DrawNote Title:", updatedDrawNote.text); // Accessing text property
        this.$nextTick(async () => {
          await this.initializePages();
        });
      } else {
        this.initializePages();

        console.warn("Updated drawNote is empty or undefined");
      }
    });
    // console.log("From drawing mounted")

    this.$nextTick(() => {
      if (!this.drawNoteInitialized) {
        console.warn(
          "EventBus did not trigger; initializing default drawNote."
        );

        // Initialize with empty lines if no update has happened

        this.$nextTick(async () => {
          await this.initializePages();
        });
      }
    });
  },

  beforeDestroy() {
    window.removeEventListener("pointerdown", () => {});

    // ลด memory leak ของ canvas ใน ios safari
    // this.stage.height(1);
    // this.stage.width(1);
    // this.stage = null;
    // this.stage.destroy();
    Object.keys(this.$refs).forEach(refKey => {
      if (refKey.startsWith("stage")) {
        const vueStage = this.$refs[refKey][0]; // Get the Vue Konva wrapper component
        if (vueStage && vueStage.getStage) {
          const stage = vueStage.getStage(); // Get the Konva Stage instance
          if (stage) {
            stage.height(1); // Minimize canvas size
            stage.width(1);
            stage.destroy(); // Destroy the stage
            eventBus.$off("drawNoteUpdated");
            eventBus.$off("updateDrawNote");
          }
        }
      }
    });
  },
};
</script>

<style>
.spanDot {
  background-color: #7e7e7e;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  align-self: center;
}
#popoverThickness .popover-body {
  padding: 0px;
}
.sticky-parent {
  position: absolute;
  height: 100vmax;
  right: 0px;
}
.overflow-hidden {
  overflow: hidden;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #333;
  z-index: 1000;
}
.page-number {
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
}
.delete-btn {
  position: absolute;
  z-index: 10;
  bottom: 50px; /* Adjust based on the page number's y-coordinate */
  left: calc(50% + 60px); /* Aligns to the right of the page number */
  transform: translateX(-50%);
}
</style>