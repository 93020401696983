# templates/MarketingConsentForm.vue
<template>
  <div>
    <div ref="body">
      <p style="text-indent: 50px; line-height: 2">
        ข้าพเจ้า
        <template v-if="documentData.patient && documentData.patient.title">
          ท{{ documentData.patient.title }}
        </template>
        {{ documentData.ptFullname }}
        เลขประจำตัวประชาชน
        {{
          (documentData.patient && documentData.patient.idNumber) ||
          "______________________________"
        }}
        <template v-if="documentData.patient && documentData.patient.address">
          ที่อยู่ {{ documentData.patient.address }}
        </template>
      </p>

      <!-- รายละเอียดการยินยอม -->
      <div class="consent-details mb-4">
        <h5 class="font-weight-bold mb-3">
          การให้ความยินยอมในการใช้ข้อมูลเพื่อการตลาด
        </h5>

        <div class="purpose mb-4">
          <h6 class="font-weight-bold">1. วัตถุประสงค์ของการขอความยินยอม</h6>
          <p style="text-indent: 50px; line-height: 2">
            เพื่อให้คลินิกสามารถใช้ข้อมูล ภาพถ่าย และประสบการณ์การรักษาของท่าน
            ในการประชาสัมพันธ์และการตลาดของคลินิก
            เพื่อให้ผู้ที่สนใจได้รับข้อมูลที่เป็นประโยชน์ในการตัดสินใจรักษา
          </p>
        </div>

        <div class="scope mb-4">
          <h6 class="font-weight-bold">2. ขอบเขตการใช้ข้อมูล</h6>
          <ul class="scope-list">
            <li>ภาพถ่ายก่อนและหลังการรักษา</li>
            <li>ข้อมูลเกี่ยวกับการรักษาและผลลัพธ์</li>
            <li>ความคิดเห็นและประสบการณ์การรักษา</li>
            <li>ข้อมูลทั่วไปที่ไม่ระบุตัวตน</li>
          </ul>
        </div>

        <div class="usage mb-4">
          <h6 class="font-weight-bold">3. การใช้งานข้อมูล</h6>
          <ul class="usage-list">
            <li>สื่อโซเชียลมีเดียของคลินิก</li>
            <li>เว็บไซต์ของคลินิก</li>
            <li>สื่อประชาสัมพันธ์ภายในคลินิก</li>
            <li>การนำเสนอในงานสัมมนาหรือการศึกษา</li>
          </ul>
        </div>

        <div class="rights mb-4">
          <h6 class="font-weight-bold">4. สิทธิของผู้ให้ความยินยอม</h6>
          <ul class="rights-list">
            <li>สามารถขอดูข้อมูลที่มีการใช้งานได้</li>
            <li>สามารถขอแก้ไขหรือลบข้อมูลได้</li>
            <li>สามารถยกเลิกความยินยอมได้ทุกเมื่อ</li>
          </ul>
        </div>

        <!-- การรักษาความปลอดภัยข้อมูล -->
        <div class="security mb-4 page-break-before">
          <h6 class="font-weight-bold">5. การรักษาความปลอดภัยข้อมูล</h6>
          <p style="text-indent: 50px; line-height: 2">
            คลินิกจะเก็บรักษาข้อมูลของท่านอย่างปลอดภัย
            และจะไม่เปิดเผยข้อมูลส่วนตัวที่ระบุตัวตนได้
            เว้นแต่จะได้รับความยินยอมจากท่านเป็นกรณีพิเศษ
          </p>
        </div>

        <div v-if="documentData.commentDetail">
          <p class="font-weight-bold">หมายเหตุ :</p>
          <p class="my-justify" style="text-indent: 50px; line-height: 2">
            {{ documentData.commentDetail }}
          </p>
        </div>

        <!-- การให้ความยินยอม -->
        <div class="consent-section mt-4">
          <div class="ml-4">
            <div class="consent-checkbox mb-2">
              <input
                type="checkbox"
                true-value="consent"
                false-value="no_consent"
                class="mr-2"
              />
              <label
                >ข้าพเจ้ายินยอมให้ใช้ข้อมูลตามวัตถุประสงค์ที่ระบุข้างต้น</label
              >
            </div>
            <div class="consent-checkbox">
              <input type="checkbox" class="mr-2" />
              <label>ข้าพเจ้าไม่ยินยอม</label>
            </div>
          </div>
        </div>

        <!-- ลายเซ็น -->
        <div class="signature-section mt-8">
          <b-row class="mb-5">
            <b-col cols="5" class="text-center"> </b-col>

            <b-col cols="5" offset="2" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ผู้ให้ความยินยอม</div>
              </div>
              <div class="mt-2">({{ documentData.ptFullname }})</div>
              <div class="mt-2">
                วันที่: {{ documentData.consentDate || ".................." }}
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "MarketingConsentForm",
  props: {
    documentData: {
      type: Object,
      required: true,
      default: () => ({
        clinicName: "",
        clinicAddress: "",
        clinicPhone: "",
        ptFullname: "",
        patient: {
          idNumber: "",
          dob: null,
          address: "",
          phone: "",
          dn: "",
        },
        title: "",
        doctorObj: {
          fullName: "",
        },
        marketingConsent: "no_consent",
        consentDate: null,
        commentDetail: "",
      }),
    },
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
};
</script>

<style scoped>
.scope-list,
.usage-list,
.rights-list {
  list-style-type: disc;
  padding-left: 2rem;
  line-height: 2;
}

.scope-list li,
.usage-list li,
.rights-list li {
  margin-bottom: 0.5rem;
}

.signature-line {
  margin-bottom: 0.5rem;
}

.font-weight-bold {
  font-weight: bold;
}

.signature-section {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.consent-checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.consent-checkbox input[type="checkbox"] {
  margin-top: 0.25rem;
}

@media print {
  .signature-section {
    page-break-inside: avoid;
  }
  .page-break {
    page-break-after: always;
  }
}
</style>