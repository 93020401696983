<template>
  <b-modal
    id="uploadFiles"
    ref="uploadFiles"
    size="xl"
    title="Upload ไฟล์"
    centered
    ok-title="บันทึก"
    cancel-title="ยกเลิก"
    @ok="saveFiles"
    @hidden="setDefaultValue"
  >
    <b-form-group
      label="Files:"
      label-cols-sm="2"
      label-align="right"
      invalid-feedback="กรุณาเลือกไฟล์"
      :state="!$v.files.$error"
    >
      <b-form-file
        multiple
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png, application/pdf"
        @input="handleFilesInput"
      ></b-form-file>
      <!-- accept="image/jpeg
       ,image/png
       ,video/quicktime 
       ,video/x-m4v 
       ,video/x-msvideo
       ,video/webm 
       ,video/mp4
       ,application/pdf" -->
    </b-form-group>
    <b-row v-if="this.files.length > 0" class="px-2 mb-3">
      <b-col
        v-for="(imageUrl, index) in imageUrls"
        :key="index"
        class="p-1 image-containner img-wrap"
        cols="6"
        md="4"
        lg="3"
        xl="3"
      >
        <b-badge
          class="img-delete-button show-when-hovered"
          variant="danger"
          href="#"
          @click.stop="deleteImg(index)"
          >&times;</b-badge
        >
        <div
          v-if="files[index].type === 'application/pdf'"
          class="text-center m-2"
        >
          <i class="far fa-file-pdf" style="font-size: 80px"></i>
        </div>
        <div v-else>
          <b-img
            :src="imageUrl"
            alt="Uploaded Image"
            thumbnail
            fluid
            :style="{ aspectRatio: '1/1', width: '100%', objectFit: 'contain' }"
          />
          <div class="overlay-button-container">
            <b-button
              class="mx-1"
              size="sm"
              variant="secondary"
              @click="imageTransformation({ index, degrees: 90 })"
            >
              <i class="fas fa-rotate-right"></i>
            </b-button>

            <b-button
              class="mx-1"
              size="sm"
              variant="secondary"
              @click="imageTransformation({ index, flipHorizontal: true })"
            >
              <i class="my-icon icon-reflect-horizontal"> </i>
            </b-button>
            <b-button
              class="ml-1"
              size="sm"
              variant="secondary"
              @click="imageTransformation({ index, flipVertical: true })"
            >
              <i class="my-icon icon-reflect-vertical"> </i>
            </b-button>
          </div>
        </div> </b-col
    ></b-row>
    <b-form-group
      label="ประเภท:"
      label-cols-sm="2"
      label-align="right"
      invalid-feedback="กรุณาเลือกประเภท"
      :state="!$v.selectedCategory.$error"
    >
      <b-input-group>
        <b-form-select
          v-model="$v.selectedCategory.$model"
          :options="fileCategories"
          @change="setIsTemplate"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- กรุณาเลือกประเภทไฟล์ --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-input-group-append is-text>
          <b-form-checkbox
            :disabled="!isDocument"
            switch
            v-model="isTemplate"
            v-b-tooltip.hover
            title="Upload เอกสาร Template ไว้ใช้กับคนไข้ทุกท่าน (ใช้ได้กับประเภท Document เท่านั้น)"
          >
            <span class="small-text">Template</span>
          </b-form-checkbox>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <!-- <b-form-group label="วันที่ถ่าย:" label-cols-sm="2">
      <b-form-datepicker
        locale="th"
        reset-button
        v-model="takenAt"
      ></b-form-datepicker
    ></b-form-group> -->
    <b-form-group label="วันที่รักษา:" label-cols-sm="2" label-align="right">
      <b-form-select
        :options="treatmentList"
        value-field="id"
        text-field="dateTime"
        v-model="selectedTreatmentId"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- กรุณาเลือกวันที่ทำการรักษา --</b-form-select-option
          >
        </template>
      </b-form-select></b-form-group
    >
    <b-form-group label="รายละเอียด:" label-cols-sm="2" label-align="right">
      <b-form-textarea
        ref="mediaFileRemark"
        rows="4"
        v-model="remark"
      ></b-form-textarea>
    </b-form-group>
    <Loading v-if="isLoading"></Loading>

    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";

import moment from "moment";
import { required, minLength } from "vuelidate/lib/validators";
import { eventBus } from "../../main";

export default {
  name: "uploadFiles",
  components: {
    Dialog,
    Loading,
  },
  props: ["patientId", "treatmentId", "labId"],
  data() {
    return {
      selectedCategory: null,
      fileCategories: [
        { text: "X-Ray", value: "XRay" },
        { text: "Intra-Oral", value: "IntraOralPhoto" },
        { text: "Extra-Oral", value: "ExtraOralPhoto" },
        { text: "OPD Card", value: "OpdCard" },
        // { text: "Invoice", value: "Invoice" },
        { text: "Document", value: "Document" },
        // { text: "Model3D", value: "Model3d" },
        // { text: "Video", value: "Video" },
      ],
      takenAt: null,
      files: [],
      filesMetadata: [],
      selectedTreatmentId: null,
      isLoading: false,

      imageUrls: [],
      images: [],
      rotations: [],
      flipHorizontals: [],
      flipVerticals: [],
      remark: "",
      isTemplate: false,
      templateTitle: "Template_Doc_",
    };
  },
  validations: {
    selectedCategory: {
      required,
    },
    files: { required },
  },
  watch: {},

  computed: {
    ...mapGetters({
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getTreatmentHistoryList: "moduleTreatment/getTreatmentHistoryList",
    }),
    treatmentList() {
      return this.$utils
        .removeDuplicates(this.getTreatmentHistoryList, "id")
        .map(i => {
          return {
            id: i.id,
            dateTime: this.$utils.formatDateTime(i.creationDt),
            creationDt: i.creationDt,
          };
        })
        .sort((a, b) => new Date(b.creationDt) - new Date(a.creationDt));
    },
    isDocument() {
      return this.selectedCategory === "Document";
    },
  },
  methods: {
    ...mapActions({
      createMediaFiles: "moduleMediaFile/createMediaFiles",
    }),
    show({ category, treatmentId }) {
      this.$refs["uploadFiles"].show();
      this.selectedCategory = category || null;
      if (category === "Template") {
        this.selectedCategory = "Document";
        this.isTemplate = true;
      }

      this.selectedTreatmentId = treatmentId || null;
      this.$v.$reset();
    },
    close() {
      this.$refs["uploadFiles"].hide();
    },
    async saveFiles(bvModalEvent) {
      try {
        bvModalEvent.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.isLoading = true;
        const filePromises = this.imageUrls.map(url =>
          this.blobFromDataURL(url)
        );
        this.files = await Promise.all(filePromises);
        let data = this.isTemplate
          ? {
              category: "Template",
              takenAt: this.takenAt
                ? moment(this.takenAt, "YYYY-MM-DD").toISOString()
                : null,
              patientId: null,
              treatmentId: null,
              labId: null,
              remark:
                this.remark ||
                `${this.templateTitle}${this.$utils.generateDateTimeString()}`,
              filesMetadata: this.filesMetadata,
            }
          : {
              category: this.selectedCategory,
              takenAt: this.takenAt
                ? moment(this.takenAt, "YYYY-MM-DD").toISOString()
                : null,
              patientId: this.patientId ? String(this.patientId) : null,
              treatmentId: this.selectedTreatmentId
                ? String(this.selectedTreatmentId)
                : null,
              labId: this.labId ? String(this.labId) : null,
              remark: this.remark,
              filesMetadata: this.filesMetadata,
            };

        await this.createMediaFiles({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          data,
          files: this.files,
        });
        await this.$nextTick();
        if (this.isTemplate) {
          this.$emit("uploadedTemplateFiles", this.files);
        } else {
          this.$emit("uploadedFiles", this.files);
        }

        this.$refs["uploadFiles"].hide();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleFilesInput(e) {
      this.files = e;
      if (!this.files || this.files.length === 0) return;
      this.imageUrls = [];
      for (let i = 0; i < this.files.length; i++) {
        const imageUrl = URL.createObjectURL(this.files[i]);
        this.imageUrls.push(imageUrl);

        const image = new Image();
        image.src = imageUrl;
        this.images.push(image);

        this.rotations.push(0);
        this.flipHorizontals.push(false);
        this.flipVerticals.push(false);
      }
      this.filesMetadata =
        this.files.map(i => {
          return { contentType: i.type };
        }) || [];
    },

    async imageTransformation({
      index,
      degrees = 0,
      flipHorizontal,
      flipVertical,
    }) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const imageHeight = this.images[index].height; // Swap width and height after rotation
      const imageWidth = this.images[index].width;

      const currentRotation = this.rotations[index] || 0; // Get current rotation or default to 0
      const totalRotation = (currentRotation + degrees) % 360;

      let canvasWidth,
        canvasHeight,
        adjustedFlipHorizontal,
        adjustedFlipVertical;

      if (totalRotation === 90 || totalRotation === 270) {
        canvasWidth = imageHeight;
        canvasHeight = imageWidth;
        adjustedFlipHorizontal = flipVertical;
        adjustedFlipVertical = flipHorizontal;
      } else {
        canvasWidth = imageWidth;
        canvasHeight = imageHeight;
        adjustedFlipHorizontal = flipHorizontal;
        adjustedFlipVertical = flipVertical;
      }
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.translate(canvasWidth / 2, canvasHeight / 2);
      ctx.rotate((totalRotation * Math.PI) / 180);
      this.$set(this.rotations, index, totalRotation);

      if (adjustedFlipHorizontal) {
        if (adjustedFlipHorizontal === !this.flipHorizontals[index]) {
          ctx.scale(-1, 1); // Flip horizontally
        } else {
          ctx.scale(1, 1);
        }
        this.$set(this.flipHorizontals, index, !this.flipHorizontals[index]);
      }
      if (adjustedFlipVertical) {
        if (adjustedFlipVertical === !this.flipVerticals[index]) {
          ctx.scale(1, -1); // Flip horizontally
        } else {
          ctx.scale(1, 1);
        }
        this.$set(this.flipVerticals, index, !this.flipVerticals[index]);
      }
      await this.$nextTick();
      ctx.drawImage(
        this.images[index],
        -this.images[index].width / 2,
        -this.images[index].height / 2
      );
      this.$set(
        this.imageUrls,
        index,
        canvas.toDataURL(this.filesMetadata[index].contentType, 1)
      );
    },
    blobFromDataURL(dataURL) {
      return fetch(dataURL)
        .then(response => response.blob())
        .catch(error => console.error("Error converting to blob:", error));
    },

    deleteImg(index) {
      this.files.splice(index, 1);
      this.filesMetadata.splice(index, 1);
      this.imageUrls.splice(index, 1);
      this.images.splice(index, 1);
      this.rotations.splice(index, 1);
      this.flipHorizontals.splice(index, 1);
      this.flipVerticals.splice(index, 1);
    },
    setIsTemplate(e) {
      if (e !== "Document") this.isTemplate = false;
    },
    setDefaultValue() {
      this.selectedCategory = null;
      this.files = [];
      this.filesMetadata = [];
      this.imageUrls = [];
      this.images = [];
      this.rotations = [];
      this.flipHorizontals = [];
      this.flipVerticals = [];
      this.remark = "";
      this.isTemplate = false;
    },
  },
  mounted() {},
};
</script>

<style>
.my-icon {
  display: inline-block; /* Use inline-flex to vertically center the icon */
  width: 1rem; /* Set the width and height of your icon */
  height: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle; /* Ensure vertical alignment */
  filter: invert(100%);
}
.icon-reflect-vertical {
  background-image: url("~@/assets/icon/reflect-vertical.svg");
}
.icon-reflect-horizontal {
  background-image: url("~@/assets/icon/reflect-horizontal.svg");
}

.image-container {
  position: relative;
}
.overlay-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
}
</style>