<template>
  <div>
    <div ref="body">
      <!-- ข้อมูลผู้ป่วย -->
      <p style="text-indent: 50px; line-height: 2">
        ข้าพเจ้า
        <template v-if="documentData.patient && documentData.patient.title">
          ท{{ documentData.patient.title }}
        </template>
        {{ documentData.ptFullname }}
        เลขประจำตัวประชาชน
        {{
          documentData.patient && documentData.patient.idNumber
            ? documentData.patient.idNumber
            : "______________________________"
        }}
        <template v-if="documentData.patient && documentData.patient.address">
          ที่อยู่ {{ documentData.patient.address }}
        </template>
        ได้รับการอธิบายถึงแผนการรักษาทางทันตกรรมจากทันตแพทย์ผู้ทำการรักษา
        และมีความเข้าใจในรายละเอียดดังต่อไปนี้
      </p>

      <!-- รายละเอียดการรักษา -->
      <div class="treatment-details mb-4">
        <h5 class="font-weight-bold mb-3">รายละเอียดการรักษา: รากฟันเทียม</h5>

        <div class="purpose mb-4">
          <h6 class="font-weight-bold">1. วัตถุประสงค์ของการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            การฝังรากฟันเทียม (Dental Implant) เป็นกระบวนการทดแทนฟันที่สูญเสียไป
            โดยการฝังวัสดุไทเทเนียมที่เป็นมิตรกับร่างกายในกระดูกขากรรไกร
            เพื่อเป็นฐานสำหรับการครอบฟันถาวร
          </p>
        </div>

        <div class="steps mb-4">
          <h6 class="font-weight-bold">2. ขั้นตอนการรักษา</h6>
          <ol class="treatment-steps">
            <li>การตรวจและประเมินสภาพช่องปาก รวมถึงการเอกซเรย์หรือ CT Scan</li>
            <li>
              การเตรียมกระดูกขากรรไกร
              (ในกรณีที่จำเป็นต้องมีการปลูกกระดูกเพิ่มเติม)
            </li>
            <li>การฝังรากฟันเทียม</li>
            <li>การติดตั้งครอบฟันถาวรหลังจากรากฟันเทียมยึดติดกับกระดูกแล้ว</li>
          </ol>
        </div>

        <!-- ความเสี่ยงและภาวะแทรกซ้อน -->
        <div class="risks mb-4">
          <h6 class="font-weight-bold">
            ความเสี่ยงและภาวะแทรกซ้อนที่อาจเกิดขึ้น
          </h6>
          <div class="ml-4">
            <p class="font-weight-bold">1. ภาวะแทรกซ้อนทั่วไป:</p>
            <ul class="complication-list">
              <li>การอักเสบหรือการติดเชื้อที่ตำแหน่งฝังรากฟันเทียม</li>
              <li>อาการปวดหรือบวมหลังการผ่าตัด</li>
              <li>การบาดเจ็บต่อเส้นประสาทในกรณีที่รากฟันใกล้เส้นประสาท</li>
            </ul>

            <p class="font-weight-bold mt-3">2. ภาวะแทรกซ้อนระยะยาว:</p>
            <ul class="complication-list">
              <li>การสูญเสียรากฟันเทียมเนื่องจากไม่ยึดติดกับกระดูก</li>
              <li>การละลายของกระดูกรอบรากฟันเทียม</li>
              <li>การแตกหรือเสียหายของครอบฟัน</li>
            </ul>
          </div>
        </div>

        <!-- ข้อจำกัดของการรักษา -->
        <div class="limitations mb-4">
          <h6 class="font-weight-bold">ข้อจำกัดของการรักษา</h6>
          <ul class="limitation-list">
            <li>
              ความสำเร็จของการรักษาขึ้นอยู่กับการดูแลสุขภาพช่องปากของผู้ป่วย
              เช่น การทำความสะอาด การตรวจสุขภาพฟัน และการงดสูบบุหรี่
            </li>
            <li>
              การรักษาอาจใช้เวลานาน (3-6 เดือน)
              เนื่องจากต้องรอให้รากฟันเทียมยึดติดกับกระดูก
            </li>
          </ul>
        </div>
        <div class="mb-5"></div>

        <!-- ทางเลือกอื่นในการรักษา -->
        <div class="alternatives mb-4">
          <h6 class="font-weight-bold">ทางเลือกอื่นในการรักษา</h6>
          <ul class="alternative-list">
            <li>การใช้สะพานฟัน (Bridge)</li>
            <li>การใส่ฟันปลอมแบบถอดได้</li>
            <li>
              การไม่ทำการรักษา (แต่ทราบถึงผลกระทบ เช่น
              กระดูกละลายหรือฟันเคลื่อนตัว)
            </li>
          </ul>
        </div>

        <div v-if="documentData.commentDetail" class="page-break-before">
          <p class="font-weight-bold">หมายเหตุ :</p>
          <p class="my-justify" style="text-indent: 50px; line-height: 2">
            {{ documentData.commentDetail }}
          </p>
        </div>

        <!-- สิทธิ์และการยินยอมของผู้ป่วย -->
        <div class="patient-rights mb-4">
          <h6 class="font-weight-bold">สิทธิ์และการยินยอมของผู้ป่วย</h6>
          <ol class="rights-list">
            <li>
              ข้าพเจ้าได้รับการอธิบายเกี่ยวกับวัตถุประสงค์ ขั้นตอน
              และความเสี่ยงของการรักษารากฟันเทียมอย่างละเอียด
            </li>
            <li>
              ข้าพเจ้าเข้าใจถึงข้อจำกัดของการรักษา
              และยินยอมที่จะปฏิบัติตามคำแนะนำของทันตแพทย์
            </li>
            <li>
              ข้าพเจ้ามีสิทธิ์ที่จะสอบถามเพิ่มเติมหรือขอเลื่อนการรักษาหากไม่พร้อม
            </li>
          </ol>
        </div>
      </div>

      <!-- การยินยอม -->
      <div class="consent-section mt-8">
        <h6 class="font-weight-bold mb-3">การยินยอมสำหรับการรักษา</h6>
        <p style="text-indent: 50px; line-height: 2">
          ข้าพเจ้ายินยอมให้ดำเนินการรักษารากฟันเทียมตามที่ระบุข้างต้น
          และเข้าใจความเสี่ยง รวมถึงทางเลือกอื่นที่ได้รับการอธิบาย
        </p>

        <!-- ลายเซ็น -->
        <div class="signature-section mt-8">
          <b-row class="mb-5">
            <b-col cols="5" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ผู้ป่วย/ผู้ให้ความยินยอม</div>
              </div>
              <div class="mt-2">({{ documentData.ptFullname }})</div>
              <div class="mt-2">
                วันที่: {{ documentData.today || ".................." }}
              </div>
            </b-col>

            <b-col cols="5" offset="2" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ทันตแพทย์ผู้ให้การรักษา</div>
              </div>
              <div class="mt-2">
                ({{ documentData.title
                }}{{
                  documentData.doctorObj ? documentData.doctorObj.fullName : ""
                }})
              </div>
              <div class="mt-2">
                วันที่: {{ documentData.today || ".................." }}
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DentalImplantConsentForm",
  props: {
    documentData: {
      type: Object,
      required: true,
      default: () => ({
        clinicName: "",
        clinicAddress: "",
        clinicPhone: "",
        ptFullname: "",
        patient: {
          idNumber: "",
          dob: null,
          address: "",
          phone: "",
          dn: "",
        },
        title: "",
        doctorObj: {
          fullName: "",
        },
        photoConsent: "no_consent",
        commentDetail: "",
        today: null,
      }),
    },
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
};
</script>

<style scoped>
.treatment-steps,
.rights-list {
  padding-left: 2rem;
  line-height: 2;
}

.treatment-steps li,
.complication-list li,
.limitation-list li,
.alternative-list li,
.rights-list li {
  margin-bottom: 0.5rem;
}

.complication-list,
.limitation-list,
.alternative-list {
  list-style-type: disc;
  padding-left: 2rem;
  line-height: 2;
}

.signature-line {
  margin-bottom: 0.5rem;
}

.font-weight-bold {
  font-weight: bold;
}

.signature-section {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.info-group {
  margin-bottom: 0.5rem;
}

.info-group label {
  display: inline-block;
  min-width: 150px;
  margin-right: 1rem;
}

.consent-checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.consent-checkbox input[type="checkbox"] {
  margin-top: 0.25rem;
}

.dotted-line {
  display: inline-block;
  min-width: 200px;
  border-bottom: 1px dotted #000;
  margin: 0 8px;
}

.title-with-border {
  border-bottom: 2px solid #000;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.indent {
  text-indent: 50px;
}

.content-section {
  margin-bottom: 2rem;
}

.required-field::after {
  content: "*";
  color: #dc3545;
  margin-left: 4px;
}

@media print {
  .signature-section {
    page-break-inside: avoid;
  }
}
</style>