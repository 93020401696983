// templates/MedicalCertTemplate.vue
<template>
  <div>
    <div ref="body">
      <h5 class="text-center my-3">ใบรับรองแพทย์</h5>
      <p class="text-right mr-3">วันที่ {{ documentData.today }}</p>
      <p style="text-indent: 50px; line-height: 2" class="my-justify">
        ข้าพเจ้า
        {{ documentData && documentData.title ? documentData.title : "" }}
        {{ documentData.doctorObj.fullName }}
        เป็นผู้จดทะเบียนประกอบโรคศิลปะแผนปัจจุบันชั้นหนึ่ง สาขาทันตกรรม
        ใบอนุญาตที่ ท
        {{ documentData.doctorObj.drLicenseNo }} ได้ทำการตรวจ/รักษา
        {{ documentData.ptFullname }} อายุ
        {{ calAge(documentData.patient.dob) || "________" }} ปี DN.
        {{ documentData.patient.dn }}
      </p>
      <p class="mb-1">ได้รับการวินิจฉัย และตรวจรักษา:</p>
      <div v-if="documentData.selectedItem.length === 0">
        <p class="line-container" v-for="n in 3" :key="n">
          <span class="printable-line"></span>
        </p>
      </div>
      <div v-else>
        <div v-for="(items, key) in documentData.groupSelectedItem" :key="key">
          <p class="mb-1">วันที่ {{ key }}</p>
          <ul :style="{ listStyleType: 'none' }">
            <li v-for="(item, index) in items" :key="item.id">
              {{ index + 1 }}. ตำแหน่ง/ซี่ฟัน
              {{
                item.toothItems && item.toothItems.length > 0
                  ? item.toothNumberSurfaces
                  : "____________"
              }}
              ได้รับการวินิจฉัย
              {{ item.dxName || "______________________________" }}
              รักษาด้วยการ
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>

      <p class="mb-1">ความเห็นของทันตแพทย์ :</p>
      <div v-if="!documentData.commentDetail">
        <p class="line-container" v-for="n in 3" :key="n">
          <span class="printable-line"></span>
        </p>
      </div>
      <div v-else>
        <p class="my-justify" style="text-indent: 50px; line-height: 2">
          {{ documentData.commentDetail }}
        </p>
      </div>
    </div>

    <!-- Document Footer -->
    <div ref="footer">
      <b-row class="mr-3" style="margin-top: 8rem">
        <b-col class="text-right">
          <span class="border-top px-4 py-3"
            >{{ documentData && documentData.title ? documentData.title : ""
            }}{{ documentData.doctorObj.fullName }}</span
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { documentConfig } from "../config/documentConfig";

export default {
  name: "MedicalCertTemplate",
  props: {
    documentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { documentConfig };
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
};
</script>