// TraditionalBracesConsentForm.vue
<template>
  <div>
    <div ref="body">
      <!-- ข้อมูลผู้ป่วย -->
      <p style="text-indent: 50px; line-height: 2">
        ข้าพเจ้า
        <template v-if="documentData.patient && documentData.patient.title">
          ท{{ documentData.patient.title }}
        </template>
        {{ documentData.ptFullname }}
        เลขประจำตัวประชาชน
        {{
          (documentData.patient && documentData.patient.idNumber) ||
          "______________________________"
        }}
        <template v-if="documentData.patient && documentData.patient.address">
          ที่อยู่ {{ documentData.patient.address }}
        </template>
        ได้รับการอธิบายถึงแผนการรักษาทางทันตกรรมจัดฟันจากทันตแพทย์ผู้ทำการรักษา
        และมีความเข้าใจในรายละเอียดดังต่อไปนี้
      </p>

      <!-- รายละเอียดการรักษา -->
      <div class="treatment-details mb-4">
        <h5 class="font-weight-bold mb-3">รายละเอียดการรักษา: การจัดฟันแบบดั้งเดิม</h5>

        <div class="purpose mb-4">
          <h6 class="font-weight-bold">1. วัตถุประสงค์ของการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            การจัดฟันแบบดั้งเดิม (Traditional Braces) เป็นการรักษาเพื่อแก้ไขการเรียงตัวของฟัน
            การสบฟันที่ผิดปกติ และปัญหาความสวยงาม โดยใช้เครื่องมือจัดฟันแบบติดแน่น
            เพื่อเคลื่อนฟันไปยังตำแหน่งที่เหมาะสม
          </p>
        </div>

        <div class="treatment-process mb-4">
          <h6 class="font-weight-bold">2. ขั้นตอนการรักษา</h6>
          <ol class="treatment-steps">
            <li>การตรวจวินิจฉัย ถ่ายภาพ และวางแผนการรักษา</li>
            <li>การเตรียมช่องปากก่อนการจัดฟัน (ถ้าจำเป็น เช่น ถอนฟัน ขูดหินปูน)</li>
            <li>การติดเครื่องมือจัดฟัน (แบร็คเก็ตและลวด)</li>
            <li>การปรับเครื่องมือเป็นระยะตามแผนการรักษา</li>
            <li>การถอดเครื่องมือจัดฟันและใส่เครื่องมือคงสภาพฟัน</li>
          </ol>
        </div>

        <div class="duration mb-4">
          <h6 class="font-weight-bold">3. ระยะเวลาการรักษา</h6>
          <ul class="duration-list">
            <li>ระยะเวลาการรักษาโดยทั่วไปประมาณ 18-36 เดือน ขึ้นอยู่กับความรุนแรงของปัญหา</li>
            <li>ต้องมาพบทันตแพทย์เพื่อปรับเครื่องมือทุก 4-6 สัปดาห์</li>
            <li>ต้องใส่เครื่องมือคงสภาพฟันหลังการรักษาอย่างน้อย 12 เดือน</li>
          </ul>
        </div>

        <div class="risks mb-4">
          <h6 class="font-weight-bold">4. ความเสี่ยงและภาวะแทรกซ้อนที่อาจเกิดขึ้น</h6>
          <div class="ml-4">
            <p class="font-weight-bold">ก. ภาวะแทรกซ้อนทั่วไป:</p>
            <ul class="complication-list">
              <li>ความเจ็บปวดหรือไม่สบายในช่วงแรกของการใส่เครื่องมือและหลังการปรับเครื่องมือ</li>
              <li>แผลในช่องปากจากการเสียดสีของเครื่องมือ</li>
              <li>การสะสมของคราบจุลินทรีย์รอบแบร็คเก็ตหากทำความสะอาดไม่ดี</li>
              <li>รากฟันอาจสั้นลงเล็กน้อยจากการเคลื่อนฟัน (มักไม่ส่งผลต่อการใช้งาน)</li>
            </ul>

            <p class="font-weight-bold mt-3">ข. ภาวะแทรกซ้อนที่อาจพบได้:</p>
            <ul class="complication-list">
              <li>ฟันผุหรือรอยขาวบนผิวฟันหากการทำความสะอาดไม่ดีพอ</li>
              <li>เหงือกอักเสบหรือมีปัญหาปริทันต์</li>
              <li>การคืนกลับของฟันหากไม่ใส่เครื่องมือคงสภาพฟันตามกำหนด</li>
            </ul>
          </div>
        </div>

        <div class="care-instructions mb-4">
          <h6 class="font-weight-bold">5. การดูแลรักษาและข้อควรปฏิบัติ</h6>
          <ul class="care-list">
            <li>ทำความสะอาดฟันและเครื่องมือจัดฟันอย่างละเอียดหลังทุกมื้ออาหาร</li>
            <li>หลีกเลี่ยงอาหารแข็ง เหนียว หรือกรอบที่อาจทำให้เครื่องมือหลุดหรือแตกหัก</li>
            <li>มาพบทันตแพทย์ตามนัดอย่างสม่ำเสมอ</li>
            <li>ใส่เครื่องมือคงสภาพฟันตามคำแนะนำของทันตแพทย์</li>
            <li>แจ้งทันตแพทย์ทันทีหากมีปัญหาหรือความผิดปกติใดๆ</li>
          </ul>
        </div>

        <div class="alternatives mb-4">
          <h6 class="font-weight-bold">6. ทางเลือกอื่นในการรักษา</h6>
          <ul class="alternative-list">
            <li>การจัดฟันแบบใส (Clear Aligners)</li>
            <li>การจัดฟันแบบติดด้านใน (Lingual Braces)</li>
            <li>การรักษาแบบไม่จัดฟัน (เช่น การทำวีเนียร์ในบางกรณี)</li>
            <li>การไม่รักษา (แต่ปัญหาการสบฟันอาจส่งผลต่อสุขภาพช่องปากในระยะยาว)</li>
          </ul>
        </div>

        <div v-if="documentData.commentDetail">
          <p class="font-weight-bold">หมายเหตุ :</p>
          <p class="my-justify" style="text-indent: 50px; line-height: 2">
            {{ documentData.commentDetail }}
          </p>
        </div>

        <div class="consent-section mt-4">
          <h6 class="font-weight-bold">การยินยอมการรักษา</h6>
          <p style="text-indent: 50px; line-height: 2">
            ข้าพเจ้าได้รับทราบถึงวิธีการรักษา ระยะเวลา ประโยชน์ที่จะได้รับ
            และความเสี่ยงที่อาจเกิดขึ้นจากการรักษา รวมถึงทางเลือกอื่นในการรักษาแล้ว
            ข้าพเจ้ายินยอมให้ทำการรักษาด้วยการจัดฟันแบบดั้งเดิมตามที่ได้วางแผนไว้
          </p>
        </div>

        <div class="signature-section mt-8">
          <b-row class="mb-5">
            <b-col cols="5" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ผู้ป่วย/ผู้ให้ความยินยอม</div>
              </div>
              <div class="mt-2">({{ documentData.ptFullname }})</div>
              <div class="mt-2">
                วันที่: {{ documentData.consentDate || ".................." }}
              </div>
            </b-col>

            <b-col cols="5" offset="2" class="text-center">
              <div class="signature-line">
                ลงชื่อ........................................
                <div class="mt-1">ทันตแพทย์ผู้ให้การรักษา</div>
              </div>
              <div class="mt-2">
                ({{ documentData.title }}{{ getDoctorName }})
              </div>
              <div class="mt-2">
                วันที่: {{ documentData.consentDate || ".................." }}
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TraditionalBracesConsentForm',
  props: {
    documentData: {
      type: Object,
      required: true,
      default: () => ({
        clinicName: '',
        clinicAddress: '',
        clinicPhone: '',
        ptFullname: '',
        patient: {
          idNumber: '',
          dob: null,
          address: '',
          phone: '',
          dn: ''
        },
        title: '',
        doctorObj: {
          fullName: ''
        },
        consentDate: null,
        commentDetail: ''
      })
    }
  },
  computed: {
    getDoctorName() {
      return this.documentData.doctorObj && this.documentData.doctorObj.fullName 
        ? this.documentData.doctorObj.fullName 
        : ''
    }
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return
      const date = moment(dateString, 'YYYY-MM-DD')
      return moment().diff(date, 'years')
    }
  }
}
</script>

<style scoped>
.treatment-steps,
.duration-list,
.complication-list,
.care-list,
.alternative-list {
  padding-left: 2rem;
  line-height: 2;
}

.treatment-steps li,
.duration-list li,
.complication-list li,
.care-list li,
.alternative-list li {
  margin-bottom: 0.5rem;
}

.signature-line {
  margin-bottom: 0.5rem;
}

.font-weight-bold {
  font-weight: bold;
}

.signature-section {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

@media print {
  .signature-section {
    page-break-inside: avoid;
  }
}
</style>