<template>
  <b-modal
    modal-class="component-modal-payment"
    id="PaymentModal"
    ref="PaymentModal"
    size="fluid"
    title="ชำระเงิน"
    no-close-on-backdrop
    @hidden="defaultValue"
  >
    <div class="row">
      <div
        class="col-12 col-lg-2 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
      >
        <PatientProfile
          :patient="patient"
          button-name="ประวัติคนไข้"
          :button-click="
            () => {
              openProfileCustomerModal();
            }
          "
        />
      </div>
      <div class="col">
        <div class="row">
          <b-col>
            <b-collapse v-model="newAptCard">
              <b-card body-class="p-2 mr-3">
                <div v-if="appointmentDraft">
                  <b-form-group
                    label="แพทย์ต้องการให้นัดครั้งต่อไป (Next visit):"
                    label-class="font-weight-bold pt-0 ml-3"
                    class="mb-1"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          label-cols-sm="4"
                          label="นัดกับแพทย์:"
                          label-align-sm="right"
                          label-size="sm"
                        >
                          <b-form-input
                            size="sm"
                            v-model="aptDraftDisplay.doctor"
                            readonly
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          label-cols-sm="4"
                          label="เพื่อรักษา:"
                          label-align-sm="right"
                          label-size="sm"
                        >
                          <b-form-input
                            size="sm"
                            :value="removeTags(aptDraftDisplay.treatmentItems)"
                            readonly
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          label-cols-sm="4"
                          label="ใช้ระยะเวลา:"
                          label-align-sm="right"
                          label-size="sm"
                        >
                          <b-input-group size="sm" append="นาที">
                            <b-form-input
                              v-model="aptDraftDisplay.duration"
                              readonly
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          label-cols-sm="4"
                          label="นัดในอีก:"
                          label-align-sm="right"
                          label-size="sm"
                        >
                          <b-input-group size="sm" append="วัน">
                            <b-form-input
                              v-model="aptDraftDisplay.inDays"
                              readonly
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          v-if="aptDraftDisplay.note"
                          label-cols-sm="2"
                          label="โน็ตเพิ่มเติม:"
                          label-align-sm="right"
                          label-size="sm"
                          readonly
                        >
                          <b-form-input
                            size="sm"
                            v-model="aptDraftDisplay.note"
                            readonly
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <div v-if="!nextVisitData" class="float-right">
                  <span class="description text-muted mr-2"
                    >*แนะนำให้สร้างนัดหมายครั้งต่อไปก่อนชำระเงิน</span
                  >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="openEventModal()"
                    >สร้างนัดหมาย</b-button
                  >
                </div>
              </b-card>
            </b-collapse>
            <b-collapse v-if="nextVisitData" v-model="editAptCard">
              <b-card
                body-class="p-2 mr-3"
                :style="{ backgroundColor: '#e3ffec' }"
              >
                <b-form-group
                  label="สร้างนัดครั้งต่อไปเรียบร้อย"
                  label-class="font-weight-bold pt-0 ml-3"
                  class="mb-1"
                >
                  <b-row>
                    <b-col>
                      <b-form-group
                        class="mb-2"
                        label-cols-sm="4"
                        label="นัดกับแพทย์:"
                        label-align-sm="right"
                        label-size="sm"
                      >
                        <b-form-input
                          size="sm"
                          v-model="nextVisitData.extendedProps.doctorName"
                          readonly
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="mb-2"
                        label-cols-sm="4"
                        label="เพื่อรักษา:"
                        label-align-sm="right"
                        label-size="sm"
                      >
                        <b-form-input
                          size="sm"
                          :value="removeTags(aptDraftDisplay.treatmentItems)"
                          readonly
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        class="mb-2"
                        label-cols-sm="4"
                        label="ใช้ระยะเวลา:"
                        label-align-sm="right"
                        label-size="sm"
                      >
                        <b-input-group size="sm" append="นาที">
                          <b-form-input
                            v-model="nextVisitData.extendedProps.timeDuration"
                            readonly
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="mb-2"
                        label-cols-sm="4"
                        label="ในวันที่:"
                        label-align-sm="right"
                        label-size="sm"
                      >
                        <b-form-input
                          size="sm"
                          v-model="aptDate"
                          readonly
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="float-right">
                    <b-button
                      v-if="appointmentDraft"
                      class="mr-2"
                      size="sm"
                      @click="newAptCard = !newAptCard"
                      >ดุข้อมูล Next visit</b-button
                    >
                    <b-button
                      size="sm"
                      variant="info"
                      @click="openEventModalEdited(nextVisitData)"
                      >แก้ไขนัดหมาย</b-button
                    >
                  </div>
                </b-form-group>
              </b-card>
            </b-collapse>
          </b-col>
        </div>
        <hr class="mt-0" />
        <div class="row">
          <div class="col">
            <b-form id="paymentForm" @submit.prevent="openPaymentMethodModal">
              <b-table
                id="payment"
                ref="payment"
                class
                small
                sort-icon-left
                head-variant="light"
                :busy="tableBusy"
                :items="treatmentItemList"
                :fields="fields"
                sort-by="creationDt"
                :sort-desc="true"
              >
                <template #cell(index)="row">{{ row.index + 1 }}.</template>
                <template #cell(creationDt)="row">
                  {{ formatDate(row.value) }}
                </template>
                <template #cell(amount)="row">
                  <div v-if="row.item.amount === undefined"></div>
                  <div v-else class="text-right">
                    <span :class="row.item.overdueFlag ? 'text-muted' : ''">
                      {{ row.item.amount }}
                    </span>
                    <span v-if="row.item.remained" class="text-danger"
                      >({{ row.item.remained }})</span
                    >
                  </div>
                </template>
                <template #cell(discountBtn)="row">
                  <b-button
                    v-if="!row.item.emptyRow"
                    class="mr-1"
                    size="sm"
                    variant="outline-info"
                    @click="row.toggleDetails"
                    :disabled="row.item.overdueFlag || isNhso"
                  >
                    <i class="fas fa-info"></i>
                  </b-button>
                </template>
                <template #row-details="row">
                  <b-card>
                    <b-form-group
                      label-cols="3"
                      label="ราคาเต็ม:"
                      label-align="right"
                      label-size="sm"
                    >
                      <b-row>
                        <b-input-group size="sm" class="col" append="บาท">
                          <b-form-input
                            type="number"
                            v-model="row.item.price"
                            readonly
                          ></b-form-input>
                        </b-input-group>
                        <b-form-radio-group
                          class="align-self-center"
                          size="sm"
                          v-model="row.item.discountMethod"
                          @change="calDiscount(row.item)"
                        >
                          <b-form-radio :value="0">ลดปกติ</b-form-radio>
                          <b-form-radio :value="1">ลดแพทย์/คลินิก</b-form-radio>
                        </b-form-radio-group>
                      </b-row>
                    </b-form-group>

                    <!-- ลดราคาปกติ -->
                    <div v-if="row.item.discountMethod === 0">
                      <b-form-group
                        label-cols="3"
                        label="ส่วนลดปกติ:"
                        label-align="right"
                        label-size="sm"
                        description="*ลดส่วนแพทย์ และคลินิกในอัตราส่วนเท่ากัน"
                      >
                        <b-row>
                          <b-input-group size="sm" class="col">
                            <b-form-input
                              v-model="row.item.discountPrice"
                              type="number"
                              @change="calDiscount(row.item)"
                              @keypress.enter.prevent
                            ></b-form-input>
                            <template #append>
                              <b-input-group-text>
                                {{ row.item.discountType == 0 ? "%" : "บาท" }}
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-form-radio-group
                            class="align-self-center"
                            size="sm"
                            v-model="row.item.discountType"
                            @change="calDiscount(row.item)"
                          >
                            <b-form-radio :value="0">%</b-form-radio>
                            <b-form-radio
                              :value="1"
                              :disabled="row.item.dfType === 1"
                              >บาท</b-form-radio
                            >
                          </b-form-radio-group>
                        </b-row>
                      </b-form-group>
                    </div>

                    <!-- ลดราคาแพทย์/clinic -->
                    <div v-else>
                      <b-form-group
                        label-cols="3"
                        label="วิธีลด:"
                        label-align="right"
                        label-size="sm"
                      >
                        <b-form-radio-group
                          class="pt-1"
                          size="sm"
                          v-model="row.item.discountType"
                          @change="calDiscount(row.item)"
                        >
                          <b-form-radio :value="0">%</b-form-radio>
                          <b-form-radio :value="1">บาท</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                      <b-form-group
                        label-cols="3"
                        label="ส่วนลดแพทย์:"
                        label-align="right"
                        label-size="sm"
                      >
                        <b-row>
                          <b-input-group
                            size="sm"
                            class="col"
                            prepend="DF"
                            append="บาท"
                          >
                            <b-form-input
                              :value="
                                row.item.dfType === 0
                                  ? (row.item.price * row.item.df) / 100
                                  : row.item.df * (row.item.quantity || 1)
                              "
                              type="number"
                              disabled
                            ></b-form-input>
                          </b-input-group>
                          <b-input-group size="sm" class="col">
                            <b-form-input
                              v-model="row.item.doctorDiscountPrice"
                              type="number"
                              @change="calDiscount(row.item)"
                              @keypress.enter.prevent
                            ></b-form-input>
                            <template #append>
                              <b-input-group-text>
                                {{ row.item.discountType == 0 ? "%" : "บาท" }}
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-input-group
                            v-if="row.item.discountType === 0"
                            size="sm"
                            class="col"
                            append="บาท"
                          >
                            <b-form-input
                              :value="
                                row.item.price *
                                (row.item.doctorDiscountPrice / 100)
                              "
                              readonly
                            ></b-form-input>
                          </b-input-group>
                        </b-row>
                      </b-form-group>
                      <b-form-group
                        label-cols="3"
                        label="ส่วนลดคลินิก:"
                        label-align="right"
                        label-size="sm"
                      >
                        <b-row>
                          <b-input-group
                            size="sm"
                            class="col"
                            prepend="CF"
                            append="บาท"
                          >
                            <b-form-input
                              :value="
                                row.item.dfType === 0
                                  ? row.item.price -
                                    (row.item.price * row.item.df) / 100
                                  : row.item.price -
                                    row.item.df * (row.item.quantity || 1)
                              "
                              type="number"
                              disabled
                            ></b-form-input>
                          </b-input-group>
                          <b-input-group size="sm" class="col">
                            <b-form-input
                              v-model="row.item.clinicDiscountPrice"
                              type="number"
                              @change="calDiscount(row.item)"
                              @keypress.enter.prevent
                            ></b-form-input>
                            <template #append>
                              <b-input-group-text>
                                {{ row.item.discountType == 0 ? "%" : "บาท" }}
                              </b-input-group-text>
                            </template>
                          </b-input-group>
                          <b-input-group
                            v-if="row.item.discountType === 0"
                            size="sm"
                            class="col"
                            append="บาท"
                          >
                            <b-form-input
                              :value="
                                row.item.price *
                                (row.item.clinicDiscountPrice / 100)
                              "
                              readonly
                            ></b-form-input>
                          </b-input-group>
                        </b-row>
                      </b-form-group>
                      <div class="description text-muted text-right">
                        ส่วนลดเป็นเปอร์เซ็นต์ จะคิดจากราคารวม
                      </div>
                    </div>
                    <hr />
                    <b-form-group
                      label-cols="3"
                      label="ราคาหลังหักส่วนลด:"
                      label-align="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm" append="บาท">
                        <b-form-input
                          type="number"
                          v-model="row.item.amount"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-card>
                </template>

                <template #cell(paid)="row">
                  <div v-if="row.item.price === undefined"></div>
                  <div v-else>
                    <b-form-input
                      class="text-right"
                      size="sm"
                      :required="false"
                      v-model="row.item.paid"
                      number
                      :disabled="isNhso"
                      @change="calPaidItem(row.item)"
                    ></b-form-input>
                  </div>
                </template>
                <template #cell(overdue)="row">
                  <div v-if="row.item.price === undefined"></div>
                  <div v-else>
                    <b-form-input
                      :class="{ 'text-danger': row.item.overdue != 0 }"
                      size="sm"
                      v-model="row.item.overdue"
                      readonly
                    ></b-form-input>
                  </div>
                </template>
                <template #cell(editButton)="row">
                  <b-button
                    v-if="row.item.overdueFlag"
                    v-show="isManager || isHeadCounter"
                    size="sm"
                    variant="outline-danger"
                    :disabled="!isManager"
                    @click="openVerifyDeleteOverdueModal(row.item)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    variant="outline-secondary"
                    @click="openPaymentHistoryModal(row.item.appointmentId)"
                  >
                    <i class="fas fa-edit"></i>
                  </b-button>
                </template>

                <template #bottom-row> </template>
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
              </b-table>

              <div>
                <b-form-group
                  label-cols="8"
                  label-align="right"
                  label="ยอดชำระสุทธิ:"
                >
                  <b-input-group append="บาท">
                    <b-form-input
                      class="text-right"
                      :value="receipt.amount"
                      readonly
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-cols="8"
                  label-align="right"
                  label="ยอดชำระวันนี้:"
                >
                  <b-input-group append="บาท">
                    <b-form-input
                      class="text-right"
                      :value="receipt.paid"
                      readonly
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  v-if="overdueState"
                  label-cols="8"
                  label-align="right"
                  label="ค้างชำระ:"
                  label-class="text-danger"
                >
                  <b-input-group append="บาท">
                    <b-form-input
                      class="text-right"
                      :value="receipt.overdue"
                      :state="!overdueState"
                      readonly
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button @click="openPaymentHistoryModal(appointmentId)">
        <i class="fas fa-caret-left"></i>
        <i class="fas fa-caret-left"></i> แก้ไขรายการรักษา
      </b-button>
      <b-button type="submit" form="paymentForm" variant="primary"
        >ชำระเงิน</b-button
      >
    </template>
    <PaymentMethodModal
      ref="paymentMethodModal"
      :appointmentLinkedWithKtbPaotang="appointmentLinkedWithKtbPaotang"
      @openPaymentReceiptModal="openPaymentReceiptModal"
    ></PaymentMethodModal>
    <VerifyDeleteOverdueModal
      ref="verifyDeleteOverdueModal"
      :afterDelete="afterDelete"
    />
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import PaymentMethodModal from "@/components/modal/PaymentMethod";
import VerifyDeleteOverdueModal from "@/components/modal/VerifyDeleteOverdue";
import moment from "moment";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import { eventBus } from "@/main";

export default {
  name: "Payment",
  components: {
    PaymentMethodModal,
    VerifyDeleteOverdueModal,
    Loading,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      appointment: {},
      appointmentLinkedWithKtbPaotang: false,
      patient: {},
      appointmentId: null,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
          sortable: true,
        },
        {
          key: "name",
          label: "รายการ",
        },
        {
          key: "doctor",
          label: "แพทย์",
        },
        {
          key: "quantity",
          label: "จำนวน",
          class: "text-right",
          thStyle: { width: "70px" },
        },
        {
          key: "amount",
          label: "ยอดสุทธิ (ยอดค้าง)",
          thClass: "text-right",
        },
        {
          key: "discountBtn",
          label: "",
        },
        {
          key: "paid",
          label: "ชำระวันนี้",
          thStyle: { width: "80px" },
        },
        {
          key: "editButton",
          label: "",
        },
      ],
      treatment: null,
      treatmentList: [],
      overdueTreatmentList: [],
      appointmentDraft: null,
      aptDraftDisplay: {},
      nextVisitData: null,
      aptDate: "",
      newAptCard: true,
      editAptCard: false,

      receipt: {
        price: 0,
        amount: 0,
        Df: 0,
        Cf: 0,
        discountValue: 0,
        discountTotal: 0,
        paidDfDiscounted: 0,
        paidCfDiscounted: 0,
        discountPaidTotal: 0,
        finalDiscount: 0,
        overdue: 0,
        paid: 0,
        paidDf: 0,
        paidCf: 0,
        receiptItems: [],
        appointmentId: null,
        treatmentId: null,
        treatmentModifyDate: null,
      },
      index: null,
      tableBusy: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      getOrthoPackageList: "moduleTreatment/getOrthoPackageList",
      getOrthoPackageItemList: "moduleTreatment/getOrthoPackageItemList",
      getOrthoPackageItemBy: "moduleTreatment/getOrthoPackageItemBy",

      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",
      getUserInfo: "moduleUser/getUserInfo",
      getUserList: "moduleUser/getUserList",
      getUserListBy: "moduleUser/getUserListBy",

      authorization: "moduleUser/getAuthorization",
      viewAvatar: "moduleContent/viewAvatar",
      getAppointmentFormat: "moduleAppointment/getAppointmentFormat",
      getTreatmentBy: "moduleAppointment/getTreatmentBy",
      getTimeDuration: "moduleAppointment/getTimeDuration",

      isManager: "moduleUser/isManager",
      isHeadCounter: "moduleUser/isHeadCounter",
    }),
    treatmentItemList() {
      let allItems = [];
      let variants = ["lightBlue", "beige", "sky", "lightPurple", "skinTone"];
      let set = [...variants];
      let bgColor;
      this.treatmentList.forEach(appt => {
        let apptItems;
        if (appt.items.length === 0) {
          apptItems = [
            {
              appointmentId: appt.appointmentId,
              treatmentItemId: appt.id,
              creationDt: appt.creationDt,
              name: "-- ไม่มีรายการรักษา --",
              nameEn: "-- No treatment items --",
              emptyRow: true,
              _rowVariant: "dark",
            },
          ];
        } else {
          bgColor = set.shift();
          if (!set.length) {
            set = [...variants];
          }
          apptItems = appt.items.map(i => {
            return {
              appointmentId: appt.appointmentId,
              treatmentItemId: i.id,
              price: i.price, //amount?
              discountMethod: 0,
              discountType: 0,
              discountPrice: 0,
              doctorDiscountPrice: 0,
              clinicDiscountPrice: 0,
              finalDf: 0,
              finalCf: 0,
              amount: i.price, //price?
              finalDiscount: 0,
              overdue: 0,
              paid: 0,
              paidDf: 0,
              paidCf: 0,
              creationDt: appt.creationDt,
              name: i.name,
              nameEn: i.nameEn,
              doctor: this.getUserListBy(i.actionUid, "uid")?.fullName, //เปลี่ยนจาก actionUid
              doctorEn: this.getUserListBy(i.actionUid, "uid")?.fullNameEn,
              quantity: i.quantity || i.toothItems?.length || "",
              dfType: i.dfType,
              df: i.df,
              _rowVariant: bgColor,
            };
          });
          allItems.push(...apptItems);
        }
      });
      let overdueList = this.formatOverdueItems(this.overdueTreatmentList);
      allItems = overdueList.concat(allItems);
      return allItems;
    },
    overdueState() {
      return this.receipt.overdue > 0;
    },
    isNhso() {
      return this.appointment.extendedProps.nhsoVisitNo > 0;
    },
  },
  watch: {
    nextVisitData() {
      if (this.nextVisitData === null) return;
      this.editAptCard = Boolean(this.nextVisitData);
      this.newAptCard = Boolean(!this.nextVisitData);
      this.aptDate = moment(this.nextVisitData.start)
        .locale("th")
        .format("ddd D MMM YY / H:mm");
    },
  },
  methods: {
    ...mapActions({
      fetchQueueAppointmentById: "moduleAppointment/fetchQueueAppointmentById",
      fetchQueueAppointmentDraftByApptId:
        "moduleAppointment/fetchQueueAppointmentDraftByApptId",
      fetchTreatmentByApptId: "moduleTreatment/fetchTreatmentByApptId",
      fetchItemsPaymentByAptId: "moduleTreatment/fetchItemsPaymentByAptId",
      fetchOverdueTreatmentByPatientId:
        "moduleTreatment/fetchOverdueTreatmentByPatientId",
    }),
    init() {},
    show(appointment) {
      this.$bvModal.show("PaymentModal");
      let extendedProps = Object.assign(appointment.extendedProps);
      this.patient = Object.assign(extendedProps.patient);
      this.appointmentId = appointment.id;

      eventBus.$on("nextVisitCreated", e => {
        this.nextVisitData = e;
      });
      eventBus.$on("nextVisitEdited", e => {
        this.nextVisitData = e;
      });
      eventBus.$on(
        "deleteWaitingForPayment",
        this.handleDeleteWaitingForPayment
      );
      this.fetchAllItems();
    },
    async fetchAllItems() {
      this.isLoading = true;

      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;

      const { data } = await this.fetchQueueAppointmentById({
        id: this.appointmentId,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
      this.appointment = data;
      this.appointmentLinkedWithKtbPaotang = Boolean(
        this.appointment.extendedProps.linkedWithKtbPaotang
      );
      Promise.all([
        this.fetchItemsPaymentByAptId({
          appointmentId: this.appointmentId,
          clinicUrl: clinicUrl,
          branchUrl: branchUrl,
        }),
        this.fetchOverdueTreatmentByPatientId({
          patientId: this.patient.id,
          clinicUrl: clinicUrl,
          branchUrl: branchUrl,
        }),
        this.fetchQueueAppointmentDraftByApptId({
          id: this.appointmentId,
          clinicUrl: clinicUrl,
          branchUrl: branchUrl,
        }),
      ])
        .then(async ([res1, res2, res3]) => {
          this.treatmentList = res1.data.treatments;
          this.overdueTreatmentList = res2.data;
          this.appointmentDraft = res3.data;

          if (res3.data) {
            this.getAppointmentDraftFormat(this.appointmentDraft);
          }
          await this.$nextTick();
          this.treatmentItemList.forEach(i => {
            this.calDiscount(i);
          });
          this.calTotalReceipt();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(async () => {
          this.isLoading = false;
        });
    },
    openEventModal() {
      let appointment = null;
      if (this.appointmentDraft) {
        appointment = Object.assign(
          this.getAppointmentFormat(this.appointmentDraft)
        );
        appointment.id = null;
      }
      this.$parent.openEventModal(appointment, this.patient, "paymentModal");
    },
    openEventModalEdited(apt) {
      this.$parent.openEventModal(apt, null, "paymentModal");
    },
    openProfileCustomerModal() {
      this.$parent.openProfileCustomerModal(
        null,
        this.patient,
        true //hide-footer
      );
    },
    openVerifyDeleteOverdueModal({ treatmentItemId }) {
      this.$refs.verifyDeleteOverdueModal.show({ id: treatmentItemId });
    },
    getAppointmentDraftFormat(appointmentDraft) {
      let { doctorUid, aptTreatItems, startDt, endDt, note } = appointmentDraft;

      this.aptDraftDisplay.doctor = this.getDoctorListBy(
        doctorUid,
        "uid"
      ).fullName;
      this.aptDraftDisplay.treatmentItems = aptTreatItems
        ?.map(i => this.getTreatmentBy(i, "id").nameTh)
        .join(", ");
      this.aptDraftDisplay.duration = moment(endDt).diff(
        moment(startDt),
        "minutes"
      );
      const daysDiff = moment(startDt).diff(moment(), "days", true);
      this.aptDraftDisplay.inDays = Math.ceil(daysDiff);
      this.aptDraftDisplay.note = note;
    },

    calDiscount(i) {
      let total, finalDf, finalCf;
      if (i.discountMethod === 0 && i.dfType === 0 && i.discountType === 0) {
        //ลดทั้งหมด /DF% / ลด%
        this.setMinMax(i, "discountPrice", 0, 100);
        total = i.price * ((100 - i.discountPrice) / 100);
        finalDf = i.price * (i.df / 100) * ((100 - i.discountPrice) / 100);
        finalCf = total - finalDf;
      } else if (
        //ลดทั้งหมด /DF% / ลดบาท
        i.discountMethod === 0 &&
        i.dfType === 0 &&
        i.discountType === 1
      ) {
        this.setMinMax(i, "discountPrice", 0, i.price);
        total = i.price - i.discountPrice;
        finalDf = total * (i.df / 100);
        finalCf = total - finalDf;
      } else if (
        //ลดทั้งหมด /DFfixed / ลด%
        i.discountMethod === 0 &&
        i.dfType === 1 &&
        i.discountType === 0
      ) {
        this.setMinMax(i, "discountPrice", 0, 100);
        total = i.price * ((100 - i.discountPrice) / 100);
        finalDf = i.df * (i.quantity || 1) * ((100 - i.discountPrice) / 100);
        finalCf = total - finalDf;
      } else if (
        //ลดทั้งหมด /DFfixed / ลดบาท
        i.discountMethod === 0 &&
        i.dfType === 1 &&
        i.discountType === 1
      ) {
        console.log("**DF fixed / Discount fixed cannot calculate discount**"); // คิดลดราคาไม่ได้
      } else if (
        //ลดแพทย์คลินิก /DF% / ลด%
        i.discountMethod === 1 &&
        i.dfType === 0 &&
        i.discountType === 0
      ) {
        this.setMinMax(i, "doctorDiscountPrice", 0, i.df);
        this.setMinMax(i, "clinicDiscountPrice", 0, 100 - i.df);
        finalDf = i.price * ((i.df - i.doctorDiscountPrice) / 100);
        finalCf = i.price * ((100 - i.df - i.clinicDiscountPrice) / 100);
        total = finalDf + finalCf;
        // add cal new
        i.doctorDiscountAmount = i.price * (i.doctorDiscountPrice / 100);
        i.clinicDiscountAmount = i.price * (i.clinicDiscountPrice / 100);
      } else if (
        //ลดแพทย์คลินิก /DF% / ลดบาท
        i.discountMethod === 1 &&
        i.dfType === 0 &&
        i.discountType === 1
      ) {
        let fullDf = i.price * (i.df / 100);
        let fullCf = i.price * ((100 - i.df) / 100);

        this.setMinMax(i, "doctorDiscountPrice", 0, fullDf);
        this.setMinMax(i, "clinicDiscountPrice", 0, fullCf);

        finalDf = fullDf - i.doctorDiscountPrice;
        finalCf = fullCf - i.clinicDiscountPrice;
        total = finalDf + finalCf;
        // add cal new
        i.doctorDiscountAmount = i.doctorDiscountPrice;
        i.clinicDiscountAmount = i.clinicDiscountPrice;
      } else if (
        //ลดแพทย์คลินิก /DFfixed / ลด%
        i.discountMethod === 1 &&
        i.dfType === 1 &&
        i.discountType === 0
      ) {
        let fullCf = i.price - i.df * (i.quantity || 1);
        this.setMinMax(
          i,
          "doctorDiscountPrice",
          0,
          (i.df * (i.quantity || 1) * 100) / i.price
        );
        this.setMinMax(i, "clinicDiscountPrice", 0, (fullCf * 100) / i.price);
        finalDf =
          i.df * (i.quantity || 1) - i.price * (i.doctorDiscountPrice / 100);
        finalCf = fullCf - i.price * (i.clinicDiscountPrice / 100);
        total = finalDf + finalCf;
      } else if (
        //ลดแพทย์คลินิก /DFfixed / ลดบาท
        i.discountMethod === 1 &&
        i.dfType === 1 &&
        i.discountType === 1
      ) {
        let fullCf = i.price - i.df * (i.quantity || 1);
        this.setMinMax(i, "doctorDiscountPrice", 0, i.df * (i.quantity || 1));
        this.setMinMax(i, "clinicDiscountPrice", 0, fullCf);

        finalDf = i.df * (i.quantity || 1) - i.doctorDiscountPrice;
        finalCf = fullCf - i.clinicDiscountPrice;
        total = finalDf + finalCf;
      } else {
        //กรณีัข้อมูไม่ครบ ไม่ต้องคำนวน df ใ้ห clinic ได้ทั้งหมด จัดการ manual เอง
        finalDf = 0;
        finalCf = i.price;
        total = i.price;
      }
      i.finalDf = finalDf;
      i.finalCf = finalCf;
      i.amount = total;
      i.finalDiscount = i.price - total;
      if (!i.overdueFlag) {
        i.paid = total;
      } else {
        i.paid = i.remained;
      }

      this.calPaidItem(i);
      this.calTotalReceipt();
    },
    calPaidItem(i) {
      if (i.overdueFlag) {
        this.setMinMax(i, "paid", 0, i.remained);
        i.overdue = i.remained - i.paid;
      } else {
        this.setMinMax(i, "paid", 0, i.amount);
        i.overdue = i.amount - i.paid;
      }
      let paidDf, paidCf;
      let num = i.finalDf * (i.paid / i.amount);
      paidDf = +parseFloat(num).toFixed(2);
      paidCf = i.paid - paidDf;
      i.paidDf = paidDf;
      i.paidCf = paidCf;

      this.calTotalReceipt();
    },

    calTotalReceipt() {
      Object.assign(this.receipt, {
        price: 0,
        amount: 0,
        Df: 0,
        Cf: 0,
        discountValue: 0,
        discountTotal: 0,
        paidDfDiscounted: 0,
        paidCfDiscounted: 0,
        discountPaidTotal: 0,
        finalDiscount: 0,
        overdue: 0,
        paid: 0,
        paidDf: 0,
        paidCf: 0,
        appointmentId: this.appointmentId,
        treatmentId: this.treatmentList[0].id,
        treatmentModifyDate: this.treatmentList[0].modifyDt,
      });
      function formatNumber(value) {
        return +parseFloat(value).toFixed(2);
      }
      this.treatmentItemList.forEach(res => {
        if (res.overdueFlag) {
          this.receipt.amount += res.remained ? formatNumber(res.remained) : 0;
        } else {
          this.receipt.amount += res.amount ? formatNumber(res.amount) : 0;
        }

        this.receipt.Df += res.finalDf ? formatNumber(res.finalDf) : 0;
        this.receipt.Cf += res.finalCf ? formatNumber(res.finalCf) : 0;
        this.receipt.price += res.price ? formatNumber(res.price) : 0;
        this.receipt.overdue += res.overdue ? formatNumber(res.overdue) : 0;
        this.receipt.paid += res.paid ? formatNumber(res.paid) : 0;
        this.receipt.paidDf += res.paidDf ? formatNumber(res.paidDf) : 0;
        this.receipt.paidCf += res.paidCf ? formatNumber(res.paidCf) : 0;
        this.receipt.finalDiscount += res.finalDiscount
          ? formatNumber(res.finalDiscount)
          : 0;
      });
    },

    async openPaymentMethodModal() {
      if (this.receipt.overdue != 0) {
        await this.$refs.Dialog.showAlertConfirm(
          `มีรายการค้างชำระ ${this.receipt.overdue} บาท`,
          "คุณต้องการดำเนินการชำะเงินต่อ"
        ).then(res => {
          if (res.isConfirmed) {
            this.receipt.receiptItems = [];
            this.receipt.receiptItems = [...this.treatmentItemList];
            this.$refs.paymentMethodModal.show({
              receipt: this.receipt,
              patient: this.patient,
              appointment: this.appointment,
            });
          }
        });
      } else {
        this.receipt.receiptItems = [];
        this.receipt.receiptItems = [...this.treatmentItemList];
        this.$refs.paymentMethodModal.show({
          receipt: this.receipt,
          patient: this.patient,
          appointment: this.appointment,
        });
      }
    },
    openPaymentHistoryModal(appointmentId) {
      this.$parent.openPaymentHistoryModal(this.appointment);
    },
    openPaymentReceiptModal(receipt) {
      this.$emit("openPaymentReceiptModal", receipt, this.treatment);
    },
    afterDelete() {
      this.fetchAllItems();
      this.$refs.Dialog.showToast("success", "ลบรายการค้างชำระเรียบร้อย");
    },
    defaultValue() {
      eventBus.$off("nextVisitCreated");
      eventBus.$off("nextVisitEdited");
      eventBus.$off("deleteWaitingForPayment");
      this.treatmentList = [];
      this.nextVisitData = null;
      this.aptDraftDisplay = {};
      this.newAptCard = true;
      this.editAptCard = false;
      this.appointment = {};
      this.appointmentLinkedWithKtbPaotang = false;
      Object.assign(this.receipt, {
        price: 0,
        amount: 0,
        Df: 0,
        Cf: 0,
        discountValue: 0,
        discountTotal: 0,
        paidDfDiscounted: 0,
        paidCfDiscounted: 0,
        discountPaidTotal: 0,
        finalDiscount: 0,
        overdue: 0,
        paid: 0,
        paidDf: 0,
        paidCf: 0,
        receiptItems: [],
        appointmentId: null,
        treatmentId: null,
        treatmentModifyDate: null,
      });
    },

    //Utils
    setMinMax(obj, key, min, max) {
      let num = Math.min(Math.max(obj[key], min), max) || 0;
      obj[key] = +parseFloat(num).toFixed(2);
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatOverdueItems(arr) {
      return arr.map(i => {
        let item = {
          appointmentId: null,
          treatmentItemId: i.id,
          price: i.price,
          discountMethod: i.lastReceiptItem?.discountMethod,
          discountType: i.lastReceiptItem?.discountType,
          discountPrice: i.lastReceiptItem?.discountPrice,
          doctorDiscountPrice: i.lastReceiptItem?.doctorDiscountPrice,
          clinicDiscountPrice: i.lastReceiptItem?.clinicDiscountPrice,
          finalDf: i.lastReceiptItem?.finalDf,
          finalCf: i.lastReceiptItem?.finalCf,
          amount: i.lastReceiptItem?.amount,
          finalDiscount: i.lastReceiptItem?.finalDiscount,
          remained: i.lastReceiptItem?.amount - i.paid,
          paid: i.paid,
          paidDf: i.lastReceiptItem?.paidDf,
          paidCf: i.lastReceiptItem?.paidCf,
          creationDt: i.lastReceiptItem?.creationDt,
          name: i.name,
          doctor: this.getUserListBy(i.actionUid, "uid")?.fullName, //เปลี่ยนจาก actionUid
          quantity: i.quantity,
          dfType: i.dfType,
          df: i.df,
          _rowVariant: "danger",
          overdueFlag: i.overdueFlag,
        };
        return item;
      });
    },
    removeTags(strWithTags) {
      if (!strWithTags) return "-";
      else strWithTags = strWithTags.toString();

      return strWithTags.replace(/(<([^>]+)>)/gi, "");
    },
    handleDeleteWaitingForPayment(e) {
      if (e.id === this.appointmentId) {
        this.$refs.Dialog.showAlertInfo(
          "มีการแก้ไขรายการรักษา",
          "กรุณาเริ่มทำรายการชำระเงินใหม่",
          "warning"
        ).then(() => {
          this.$bvModal.hide("PaymentModal");
        });
      }
    },
  },
};
</script>

<style></style>