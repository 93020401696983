# templates/ReferralLetterTemplate.vue
<template>
  <div>
    <div ref="body">
      <!-- Header -->
      <h5 class="text-center my-3">หนังสือส่งตัวผู้ป่วย</h5>
      <p class="text-right mr-3">วันที่ {{ documentData.today }}</p>

      <!-- Subject & Recipient -->
      <div class="mb-4">
        <p>เรื่อง: ขอส่งตัวผู้ป่วยเพื่อการรักษาต่อ</p>
        <p>เรียน: แพทย์ผู้รับการส่งต่อ</p>
      </div>

      <!-- Doctor & Patient Info -->
      <p style="text-indent: 50px; line-height: 2" class="my-justify">
        ข้าพเจ้า {{ documentData.title
        }}{{ documentData.doctorObj ? documentData.doctorObj.fullName : "" }}
        เป็นผู้จดทะเบียนประกอบโรคศิลปะแผนปัจจุบันชั้นหนึ่ง สาขาทันตกรรม
        ใบอนุญาตที่ ท
        {{ documentData.doctorObj ? documentData.doctorObj.drLicenseNo : "" }}
        มีความประสงค์ขอส่งตัวผู้ป่วย {{ documentData.ptFullname }} อายุ
        {{
          calAge(documentData.patient ? documentData.patient.dob : null) ||
          "________"
        }}
        ปี DN.
        {{ documentData.patient ? documentData.patient.dn : "" }}
        <template v-if="documentData.patient && documentData.patient.address">
          ที่อยู่ {{ documentData.patient.address }}
        </template>
      </p>

      <!-- Treatment History -->
      <div class="clinical-info mb-4">
        <p class="mb-1 font-weight-bold">ประวัติการรักษาที่ผ่านมา:</p>
        <div
          v-if="
            !documentData.selectedItem || documentData.selectedItem.length === 0
          "
        >
          <p class="line-container" v-for="n in 3" :key="n">
            <span class="printable-line"></span>
          </p>
        </div>
        <div v-else>
          <div
            v-for="(items, key) in documentData.groupSelectedItem"
            :key="key"
          >
            <p class="mb-1">วันที่ {{ key }}</p>
            <ul :style="{ listStyleType: 'none' }">
              <li v-for="(item, index) in items" :key="item.id">
                {{ index + 1 }}. ตำแหน่ง/ซี่ฟัน
                {{
                  item.toothItems && item.toothItems.length > 0
                    ? item.toothNumberSurfaces
                    : "____________"
                }}
                ได้รับการวินิจฉัย
                {{ item.dxName || "______________________________" }}
                รักษาด้วยการ
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Additional Treatment Details -->
      <div class="mb-4" v-if="documentData.commentDetail">
        <p class="font-weight-bold">รายละเอียดเพิ่มเติม:</p>
        <div style="padding-left: 50px">
          <p class="my-justify" style="line-height: 2; white-space: pre-wrap">
            {{ documentData.commentDetail }}
          </p>
        </div>
      </div>

      <!-- Signature Section -->
      <div class="signature-section">
        <div class="signature-wrapper">
          <p class="text-center">ขอแสดงความนับถือ</p>

          <div class="signature-content">
            <div class="signature-line">
              ............................................
            </div>
            <p class="mt-2">
              ({{ documentData.title
              }}{{
                documentData.doctorObj ? documentData.doctorObj.fullName : ""
              }})
            </p>
            <p class="mb-1">ทันตแพทย์ผู้ทำการรักษา</p>
            <p>
              เลขที่ใบประกอบวิชาชีพ:
              {{
                documentData.doctorObj ? documentData.doctorObj.drLicenseNo : ""
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { documentConfig } from "../config/documentConfig";

export default {
  name: "ReferralLetterTemplate",
  props: {
    documentData: {
      type: Object,
      required: true,
      default: () => ({
        title: "",
        doctorObj: {
          fullName: "",
          drLicenseNo: "",
        },
        ptFullname: "",
        patient: {
          dob: null,
          dn: "",
          address: "",
        },
        selectedItem: [],
        groupSelectedItem: {},
        currentStatus: "",
        referralReason: "",
        commentDetail: "",
        specialInstructions: "",
        attachments: "",
        contactInfo: "",
        today: "",
      }),
    },
  },
  data() {
    return { documentConfig };
  },
  methods: {
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
};
</script>

<style scoped>
.signature-section {
  margin-top: 8rem;
  margin-bottom: 3rem;
}

.signature-wrapper {
  margin-left: 55%;
  width: 40%;
}

.signature-content {
  margin-top: 1rem;
  text-align: center;
}

.signature-line {
  margin: 2rem 0 0.5rem;
  font-size: 1.1rem;
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.printable-line {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #000;
  margin: 0.5rem 0;
}

.my-justify {
  text-align: justify;
}

@media print {
  .signature-section {
    page-break-inside: avoid;
  }
}
</style>