// config/documentConfig.js

export const documentTypes = [
  { text: "สร้างเอกสาร", value: "empty", disabled: true },
  { text: "ใบรับรองแพทย์", value: "medicalCert" },
  { text: "ใบส่งตัว", value: "referralLetter", checkForSubscription: true },
  {
    label: "ใบขอความยินยอม",
    options: [
      {
        text: "ใบยินยอมการรักษารากเทียม",
        value: "dentalImplantConsent",
        checkForSubscription: true,
      },

      {
        text: "ใบยินยอมการรักษาวีเนียร์",
        value: "veneerConsent",
        checkForSubscription: true,
      },
      {
        text: "ใบยินยอมการจัดฟันแบบดั้งเดิม",
        value: "traditionalBracesConsent",
        checkForSubscription: true,
      },
      {
        text: "ใบยินยอมการจัดฟันใส",
        value: "clearAlignerConsent",
        checkForSubscription: true,
      },
      {
        text: "ใบยินยอมการใช้ข้อมูลเพื่อการตลาด",
        value: "marketingConsent",
        checkForSubscription: true,
      },
    ],
  },
];

export const documentConfig = {
  empty: {
    title: "ใบรับรองทันตแพทย์",
    subtitle: "สำหรับพิมพ์ หรือบันทึกเป็นPDFเท่านั้น",
    showPatientName: true,
    showAddress: true,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: true,
    showUpload: true,
    filePrefix: "Med_Cert",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์ข้อมูล",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อ",
      address: "ที่อยู่",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์",
      comments: "ความเห็นทันตแพทย์",
      tel: "โทร",
      tableCaption: "เลือกรายการรักษาที่ต้องการออกใบรับรอง",
    },
    placeholders: {
      selectTitle: "กรุณาเลือกคำนำหน้า",
      selectDoctor: "รายชื่อแพทย์",
      comments: "...",
    },
  },
  medicalCert: {
    title: "ใบรับรองทันตแพทย์",
    subtitle: "สำหรับพิมพ์ หรือบันทึกเป็นPDFเท่านั้น",
    showPatientName: true,
    showAddress: true,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: true,
    showUpload: true,
    filePrefix: "Med_Cert",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์ข้อมูล",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อ",
      address: "ที่อยู่",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์",
      comments: "ความเห็นทันตแพทย์",
      tel: "โทร",
      tableCaption: "เลือกรายการรักษาที่ต้องการออกใบรับรอง",
    },
    placeholders: {
      selectTitle: "กรุณาเลือกคำนำหน้า",
      selectDoctor: "รายชื่อแพทย์",
      comments: "...",
    },
  },

  dentalImplantConsent: {
    title: "ใบยินยอมรักษารากเทียม",
    subtitle: "",
    showPatientName: true,
    showAddress: false,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: false,
    showUpload: true,
    filePrefix: "Implant-Consent",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อผู้ป่วย",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์ผู้รักษา",
      comments: "รายเอียดเพิ่มเติม",
      tel: "โทร",
    },
    placeholders: {
      selectTitle: "เลือกคำนำหน้า",
      selectDoctor: "เลือกแพทย์",
      comments: "รายเอียดเพิ่มเติม...",
    },
  },
  referralLetter: {
    title: "หนังสือส่งตัว",
    subtitle: "",
    showPatientName: true,
    showAddress: true,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: true,
    showUpload: true,
    filePrefix: "Referral",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อผู้ป่วย",
      address: "ที่อยู่",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์ผู้ส่งตัว",
      comments: "รายละเอียดเพิ่มเติม",
      tel: "โทร",
    },
    placeholders: {
      selectTitle: "เลือกคำนำหน้า",
      selectDoctor: "เลือกแพทย์",
      comments: "สถานะในปัจจุบัน และเหตุผลในการส่งต่อ ...",
    },
  },

  marketingConsent: {
    title: "ใบยินยอมการใช้ข้อมูลเพื่อการตลาด",
    subtitle: "",
    showPatientName: true,
    showAddress: false,
    // showDoctorTitle: true,
    // showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: false,
    showUpload: true,
    filePrefix: "Marketing-Consent",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อผู้ป่วย",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์ผู้รักษา",
      comments: "รายละเอียดเพิ่มเติม",
      tel: "โทร",
    },
    placeholders: {
      selectTitle: "เลือกคำนำหน้า",
      selectDoctor: "เลือกแพทย์",
      comments: "รายละเอียดเพิ่มเติม...",
    },
  },

  veneerConsent: {
    title: "ใบยินยอมการรักษาวีเนียร์",
    subtitle: "",
    showPatientName: true,
    showAddress: false,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: false,
    showUpload: true,
    filePrefix: "Veneer-Consent",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อผู้ป่วย",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์ผู้รักษา",
      comments: "รายละเอียดเพิ่มเติม",
      tel: "โทร",
    },
    placeholders: {
      selectTitle: "เลือกคำนำหน้า",
      selectDoctor: "เลือกแพทย์",
      comments: "รายละเอียดเพิ่มเติม...",
    },
  },

  traditionalBracesConsent: {
    title: "ใบยินยอมการจัดฟันแบบดั้งเดิม",
    subtitle: "",
    showPatientName: true,
    showAddress: false,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: false,
    showUpload: true,
    filePrefix: "Braces-Consent",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อผู้ป่วย",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์ผู้รักษา",
      comments: "รายละเอียดเพิ่มเติม",
      tel: "โทร",
    },
    placeholders: {
      selectTitle: "เลือกคำนำหน้า",
      selectDoctor: "เลือกแพทย์",
      comments: "รายละเอียดเพิ่มเติม...",
    },
  },

  clearAlignerConsent: {
    title: "ใบยินยอมการจัดฟันใส",
    subtitle: "",
    showPatientName: true,
    showAddress: false,
    showDoctorTitle: true,
    showDoctorSelect: true,
    showComments: true,
    showTreatmentTable: false,
    showUpload: true,
    filePrefix: "Clear-Aligner-Consent",
    buttons: {
      print: "พิมพ์",
      clear: "เคลียร์",
      upload: "เซ็นต์",
    },
    labels: {
      patientName: "ชื่อผู้ป่วย",
      doctorTitle: "ทพ./ทพญ.",
      doctor: "แพทย์ผู้รักษา",
      comments: "รายละเอียดเพิ่มเติม",
      tel: "โทร",
    },
    placeholders: {
      selectTitle: "เลือกคำนำหน้า",
      selectDoctor: "เลือกแพทย์",
      comments: "รายละเอียดเพิ่มเติม...",
    },
  },
};

export default documentConfig;
